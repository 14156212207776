import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-form-date-calc',
  styleUrls: ['form-dateCalc.component.scss'],
  template: `
    <div>
      <div class="date-calc-age">
        <mat-form-field class="w-100">
          <mat-label>{{ to.placeholder }}</mat-label>
          <input
            matInput
            [placeholder]="props.placeholder"
            [formControl]="formControl"
            [required]="props.required"
            (keyup)="clearDatePicker()"
          />
          <mat-error
            *ngIf="
              formControl.hasError('required') &&
              !formControl.hasError('regRequired')
            "
            >This field is required.</mat-error
          >
          <mat-error *ngIf="formControl.hasError('regRequired')"
            >This field is needed for suitability review. You can opt out below
            if the applicant refuses to provide the information.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="date-calc-date">
        <button
          mat-raised-button
          color="accent"
          type="button"
          (click)="picker.open()"
          [disabled]="formControl.disabled"
          ><mat-icon>today</mat-icon>Calculate Age</button
        >
        <mat-form-field>
          <input
            matInput
            [formControl]="dateCtrl"
            [matDatepicker]="picker"
            (dateChange)="dateChange($event)"
          />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  `,
})
export class FormlyCustomDateCalcComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  dateCtrl = new FormControl();

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.formControl.disabled) {
      this.dateCtrl.disable();
    }
  }

  dateChange(event) {
    this.formControl.setValue(null);
    if (event.value) {
      const selectedDate = DateTime.fromJSDate(event.value);
      const todaysDate = DateTime.fromJSDate(new Date());
      const yearsDiff = Math.floor(
        todaysDate.diff(selectedDate, 'years').years
      );
      if (yearsDiff > -1) {
        this.formControl.setValue(yearsDiff);
      }
    }
  }
  clearDatePicker() {
    this.dateCtrl.setValue(null);
  }
}
