<div class="page-container fia-container container">
  <div class="row w-100 controls">
    <form [formGroup]="form" class="col-4">
      <mat-form-field class="col-12 default-select">
        <mat-select
          [placeholder]="'Preset Table Filtering and Sorting'"
          formControlName="defaultsControl"
          (selectionChange)="useDefault($event)"
        >
          <mat-option *ngFor="let opt of defaults" [value]="opt.label">{{
            opt.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <app-grid-column-filter
      [columns]="gridColumns"
      (columnsUpdated)="externalColumnsUpdated($event)"
    ></app-grid-column-filter>
    <!-- <mat-form-field class="col-4 default-select">
      <mat-select [placeholder]="'Strategy Type'" (selectionChange)="useDefault($event)">
        <mat-option *ngFor="let opt of strategyTypes" [value]="opt">{{opt.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-4 default-select">
      <mat-select [placeholder]="'Protection Type'" (selectionChange)="useDefault($event)">
        <mat-option *ngFor="let opt of protectionTypes" [value]="opt">{{opt.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-4 default-select">
      <mat-select [placeholder]="'Index Type'" (selectionChange)="useDefault($event)">
        <mat-option *ngFor="let opt of indexTypes" [value]="opt">{{opt.label}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <button
      mat-button
      color="primary"
      class="reset-button pull-right"
      (click)="useDefault({ value: 'clear' }, true)"
    >
      <mat-icon>replay</mat-icon>Reset
    </button>
    <button
      mat-button
      color="primary"
      class="grid-button"
      (click)="exportToExcel(viaGrid)"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
    <!-- <mat-form-field class="col-4 hide-select">
      <mat-select [placeholder]="'Hide Columns'" [(value)]="hidden" (selectionChange)="updateHidden($event)" multiple>
        <mat-option *ngFor="let col of gridColumns" [value]="col.DataField">{{col.Label}}</mat-option>
      </mat-select>
    </mat-form-field> -->
  </div>
  <kendo-grid
    class="col-12"
    #viaGrid="kendoGrid"
    [kendoGridBinding]="ogData"
    [skip]="state.skip"
    [pageSize]="state.take"
    [pageable]="true"
    [resizable]="true"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [sort]="state.sort"
    [filterable]="'menu'"
    [columnMenu]="{ columnChooser: false }"
    [filter]="filter"
    filterable="menu"
    (filterChange)="filterChange($event)"
    [groupable]="true"
    [group]="state.group"
    [state]="state"
    (dataStateChange)="dataStateChange($event)"
  >
    <ng-template ngFor [ngForOf]="gridColumns" let-column>
      <kendo-grid-column
        [field]="column.DataField"
        [hidden]="isHidden(column.DataField)"
        [title]="column.Label"
        [sortable]="column.Sort"
        [filterable]="column.Filter"
        [groupable]="column.Group"
        [media]="column.RespVis"
        [filter]="column.DataType"
        [width]="column.Width"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter="filter"
          let-filterService="filterService"
        >
          <!-- <kendo-floatinglabel text="Filter By..."> -->
          <kendo-multiselect
            style="width: 99%; margin-top: -30px"
            [data]="filterData[column.DataField]"
            textField="display"
            valueField="value"
            [valuePrimitive]="true"
            [value]="filter | filterValues"
            (valueChange)="
              filterValueChange($event, column.DataField, filterService)
            "
            [fillMode]="'outline'"
            placeholder="Filter By..."
          >
          </kendo-multiselect>
          <!-- </kendo-floatinglabel> -->
          <!-- <mat-form-field class="col-12 p-0"   >
      <mat-label>Filter By...</mat-label>
      <mat-select multiple class="w-100"
      [valuePrimitive]="true"
      [value]="filter | filterValues"
      (selectionChange)="
        filterValueChange($event, column.DataField, filterService)
      ">
          <mat-option *ngFor="let opt of filterData[column.DataField]" [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
    </mat-form-field> -->
        </ng-template>
        <ng-template
          *ngIf="column.Type === 'integer'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | number: '1.0-0' }}
          <!-- {{dataItem[column.DataField] | percent}} -->
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'integer'"
          >{{ value | number: '1.0-0' }}</ng-template
        >
        <ng-template
          *ngIf="column.Type === 'perc'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{
            dataItem[column.DataField] === 'N/A' ||
            dataItem[column.DataField] === 'Uncapped'
              ? dataItem[column.DataField]
              : (dataItem[column.DataField] | percent)
          }}
          <!-- {{dataItem[column.DataField] | percent}} -->
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'perc'"
          >{{ value | percent }}</ng-template
        >

        <ng-template
          *ngIf="column.Type === 'perc2'"
          kendoGridCellTemplate
          let-dataItem
        >
          <ng-container
            *ngIf="
              dataItem[column.DataField] == -9.99 ||
                dataItem[column.DataField] == -999;
              else realData
            "
          >
            N/A
          </ng-container>
          <ng-template #realData>
            {{
              dataItem[column.DataField] === 'N/A' ||
              dataItem[column.DataField] === 'Uncapped'
                ? dataItem[column.DataField]
                : (dataItem[column.DataField] | percent: '1.2')
            }}
          </ng-template>
        </ng-template>

        <ng-template
          *ngIf="column.DataField === 'VARate$RateLockPeriod'"
          kendoGridCellTemplate
          let-dataItem
        >
          <ng-container *ngIf="dataItem[column.DataField] === 'F'"
            >Full Term</ng-container
          >
          <ng-container *ngIf="dataItem[column.DataField] === 'A'"
            >Annual</ng-container
          >
          <ng-container *ngIf="dataItem[column.DataField] === 'S'"
            >Semi-annual</ng-container
          >
          <ng-container *ngIf="dataItem[column.DataField] === 'Q'"
            >Quarterly</ng-container
          >
          <ng-container *ngIf="dataItem[column.DataField] === 'M'"
            >Monthly</ng-container
          >
          <ng-container *ngIf="dataItem[column.DataField] === 'D'"
            >Daily</ng-container
          >
        </ng-template>

        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'perc2'"
          >{{ value | percent: '1.2' }}</ng-template
        >

        <ng-template
          *ngIf="column.Type === 'curr'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | currency }}
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'curr'"
          >{{ value | currency }}</ng-template
        >
      </kendo-grid-column>
    </ng-template>
    <kendo-grid-excel fileName="RightBridge-RILA.xlsx" [fetchData]="excelData">
    </kendo-grid-excel>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="buttonCount"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes
        [pageSizes]="pageSizeOptions"
      ></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
