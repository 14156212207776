import { throwError as observableThrowError, interval } from 'rxjs';
import { take, concat, tap, retryWhen } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { SessionStorageService } from './session-storage.service';

// declare var require: any;

@Injectable()
export class AppAccessService {
  rights = null;
  url: string;
  accessList = [];

  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    public router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let auth: any = false;
    this.url = state.url ? state.url.split('/')[1] : '';

    this.ss.storageChangeExternal.subscribe(chng => {
      if (chng.key === 'rights') {
        setTimeout(() => {
          auth = this.getRights();
          return auth;
        }, 5000);
      }
    });

    return true;
  }

  checkAccess() {
    let access = false;
    const app = this.url.toUpperCase();

    this.accessList = this.rights.filter(z => {
      return (
        z.toUpperCase() == 'AW' ||
        z.toUpperCase() == 'IW' ||
        z.toUpperCase() == 'PP'
      );
    });
    access = this.rights.includes(app);

    return access;
  }

  getRights() {
    let auth = false;
    this.rbs
      .getRights()
      .pipe(
        tap(
          val => {
            this.rights = val;
            auth = this.checkAccess();
          },
          err => {
            console.error(err);
          }
        ),
        retryWhen(err => {
          console.error(err);
          return interval(1000).pipe(
            take(10),
            concat(observableThrowError('error'))
          );
        })
      )
      .subscribe(
        val => {
          this.rights = val;
          auth = this.checkAccess();

          if (!auth) {
            if (this.accessList.length > 0 && !auth) {
              const redir = '/' + this.accessList[0];
              this.router.navigate([redir]);
              return auth;
            } else {
              this.rbs.logout();
              return auth;
            }
          }
        },
        () => {
          this.rights = null;
        }
      );
    return auth;
  }
}
