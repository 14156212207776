import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
import { EnvironmentService } from './environment.service';
import { RightBridgeApiService } from './right-bridge-api.service';
import { ErrorReportingService } from './error-reporting.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ManageFiltersService {
  private environment;
  public baseUrl: string;
  public f5500BaseUrl: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private rbs: RightBridgeApiService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }

    this.f5500BaseUrl = this.environment.f5500Base;
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.org = this.environment.org || 'basev2';
  }

  getSavedFilterTemplates() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/GridData/GetFilters`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  saveFilterTemplate(filterTemplateData) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    let url =
      this.baseUrl +
      `/GridData/SaveFilter?grid=${filterTemplateData.grid}&name=${filterTemplateData.name}`;

    // if user is making changes for unit
    if (+filterTemplateData.scope) {
      url = url.concat(
        `&scope=${filterTemplateData.scope}&rootUnitID=${filterTemplateData.rootUnitID}`
      );
    } else {
      url = url.concat(`&scope=0`);
    }
    // if user is updating existing saved filters
    if (filterTemplateData.filterID) {
      url = url.concat(`&filterID=${filterTemplateData.filterID}`);
    }

    return this.http
      .patch(url, filterTemplateData.JSONTemplate, {
        headers: reqHeaders,
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return this.errSvc.handleError(error);
        })
      );
  }

  deleteFilterTemplate(filterID) {
    const sessId = this.ss.get('session');
    const url = this.baseUrl + `/GridData/DeleteFilter?filterID=${filterID}`;
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    return this.rbs.deleteRequest(url, reqHeaders);
  }
}
