import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { EnvironmentService } from '../services/environment.service';
// import { environment } from '../../environments/environment';

@Component({
  selector: 'app-attest-dialog',
  templateUrl: './attest-dialog.component.html',
  styleUrls: ['./attest-dialog.component.scss'],
})
export class AttestDialogComponent implements OnInit {
  private environment;
  form: FormGroup;
  usersName = null;
  docUrl = null;

  constructor(
    private dialogRef: MatDialogRef<AttestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();

    const formGroup = new UntypedFormGroup({
      nameInput: new FormControl(''),
    });
    this.form = formGroup;
    const parser = document.createElement('a');
    parser.href = this.environment.apiBase;
    this.docUrl = `https://ra.rightbridge.net/v3/assets/license-agreement.pdf`;
  }

  accept() {
    const data = this.form.value.nameInput;
    if (data) {
      this.rbs.signLicenseAgreement(this.form.value.nameInput).subscribe(x => {
        this.close(data);
      });
    } else {
      this.form.markAsTouched();
    }
  }

  close(formData = 'cancel') {
    this.dialogRef.close({ data: formData });
    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
  }
}
