<div class="page-container profile-history-container container unified">
  <div class="profile-history-summary-container row">
    <div class="history-header col-12 mb-2">
      <h1>Case History</h1>
      <div>
        <button mat-button color="primary" (click)="dlXlsx()">
          <mat-icon class="mr-2">cloud_download</mat-icon>Download Excel File
        </button>
        <button mat-button color="primary" (click)="legacyView()">
          <mat-icon class="mr-1">history_edu</mat-icon>Legacy View
        </button>
      </div>
    </div>
    <div
      class="profile-history-data-container col-12 col-xl-3 col-lg-4 mb-lg-5 mb-3"
    >
      <div><strong>Solution:</strong> {{ solutionName }}</div>
      <div><strong>Case Number:</strong> {{ caseNumber }}</div>
      <div><strong>ProfileID:</strong> {{ profileId }}</div>
      <div><strong>Client Name:</strong> {{ clientName }}</div>
      <div><strong>User Name:</strong> {{ userName }}</div>
      <div><strong>RepCode:</strong> {{ repCode }}</div>
    </div>

    <div class="col-12 col-xl-8 col-lg-8 mb-5">
      <div class="profile-history-stats">
        <div class="stat-box">
          <h2>{{ runs || 0 }}</h2>
          <h4>Runs</h4>
        </div>
        <div class="stat-box">
          <h2>{{ scoreClassChanges || 0 }}</h2>
          <h4>Score Class Change{{ scoreClassChanges > 1 ? 's' : '' }}</h4>
        </div>
      </div>
    </div>
  </div>

  <div
    class="profile-history-grid-container"
    id="grid-container"
    *ngIf="productTypes"
  >
    <table #profileHistoryGrid>
      <thead>
        <th></th>
        <th>Bridge</th>
        <th *ngFor="let changeCount of dataColumns; let i = index">
          <div class="change-count">
            {{ i + 1 }}
          </div>
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let product of productTypes" class="product-row">
          <td class="product-label-cell">
            <div class="product-label-container">
              <div class="product-label">
                {{ product.label.toUpperCase() || 'OTHER' }}
              </div>
            </div>
          </td>
          <td>
            <tr *ngFor="let bridge of product.Bridges">
              <td>
                <div class="bridge-name-container">
                  {{ bridge.productName }}
                </div>
              </td>
            </tr>
          </td>
          <td *ngFor="let changeCount of dataColumns">
            <tr *ngFor="let bridge of product.Bridges" class="bridge-row">
              <td class="change-column">
                <div
                  *ngIf="bridgeHasChanges(bridge, changeCount.profileChangeID)"
                  class="change text-center"
                  [ngClass]="
                    getChangeClass(bridge, changeCount.profileChangeID)
                  "
                  (click)="
                    openChangeDetail(bridge, changeCount.profileChangeID)
                  "
                >
                  {{ getChangeAmount(bridge, changeCount.profileChangeID) }}
                </div>
                <div
                  *ngIf="!bridgeHasChanges(bridge, changeCount.profileChangeID)"
                  class="change empty-change"
                >
                  --
                </div>
              </td>
            </tr>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
