import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  UntypedFormGroup,
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  ValidationErrors,
  Form,
} from '@angular/forms';
import {
  tap,
  distinctUntilChanged,
  takeUntil,
  startWith,
  debounceTime
} from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService } from '../services/session-storage.service';
import { BeaconService } from '../services/beacon.service';
import { LifeWizardService } from '../life-wizard/life-wizard.service';
import { RixtremaService } from '../rixtrema/rixtrema.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, isEqual } from 'lodash';
import { AdminService } from '../admin/admin.service';
import { Context } from '../models/admin-models';
import { FormService } from './form-service.service';
import { Decimal } from 'decimal.js-light';
import { EnvironmentService } from '../services/environment.service';
import { FundFeeLookupService } from '../fund-fee-lookup/fund-fee-lookup.service';
import { WarnDialogComponent } from '../warn-dialog/warn-dialog.component';
import { SaveUnitProfileDialogComponent } from '../admin/save-unit-profile-dialog/save-unit-profile-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UnifiedFlowService } from '../unified-flow/unified.service';
import { ES6Parser, ES6StaticEval } from 'espression';
import { checkPercentVar } from '../lib/percent-vars';
import { FormlyForm } from '@ngx-formly/core';
import { ModuleStatusService } from '../services/module-status.service';

export function regValidation(control: FormControl, field): ValidationErrors {
  let model;
  const val = control.value;
  if (field.model && Object.keys(field.model).length > 18) {
    model = field.model;
  } else if (
    field.parent.parent &&
    Object.keys(field.parent.parent.model).length > 18
  ) {
    model = field.parent.parent.model;
  } else if (
    field.parent.parent &&
    Object.keys(field.parent.parent.fieldGroup).length > 18
  ) {
    model = field.parent.parent.fieldGroup[0].model;
  } else {
    model = field.parent.parent.parent.model;
  }

  if (model) {
    const optOut = model.regSkipped;
    const useOptOut =
      (typeof model.UnitRegulation_UseOptOut == 'string' &&
        model.UnitRegulation_UseOptOut == 'Y') ||
      (typeof model.UnitRegulation_UseOptOut == 'boolean' &&
        model.UnitRegulation_UseOptOut);
    const regState = model.UnitRegulationState_States
      ? model.UnitRegulationState_States.includes(
          model.LifeWizard_StateOfIssue
        ) ||
        model.UnitRegulationState_States.includes(
          model.AnnuityWizard_StateOfIssue
        )
      : false;

    if (
      regState &&
      useOptOut &&
      (model.LifeWizard_AssessmentType == 2 ||
        model.AnnuityWizard_AssessmentType == 2)
    ) {
      if (optOut) {
        return null;
      } else if (!val && val !== 0 && !optOut) {
        return { regRequired: true };
      } else {
        return null;
      }
    } else if (
      (!regState && !val && val !== 0) ||
      (!useOptOut && !val && val !== 0)
    ) {
      return { required: true };
    } else if (model.LifeWizard_AssessmentType == 1 && !val && val !== 0) {
      return { required: true };
    }
  } else {
    return null;
  }
}

export function groupValidation(control: FormControl): ValidationErrors {
  const val = control.value;
  const containsValue = Object.entries(val).some(x => {
    return x[1];
  });

  if (!containsValue && control.touched) {
    return { requiredGroup: true };
  }
  return null;
}

export function customCriteriaValidation(
  control: FormControl,
  field,
  form
): ValidationErrors {
  let model;

  if (field.model && Object.keys(field.model).includes('ClientEstimates_Age')) {
    model = field.model;
  } else if (
    field.parent.parent &&
    Object.keys(field.parent.parent.model).includes('ClientEstimates_Age')
  ) {
    model = field.parent.parent.model;
  } else if (
    field.parent.parent &&
    field.parent.fieldGroup[0].model &&
    Object.keys(field.parent.fieldGroup[0].model).includes(
      'ClientEstimates_Age'
    )
  ) {
    model = field.parent.parent.fieldGroup[0].model;
  } else if (
    field.parent.parent.parent &&
    Object.keys(field.parent.parent.parent.model).includes(
      'ClientEstimates_Age'
    )
  ) {
    model = field.parent.parent.parent.model;
  } else {
    model = field.parent.parent.model;
  }

  if (model && model['formChecks']) {
    const check = model['formChecks'].find(
      x => field.props.formChecks == x.id && x.id != 'required'
    );

    const parser = new ES6Parser();
    const staticEval = new ES6StaticEval();
    const ast = parser.parse(check.criteria);
    let notValid;

    
    try {
      notValid = staticEval.evaluate(ast, {
        model: model,
        field: field,
        val: field.formControl.value,
      });
    } catch (err) {
      console.warn(`   `);
      console.warn(`***** ***** ***** ***** ***** ***** **** *****`);
      console.warn(
        `**** Can't process custom form validation: ${check.criteria} ****`
        );
        console.warn(`**** ***** Processing Error: ${err} ***** ****`);
        console.warn(`***** ***** ***** ***** ***** ***** **** *****`);
        console.warn(`   `);
      }
      
      // console.log(notValid, check, model, field, model.LISubtypes, '213123', model.LISubtypes.LISubtypes_PersonalCoverage, '23423423', !model.LISubtypes.LISubtypes_PersonalCoverage, !model.LISubtypes.LISubtypes_LegacyEstate, !model.LISubtypes.LISubtypes_Business, !model.LISubtypes.LISubtypes_Juvenile);
      
    const err = {};
    if (notValid) {
      err[check.id] = true;
      control.setErrors(err);
      field.form.setErrors(err);
      return err;
    }
  }

  return null;
}

@Component({
  selector: 'app-formly',
  templateUrl: './formly.component.html',
  styleUrls: ['./formly.component.scss'],
})
export class FormlyComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() context: Context;
  @Input() data;
  @Input() triggerSave;
  @Input() tabChange;
  @Input() currentStep;
  @Input() currentApp;
  @Input() externalSubmit = false;
  @ViewChild('buttons', { static: false }) buttons: ElementRef<HTMLElement>;
  @ViewChild('questionnaire', { static: true }) questionnaire: FormlyForm;
  @Output() stepsChanged = new EventEmitter();
  @Output() newProfile = new EventEmitter();
  @Output() formChange = new EventEmitter();

  private environment;

  isNaN: Function = Number.isNaN;
  questions = [];
  form = new UntypedFormGroup({}, { updateOn: 'change' });
  fields: FormlyFieldConfig[] = [];
  model = {};
  vars;
  sections = false;
  profile;
  app: string;
  nextButtonText: string;
  debug = false;
  loading = false;
  header = { label: 'Client', app: null };
  submitted = false;
  usedBeacon = false;
  beaconLog = [];
  beaconData;
  usedRixtrema = false;
  planData;
  newPlanData;
  groups = [];
  regAnnuities = [];
  regProducts = [];
  formErrors = [];
  optOutMessage =
    'The client has chosen not to provide necessary regulatory data and would like to proceed without it.';
  readOnly = false;
  saveAllowed = true;
  noSaveProfile = false;
  showWipSave = false;
  breadcrumbs = 'questionnaire';
  formContext: Context = {} as Context;
  regSkipped = false;
  rolloverData = [];
  hashQuestion = [];
  hashStatus = [];
  existingHash = [];
  rights = this.ss.get('rights');
  formChecks;
  customMessages = [];
  debouncer = new Subject();
  lastSave = null;
  triggerSaveObs;
  triggerValidateObs;
  renamed = {};
  nestedLoopList = [];
  unsubscribe = new Subject();
    stepsObs;
    stepsList;

  constructor(
    private rbs: RightBridgeApiService,
    private route: ActivatedRoute,
    private router: Router,
    private ss: SessionStorageService,
    private location: Location,
    private fb: FormBuilder,
    private beaconSrc: BeaconService,
    private lws: LifeWizardService,
    private rixtremaSrc: RixtremaService,
    private snacky: MatSnackBar,
    private admnSrvc: AdminService,
    private frmSrvc: FormService,
    private envSvc: EnvironmentService,
    private feeSvc: FundFeeLookupService,
    private dialog: MatDialog,
    private unfFlowSvc: UnifiedFlowService,
    private modStatusSvc: ModuleStatusService
  ) {
    beaconSrc.annuitySelected$.pipe(takeUntil(this.unsubscribe)).subscribe(
      ann => {
        this.handleBeacon(ann);
      },
      error => alert(error)
    );

    rixtremaSrc.planSelected$.pipe(takeUntil(this.unsubscribe)).subscribe(
      plan => {
        this.handleF5500(plan);
      },
      error => alert(error)
    );

    feeSvc.feeCalcData$.pipe(takeUntil(this.unsubscribe)).subscribe(
      calc => {
        this.handleFeeData(calc);
      },
      error => alert(error)
    );

    frmSrvc.hashingDone$.pipe(takeUntil(this.unsubscribe)).subscribe(
      status => {
        if (this.existingHash.length > this.hashStatus.length) {
          this.hashStatus = [];
        }
        this.hashStatus.push(status);
      },
      error => alert(error)
    );

    frmSrvc.clearIWFunds$.pipe(takeUntil(this.unsubscribe)).subscribe(
      () => {
        this.clearIWFunds();
      },
      error => alert(error)
    );

    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }
  }

  ngOnInit() {
    this.formContext = this.context ? this.context : this.formContext;
    this.readOnly = this.rights?.includes('ReadOnlyAccess');
    this.saveAllowed = this.rights?.includes('SaveWizard');
    this.noSaveProfile = this.rights?.includes('ReadOnly');
    this.ss.remove('selectedFunds');
    this.app = this.ss.get('currentApp');
    this.breadcrumbs = this.noSaveProfile
      ? 'no-save-questionnaire'
      : this.breadcrumbs;

    const firm = this.rbs.setFirmId();

    if (this.app == 'iw') {
      this.header.label = 'Case';
    } else if (this.environment.org?.toLowerCase() === 'sf') {
      this.header.label = 'Customer';
    } else if (
      (firm?.toLowerCase() === 'c' || firm?.toLowerCase() === 'allstate') &&
      this.app === 'li'
    ) {
      this.header.label = 'Owner';
    }

    switch (this.app) {
      case 'iw':
        this.nextButtonText = 'Next';
        this.header.app = 'Investment Wizard';
        break;
      case 'aw':
        this.nextButtonText = 'Evaluate Annuities';
        this.header.app = 'Annuity Wizard';
        break;
      case 'pp':
        this.nextButtonText = 'Review Results';
        this.header.app = 'Product Profiler';
        break;
      case 'cp':
        this.nextButtonText = 'View Client Needs';
        this.header.app = 'Client Profiler';
        break;
      case 'li':
        this.nextButtonText = 'Next';
        this.header.app = 'Life Insurance Wizard';
        break;
    }

    this.route.params.subscribe(params => {
      this.profile = params.id ? params.id : null;
      this.debug = params.debug || false;
    });
    this.loading = true;

    if (!this.context || (this.context && this.context.type != 'unified')) {
      this.triggerQuestionnaireBuild();
    }

    this.unfFlowSvc.setTriggerSave('initial');

    this.model['regSkipped'] = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'data':
            if (
              !isEqual(changes.data.currentValue, changes.data.previousValue)
            ) {
              this.app = this.ss.get('currentApp');
              this.triggerQuestionnaireBuild();
            }
            break;
          case 'externalSubmit':
            this.submitted = changes.externalSubmit.currentValue;
            this.formChange.emit({ model: this, form: this.form });
            break;
        }
      }
    }
  }

  ngOnDestroy() {
    this.profile = null;
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    //
    // Hack to keep the submit button from disappearing on non step questionnaires
    //
    // const el = this.buttons.nativeElement;
    // setTimeout(() => {
    //   el.focus();
    //   el.click();
    // }, 500);

    if (this.context && this.context.type == 'unified') {
      const saving = this.unfFlowSvc.getSaving();
      if (!this.triggerSaveObs) {
        this.triggerSaveObs = this.unfFlowSvc.triggerSaveExternal
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(x => {
            const results = x;
            if (!saving && this.profile == results['id']) {
              this.lastSave = results['msg'];

              if (results['msg'] && results['msg'] != 'initial') {
                this.saveForm(this.model, true, true);
              }
            }
          });
      }

      if (!this.triggerValidateObs) {
        this.triggerValidateObs = this.unfFlowSvc.triggerValidateExternal;
        this.triggerValidateObs.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
          this.submitted = true;

          // this.validateFields(fieldsToValidate, null, x.validate === 'all');
          
          
          const visible = this.fields[0].fieldGroup.filter(x => !x.hide);
          const fieldsToValidate = x.validate != 'all' && visible[x.validate].fieldGroup ? visible[x.validate].fieldGroup : visible;
          // const fieldsToValidate = visible[x.validate].fieldGroup ? visible[x.validate].fieldGroup : this.form.controls;
          this.validateFields(fieldsToValidate);
        });
      }
    }
  }

  resetModel() {
    this.model = {};

    this.model['regSkipped'] = false;
  }

  triggerQuestionnaireBuild() {
    this.form = new UntypedFormGroup({}, { updateOn: 'change' });
    this.resetModel();
    if (this.data && this.data.sections && this.data.sections.length > 0) {
      this.model['formChecks'] = this.data.formChecks;
      this.formatSections(this.data.sections, this.data.refVar);
      this.triggerInit();
    } else if (this.data) {
      this.formatQuestions(this.data);
      this.triggerInit();
    } else {
      const savedProfile = this.ss.get('noSave');
      if (this.noSaveProfile && this.profile && savedProfile) {
        const data = cloneDeep(savedProfile.questionnaire);

        if (savedProfile.questionnaire['formChecks']) {
          this.model['formChecks'] = {
            ...this.model,
            ...savedProfile.questionnaire['formChecks'],
          };
        }

        if (
          savedProfile.questionnaire['sections'] &&
          savedProfile.questionnaire['sections'].length > 0
        ) {
          this.formatSections(data.sections, data.refVar);
        } else {
          this.formatQuestions(data);
        }
      } else if (
        !this.context ||
        (this.context && this.context.type != 'unified')
      ) {
        this.rbs.getQuestions(this.profile).subscribe(data => {
          const questionnaire = data.results.questionnaire;
          this.model['formChecks'] = data.results.questionnaire.formChecks;
          if (questionnaire.sections && questionnaire.sections.length > 0) {
            this.formatSections(questionnaire.sections, questionnaire.refVar);
            this.triggerInit();
          } else {
            this.formatQuestions(questionnaire);
          }
        });
      }
    }
  }

  updateState() {
    if (this.context && this.context.type && this.context.type == 'unified') {
      this.stepsChanged.emit(this.fields[0].fieldGroup);
    }
  }

  formatSections(data, refVar) {
    let context = 'stepper';

    if (this.context && this.context.type) {
      switch (this.context.type) {
        case 'unitProfile':
          context = 'tabs';
          break;
        case 'unified':
          context = 'unified';
          break;
      }
    }

    this.sections = true;
    const sections = data;
    this.fields = [
      {
        type: context,
        props: {
          context: this.formContext,
        },
        options: {},
        fieldGroup: [],
        hooks: {
          onChanges: field =>
            field.formControl.valueChanges.pipe(
              distinctUntilChanged(),
              tap(value => value)
            ),
        },
      },
    ];

    sections.forEach(sct => {
      this.groups.push(...sct.groups);
      this.questions.push(...sct.questions);
    });

    sections.forEach(sct => {
      let condit = null;

      if (sct.conditional && !sct.conditionalReady) {
        condit = this.buildConditional(sct.conditional);
      } else if (sct.conditional && sct.conditionalReady) {
        condit = sct.conditional;
      }

      const step = {
        props: {
          label: sct.label,
          right: sct.right,
        },
        options: {},
        fieldGroup: [],
        hooks : {
          onInit(field) {
            // field.onPopulate(field);
          }
        },
        expressions: {
          hide: field => {
            if (condit) {
              const evaluate =
                condit && condit.length
                  ? !this.evalConditional(condit, null, field)
                  : false;
              return evaluate;
            }
          },

        },
      };

      step.fieldGroup = this.formatQuestions(sct, true, refVar);
      this.fields[0].fieldGroup.push(step);

      const savedProfile = this.ss.get('noSave');

      if (this.noSaveProfile && this.profile && savedProfile) {
        if (savedProfile.model) {
          this.model = { ...savedProfile.model };
        }
        if (
          savedProfile.questionnaire &&
          savedProfile.questionnaire['formChecks']
        ) {
          this.model['formChecks'] = savedProfile.questionnaire['formChecks'];
        }
      }
    });
    this.updateState();
    this.nestedLoopTransform();
    this.loading = false;
  }

  formatQuestions(data, sections = false, refVar?) {
    if (!sections) {
      this.groups = data.groups;
      this.questions = data.questions;
    }

    this.vars = refVar || data.refVar;

    if (this.vars) {
      this.vars.forEach(e => {
        if (
          e.varName === 'ClientProfilingOut.SubmitStatus' &&
          e.rawValue === 'S' &&
          this.rights?.includes('locksubmittedcase')
        ) {
          this.readOnly = true;
        }

        if (
          e.varName === 'ClientProfilingOut.SubmitStatus' &&
          e.rawValue === 'S' &&
          !this.rights?.includes('locksubmittedcase')
        ) {
          this.readOnly = false;
        }
        this.model[e.varName.replace('.', '_')] =
          e.rawValue || typeof e.rawValue === 'boolean' ? e.rawValue : '';
      });
    }

    this.modelUpdate(this.model);

    const tempFields: FormlyFieldConfig[] = [];
    data.questions.forEach(x => {
      let type = 'input';

      if (!x.controlType) {
        x.controlType = 'text';
        console.warn(
          'No ControlType present on: ',
          x.varName,
          ' defaulting to text input.'
        );
      }
      switch (x.controlType.toLowerCase()) {
        case 'text':
          type = 'custom-input';
          break;
        case 'textexplanation':
          type = 'custom-input-exp';
          break;
        case 'textbox':
          type = 'custom-input';
          break;
        case 'textarea':
          type = 'custom-textarea';
          break;
        case 'dropdown':
          type = 'custom-drop';
          break;
        case 'dropexplanation':
          type = 'custom-drop-exp';
          break;
        case 'typeahead':
          type = 'custom-type-ahead';
          break;
        case 'currency':
          type = 'custom-currency';
          break;
        case 'currencyexplanation':
          type = 'custom-currency-exp';
          break;
        case 'rankorder':
          type = 'custom-rankorder';
          break;
        case 'radiobutton':
          type = 'custom-radio';
          break;
        case 'radioexplanation':
          type = 'custom-radio-exp';
          break;
        case 'radiovalidate':
          type = 'custom-radio-validate';
          break;
        case 'radiovalidateexplanation':
          type = 'custom-radio-validate-exp';
          break;
        case 'integerexplanation':
          type = 'custom-integer-exp';
          break;
        case 'numberexplanation':
          type = 'custom-number-exp';
          break;
        case 'number':
          type = 'custom-number';
          break;
        case 'multiselect':
          type = 'custom-multi-select';
          break;
        case 'slider':
          type = 'custom-radio';
          break;
        case 'checkbox':
          type = 'custom-checkbox';
          break;
        case 'percentage':
          type = 'custom-percentage';
          break;
        case 'percentageexplanation':
          type = 'custom-percentage-exp';
          break;
        case 'message':
          type = 'custom-message';
          break;
        case 'sectionheader':
          type = 'custom-sectionHeader';
          break;
        case 'date':
          type = 'custom-date';
          break;
        case 'datecalc':
          type = 'custom-date-calc';
          break;
        case 'button':
          type = 'custom-button';
          break;
        case 'validatedcheck':
          if (Array.isArray(x.rawValue)) {
            const newArr = [];
            x.rawValue.forEach(el => {
              if (
                +el === 0 ||
                (typeof x.rawValue == 'string' &&
                  x.rawValue.toLowerCase() === 'n') ||
                !el
              ) {
                newArr.push(false);
              } else {
                newArr.push(true);
              }
            });
            x.rawValue = newArr;
          } else {
            if (
              +x.rawValue === 0 ||
              (typeof x.rawValue == 'string' &&
                x.rawValue.toLowerCase() === 'n') ||
              !x.rawValue
            ) {
              x.rawValue = false;
            } else {
              x.rawValue = true;
            }
          }
          type = 'custom-yesno';
          break;
        case 'hash':
          type = 'custom-hash';
          this.hashQuestion.push(x);
          if (x.rawValue && x.rawValue.length > 0) {
            this.existingHash.push(x);
          }
          break;
        case 'insuredswap':
          type = 'custom-insuredSwap';
          break;
        case 'repcode':
          type = 'custom-repCode';
          break;
        case 'status':
          type = 'custom-status';
          break;
        case 'gridExpand':
          type = 'custom-gridExpand';
          break;
        case 'datagrid':
          type = 'custom-dataGrid';
          break;
        case 'grid':
          type = 'custom-grid';
          break;
        case 'stepper':
          type = 'stepper';
          break;
        case 'unified':
          type = 'unified';
          break;
        case 'tabs':
          type = 'tabs';
          break;
        default:
          type = 'custom-input';
      }

      let condit = null;
      const inGroups = data.groups.find(y => y.id == x.group) || false;

      if (x.dynamicValidation) {
        let desc;

        x.options = [];
        const val = this.vars.find(
            z => z.varName == x.dynamicValidation.val
          ).rawValue,
          disp = this.vars.find(
            z => z.varName == x.dynamicValidation.display
          ).rawValue;

        if (x.dynamicValidation.description) {
          desc = this.vars.find(
            z => z.varName == x.dynamicValidation.description
          ).rawValue;
        }

        if (desc) {
          val.forEach((z, i) => {
            x.options.push({
              label: disp[i],
              value: val[i],
              description: desc[i],
            });
          });
        } else if (val) {
          val.forEach((z, i) => {
            x.options.push({ label: disp[i], value: val[i] });
          });
        }
      }

      if (x.conditional && inGroups && !x.conditionalReady) {
        condit = this.buildConditional(x.conditional, x.group);
      } else if (x.conditional && !x.conditionalReady) {
        condit = this.buildConditional(x.conditional);
      } else if (x.conditional && x.conditionalReady) {
        condit = x.conditional;
      }

      if (x.changeAction) {
        switch (x.changeAction) {
          case 'checkRegInit':
            x.change = () => {
              this.checkRegInit();
            };
            break;
          case 'initRegProcess':
            x.change = () => {
              this.initRegProcess();
            };
            break;
          case 'getRegAnnuities':
            x.change = (field, ctrl) => {
              setTimeout(() => this.getRegAnnuities(ctrl.value));
            };
            break;
          case 'getRegProducts':
            x.change = () => {
              setTimeout(() => this.getRegProducts(null));
            };
            break;
          case 'getRegRiders':
            x.change = (field, ctrl) => {
              setTimeout(() => this.getRegRiders(ctrl.value, true));
            };
            break;
          case 'clearRegData':
            x.change = () => {
              setTimeout(() => this.clearRegData());
            };
            break;
          case 'purposeChange':
            x.change = () => {
              setTimeout(() => this.purposeChange());
            };
            break;
          case 'fourOhFourChange':
            x.change = (field, ctrl) => {
              setTimeout(() => this.fourOhFourChange(ctrl, field));
            };
            break;
          case 'fourOhFourChangeNP':
            x.change = (field, ctrl) => {
              setTimeout(() => this.fourOhFourChangeNP(ctrl));
            };
            break;
          case 'mapPurposeValue':
            x.change = (field, ctrl) => {
              setTimeout(() => this.mapPurposeValue(ctrl));
            };
            break;
          case 'ClearIWFunds':
            x.change = () => {
              setTimeout(() => this.clearIWFunds());
            };
            break;
        }
      }

      if (x.group && inGroups) {
        const currgroup = data.groups.find(g => {
          return x.group == g.id;
        });

        let existing;

        if (currgroup.hasOwnProperty('parentGroup')) {
          existing = tempFields.find(g => {
            return g.key == currgroup.parentGroup;
          });

          existing = existing.fieldArray['fieldGroup'].find(g => {
            return g.key == x.group;
          });
        } else {
          existing = tempFields.find(g => {
            return g.key == x.group;
          });
        }

        const repeating = currgroup.looping;
        const multiCheckbox = currgroup.type
          ? currgroup.type.toLowerCase() === 'multicheckbox'
          : false;

        let specialClass = x.width && x.width.length ? ' ' + x.width : '';
        specialClass += x.stacked ? ' stacked' : '';
        specialClass += x.additionalClass ? ' ' + x.additionalClass : '';
        specialClass +=
          x.hidden && typeof x.hidden === 'boolean' ? ' visual-hidden' : '';
        specialClass +=
          x.controlType.toLowerCase() == 'sectionheader' ? ' no-margin' : '';

        let validatorsList = [];

        if (x.formCheck) {
          validatorsList = this.setValidations(
            x.formCheck,
            this.model['formChecks']
          );
        }

        const field = this.buildField(
          x,
          type,
          specialClass,
          condit,
          validatorsList,
          repeating,
          true
        );

        if (typeof existing != 'undefined' && repeating) {
          existing.fieldArray['fieldGroup'].push(field);
        } else if (typeof existing != 'undefined' && !repeating) {
          existing.fieldGroup.push(field);
        } else if (typeof existing == 'undefined' && repeating) {
          let group = {};
          const groupData = inGroups;
          let specialClass =
            groupData.width && groupData.width.length
              ? ' ' + groupData.width
              : '';
          specialClass += groupData.additionalClass
            ? ' ' + groupData.additionalClass
            : '';
          specialClass += groupData.hasOwnProperty('parentGroup')
            ? ' expanded-width'
            : '';

          if (groupData.conditional && !groupData.conditionalReady) {
            condit = this.buildConditional(groupData.conditional);
          } else if (groupData.conditional && groupData.conditionalReady) {
            condit = groupData.conditional;
          }

          let repeatType;
          if (
            (repeating == 'compact' && groupData.table) ||
            repeating == 'table'
          ) {
            repeatType = 'custom-grid';
          } else if (repeating == 'expand' && groupData.table) {
            repeatType = 'custom-gridExpand';
          } else if (repeating == 'compact' && !groupData.table) {
            repeatType = 'custom-compactRepeat';
          } else {
            repeatType = 'custom-repeating';
          }

          group = {
            key: x.group,
            type: repeatType,
            fieldArray: {
              fieldGroupclassName: 'col-12 row group-container ' + specialClass,
              props: {
                btnText: groupData.buttonText,
                externalBtnText: groupData.productShelfText,
                layout: groupData.table ? 'table' : null,
                search: groupData.search || null,
                showTemplates: groupData.showTemplates || null,
                showTotals: groupData.showTotals || null,
                defaultOpen: groupData.open || false,
                nested: groupData.hasOwnProperty('parentGroup'),
                entryLimit: groupData?.maxentries,
                minEntries: groupData?.minentries,
                disableCheck:
                  this.vars?.findIndex(
                    x =>
                      x.varName === 'ClientProfilingOut.SubmitStatus' &&
                      x.rawValue === 'S'
                  ) > -1 && this.rights?.includes('locksubmittedcase'),
              },
              fieldGroup: [field],
            },
            expressions: {
              hide: (field, model) => {
                let localModel;
                if (
                  model &&
                  model.model &&
                  (condit?.includes('CurrentAnnuityDetailsArray') ||
                    condit?.includes('EnterAccountHoldings') ||
                    condit?.includes('field.parent'))
                ) {
                  localModel = model.model;
                }

                if (groupData.conditional) {
                  const evaluate =
                    condit && condit.length
                      ? !this.evalConditional(condit, localModel || null, field)
                      : false;
                  return evaluate;
                }
              },
              className: (values, control, checkedGroup) => {
                let className =
                  'col-12 field-group repeat-container ' +
                  specialClass +
                  ' ' +
                  x.group;
                if (
                  checkedGroup?.formControl &&
                  !checkedGroup.formControl.valid &&
                  checkedGroup.form.touched &&
                  multiCheckbox
                ) {
                  className += ' error';
                }
                className += groupData.hasOwnProperty('parentGroup')
                  ? ' expanded-width'
                  : '';
                return className;
              },
              showButton: () => {
                if (groupData.buttonCondition) {
                  return this.evalNonStandardConditional(
                    groupData.buttonCondition
                  );
                }
                return true;
              },
              showExternalButton: () => {
                if (groupData.productShelfCondition) {
                  return this.evalConditional(groupData.productShelfCondition);
                }
                return true;
              },
              showCsvFunctions: () => {
                if (groupData.showCSV && typeof groupData.hidden != 'boolean') {
                  return this.evalConditional(groupData.showCSV);
                } else if (
                  groupData.showCSV &&
                  typeof groupData.hidden === 'boolean'
                ) {
                  return true;
                }
                return false;
              },
            },
          };

          if (groupData.hasOwnProperty('parentGroup')) {
            const parent = tempFields.find(
              x => x.key === groupData.parentGroup
            );
            parent.fieldArray['fieldGroup'].push(group);
          } else {
            tempFields.push(group);
          }
        } else {
          let group = {};
          const validatorsList = multiCheckbox ? [groupValidation] : [];
          const groupData = inGroups;
          let specialClass =
            groupData.width && groupData.width.length
              ? ' ' + groupData.width
              : '';
          specialClass += groupData.additionalClass
            ? ' ' + groupData.additionalClass
            : '';
          specialClass += groupData.hasOwnProperty('parentGroup')
            ? ' expanded-width'
            : '';

          if (groupData.conditional && !groupData.conditionalReady) {
            condit = this.buildConditional(groupData.conditional);
          } else if (groupData.conditional && groupData.conditionalReady) {
            condit = groupData.conditional;
          }

          group = {
            key: x.group,
            wrappers: ['panel'],
            fieldGroupClassName:
              'w-100 row field-group ' + x.group + ' ' + specialClass,
            props: {
              label: x.group,
              layout: groupData.table ? 'table' : null,
              search: groupData.search || null,
              showTemplates: groupData.showTemplates || null,
              showTotals: groupData.showTotals || null,
              defaultOpen: groupData.open || false,
              entryLimit: groupData?.maxentries,
              minEntries: groupData?.minentries,
              disableCheck:
                this.vars?.findIndex(
                  x =>
                    x.varName === 'ClientProfilingOut.SubmitStatus' &&
                    x.rawValue === 'S'
                ) > -1 && this.rights?.includes('locksubmittedcase'),
            },
            fieldGroup: [field],
            validators: { validation: validatorsList },
            expressions: {
              hide: field => {
                if (groupData.conditional) {
                  const evaluate =
                    condit && condit.length
                      ? !this.evalConditional(condit, null, field)
                      : false;
                  return evaluate;
                }
              },
              className: (values, control, checkedGroup) => {
                let className = 'col-12 group-container' + specialClass;
                if (
                  checkedGroup?.formControl &&
                  !checkedGroup.formControl.valid &&
                  checkedGroup.form.touched &&
                  multiCheckbox
                ) {
                  className += ' error';
                }
                return className;
              },
            },
          };

          if (groupData.hasOwnProperty('parentGroup')) {
            const parent = tempFields.find(
              x => x.key === groupData.parentGroup
            );
            parent.fieldArray['fieldGroup'].push(group);
          } else {
            tempFields.push(group);
          }
        }
      } else {
        let field;
        let specialClass = x.width && x.width.length ? ' ' + x.width : '';
        specialClass += x.stacked ? ' stacked' : '';
        specialClass += x.additionalClass ? ' ' + x.additionalClass : '';
        specialClass +=
          x.controlType.toLowerCase() == 'sectionheader' ? ' no-margin' : '';

        let validatorsList = [];

        if (x.formCheck) {
          validatorsList = this.setValidations(
            x.formCheck,
            this.model['formChecks']
          );
        }
        field = this.buildField(x, type, specialClass, condit, validatorsList);
        tempFields.push(field);
      }

      const group = data.groups.find(z => z.id == x.group);
      const parentGroup =
        group && group.parentGroup
          ? data.groups.find(z => {
              return z.id == group.parentGroup;
            })
          : null;

      if (parentGroup) {
        this.nestedLoopList.push(group);
      }
      if (x.rawValue === true || (x.rawValue && x.rawValue.length > 0)) {
        if (Array.isArray(x.rawValue) && x.group && group && group.looping) {
          x.rawValue.forEach((e, i) => {
            if (
              type == 'custom-percentage' ||
              type == 'custom-percentage-exp'
            ) {
              const val =
                x.rawValue[i] || x.rawValue[i] === 0 || x.rawValue[i] === '0'
                  ? +x.rawValue[i]
                  : null;

              if (val || val == 0) {
                this.setGroupModelProperties(
                  group,
                  x,
                  `${this.formatPercent(val, x.decimalprecision)}%`,
                  i
                );
              }
            } else if (type == 'custom-date') {
              this.setGroupModelProperties(group, x, new Date(x.display[i]), i);
            } else {
              this.setGroupModelProperties(group, x, x.rawValue[i], i);
            }
          });
        } else if (x.group && group) {
          if (type == 'custom-percentage' || type == 'custom-percentage-exp') {
            const val =
              x.rawValue || x.rawValue === 0 || x.rawValue === '0'
                ? +x.rawValue
                : null;
            if (val || val == 0) {
              this.setGroupModelProperties(
                group,
                x,
                `${this.formatPercent(val, x.decimalprecision)}%`
              );
            }
          } else if (type == 'custom-date') {
            this.setGroupModelProperties(group, x, new Date(x.display));
          } else {
            const val =
              Object.prototype.toString.call(x.rawValue) === '[object Object]'
                ? x.rawValue[0]
                : x.rawValue;
            this.setGroupModelProperties(group, x, val);
          }
        } else {
          if (type == 'custom-percentage' || type == 'custom-percentage-exp') {
            const val =
              x.rawValue || x.rawValue === 0 || x.rawValue === '0'
                ? +x.rawValue
                : null;
            if (val || val == 0) {
              this.model[x.varName.replace('.', '_')] = `${this.formatPercent(
                val
              )}%`;
            }
          } else if (type == 'custom-date') {
            this.model[x.varName.replace('.', '_')] = new Date(x.display);
          } else {
            this.model[x.varName.replace('.', '_')] =
              Object.prototype.toString.call(x.rawValue) === '[object Object]'
                ? x.rawValue[0]
                : x.rawValue;
          }
        }
      } else if (x.rawValue === false) {
        if (x.group && data.groups.find(z => z.id == x.group)) {
          this.setGroupModelProperties(group, x, x.defaultValue);
        } else {
          this.model[x.varName.replace('.', '_')] = x.defaultValue;
        }
      } else if (x.defaultValue && !x.rawValue) {
        if (x.group && data.groups.find(z => z.id == x.group)) {
          this.setGroupModelProperties(group, x, x.defaultValue);
        } else {
          this.model[x.varName.replace('.', '_')] = x.defaultValue;
        }
      }
    });

    if (!sections) {
      this.fields = [{ type: null, fieldGroup: tempFields }];
      this.triggerInit();
    } else {
      return tempFields;
    }

    this.loading = false;
    this.showWipSave = true;
    this.unfFlowSvc.setLoading(false);
  }

  setValidations(check, validations) {
    const list = [];
    if (check && check.length > 0) {
      let customCriteriaCheck = false;

      check.forEach(el => {
        if (validations) {
          const checkType = validations.find(z => z.id == el);

          customCriteriaCheck = checkType && checkType.type == 'customCriteria';
        }
      });

      if (customCriteriaCheck) {
        list.push(customCriteriaValidation);
      } else {
        if (
          check &&
          check.includes('required') &&
          !check.includes('regRequired')
        ) {
          list.push(Validators.required);
        }

        if (check && check.includes('regRequired')) {
          if (this.app == 'aw' && !this.model['RequiredQuestions_AWSkipped']) {
            this.model['regSkipped'] = false;
          } else if (
            this.app == 'aw' &&
            this.model['RequiredQuestions_AWSkipped']
          ) {
            this.model['regSkipped'] = true;
          } else if (!this.model['RequiredQuestions_Skipped']) {
            this.model['regSkipped'] = false;
          } else if (this.model['RequiredQuestions_Skipped']) {
            this.model['regSkipped'] = true;
          }
          list.push(regValidation);
        }
      }
    }

    return list;
  }

  buildField(
    fieldData,
    type,
    specialClass,
    condit,
    validatorsList,
    repeating?,
    group?
  ) {

    let addtnlData;

    const that = this;

    if (type == 'custom-insuredSwap') {
      addtnlData = {
        profile: this.profile,
        app: this.app,
      };

      specialClass += ' insured-swap';
    }

    return {
      key: fieldData.varName.replace('.', '_'),
      type: type,
      className: 'col-sm-12 col-md-6' + specialClass,
      defaultValue: this.setDefault(fieldData),
      modelOptions: {
        updateOn: type === 'custom-date' ? 'blur' : 'change',
      },
      props: {
        label: fieldData.label,
        placeholder: fieldData.placeholder,
        prompt: fieldData.prompt,
        options: fieldData.options ? fieldData.options : [],
        help: fieldData.help,
        messages: fieldData.messages,
        messageType: fieldData.messageType || null,
        min: typeof fieldData.min == 'number' ? fieldData.min : null,
        max: typeof fieldData.max == 'number' ? fieldData.max : null,
        number:
          typeof fieldData.min == 'number' && typeof fieldData.max == 'number'
            ? true
            : false,
        buttonType: fieldData.buttonType || null,
        onClick: fieldData.onClick
          ? () => {
              this.evalConditional(fieldData.onClick);
            }
          : null,
        change: fieldData.change,
        varName: fieldData.varName.replace('.', '_'),
        decimalPrecision: fieldData.decimalprecision
          ? fieldData.decimalprecision
          : 3,
        maxLength: fieldData.maxlength ? fieldData.maxlength : null,
        model: this.model,
        data: addtnlData,
        hashType: fieldData.hashtype || null,
        gridId: fieldData.gridId,
        filterExcel: fieldData.filterExcel,
        layout: fieldData.table ? 'table' : null,
        search: fieldData.search || null,
        formChecks: fieldData.formCheck,
        showTemplates: fieldData.showTemplates || null,
        showTotals: fieldData.showTotals || null,
        context: this.formContext,
        labelBorder: fieldData.labelBorder,
        rawValues:
          fieldData.varName.replace('.', '_') == 'Client_Rep_RepCode'
            ? { label: fieldData.display[0], value: fieldData.rawValue[0] }
            : null,
        group: fieldData.group,
        subField: fieldData?.subField,
        entryLimit: fieldData?.maxEntries,
        minEntries: fieldData?.minentries,
      },
      validators: { validation: validatorsList },

      hooks: {
        onInit(field) {
          const control = field.formControl;
          if (
            fieldData.defaultConditional &&
            that.evalConditional(fieldData.defaultConditional) &&
            control &&
            (control.value === null || control.value === '')
          ) {
            control.setValue(fieldData.defaultIfTrue);
          } else if (
            control &&
            control.value === null &&
            fieldData.defaultValue
          ) {
            control.setValue(fieldData.defaultValue);
          }
          // control.setValidators(validatorsList)
        },
      },

      /////
      ///// Expression Properties don't work properly if "extras: { checkExpressionOn: 'modelChange' }" is enabled in the form module
      /////
      expressions: {
        hide: (field, model) => {
          const that = this;
          let localModel;
          if (model && model.model && repeating) {
            localModel = model.model;
          }
          if (!repeating && group) {
            model = this.model;
          } else if (model && model.hasOwnProperty('expressions')) {
            model = this.model;
          }

          if (fieldData.conditional) {
            const evaluate =
              condit && condit.length
                ? !that.evalConditional(condit, localModel || null, field)
                : false;
            return evaluate;
          }
        },
        className: (field, model) => {
          let classes = 'col-sm-12 col-md-6' + specialClass;
          let localModel;
          if (model && model.model && repeating) {
            localModel = model.model;
          }
          if (
            (fieldData.hidden && typeof fieldData.hidden === 'boolean') ||
            (fieldData.hidden &&
              typeof fieldData.hidden != 'boolean' &&
              this.evalConditional(fieldData.hidden, localModel || null, field))
          ) {
            classes += ' visual-hidden';
          }
          return classes;
        },
        'props.required': field => {
          const model = this.model;

          if (
            fieldData.unitVarNotRequired &&
            this.model[fieldData.unitVarNotRequired.replace('.', '_')]
          ) {
            field.formControl.setValidators(null);
            field.formControl.updateValueAndValidity();
            return false;
          } else if (fieldData.formCheck && !fieldData.hidden) {
            if (
              fieldData.formCheck.includes('required') &&
              !fieldData.formCheck.includes('regRequired')
            ) {
              return true;
            }
          }
          return false;
        },
        'props.prompt': () => {
          const that = this;
          const model = this.model;
          const varReg = /{{.*?}}/gi;
          let prompt = fieldData.prompt;

          if (prompt) {
            const varMatch = prompt.match(varReg);
            if (varMatch && varMatch.length > 0) {
              varMatch.forEach(el => {
                let varName = el.replace('{{', '');
                varName = varName.replace('}}', '');

                if (fieldData.evalPrompt == 'solve') {
                  prompt = prompt.replace(el, that.evalConditional(varName));
                } else if (fieldData.evalPrompt == 'display') {
                  const val = this.vars.find(z => {
                    return z.varName == varName;
                  });
                  if (val && val.display) {
                    prompt = prompt.replace(el, val.display);
                  }
                } else if (typeof varName != 'undefined') {
                  const split = varName.split('.');
                  varName =
                    split.length > 2
                      ? `${split[0]}.${split[1]}_${split[2]}`
                      : varName.replace('.', '_');
                  if (split.length > 2) {
                    prompt = prompt.replace(
                      el,
                      model[split[0]][`${split[1]}_${split[2]}`]
                    );
                  } else {
                    prompt = prompt.replace(el, model[varName]);
                  }
                } else {
                  return null;
                }
              });
            }
            return prompt;
          } else if (fieldData.evalPrompt && !prompt) {
            console.warn(`No Prompt To Evaluate On ${fieldData.varName}`);
            return '!!! Invalid Prompt Evaluate UI Config Call !!!';
          } else {
            return null;
          }
        },
        'props.label': (mdl, esc, ctrl) => {
          const that = this;
          const model = this.model;
          const varReg = /{{.*?}}/gi;
          let label = fieldData.label;

          if (label) {
            const varMatch = label.match(varReg);
            if (varMatch && varMatch.length > 0) {
              varMatch.forEach(el => {
                let varName = el.replace('{{', '');
                varName = varName.replace('}}', '');

                if (fieldData.evalLabel == 'solve') {
                  label = label.replace(el, that.evalConditional(varName));
                } else if (fieldData.evalLabel == 'display') {
                  const val = this.vars.find(z => {
                    return z.varName == varName;
                  });
                  if (val && val.display) {
                    label = label.replace(el, val.display);
                  }
                } else if (typeof varName != 'undefined') {
                  const split = varName.split('.');
                  varName =
                    split.length > 2
                      ? `${split[0]}.${split[1]}_${split[2]}`
                      : varName.replace('.', '_');
                  if (split.length > 2) {
                    label = label.replace(
                      el,
                      model[split[0]][`${split[1]}_${split[2]}`]
                    );
                  } else {
                    label = label.replace(el, model[varName]);
                  }
                } else {
                  return null;
                }
              });
            }
            return (
              label +
              ((fieldData.formCheck?.includes('required') &&
                ctrl?.props.disabled) ||
              (fieldData.formCheck?.includes('regRequired') &&
                !model['regSkipped'])
                ? '*'
                : '')
            );
          } else if (fieldData.evalLabel && !label) {
            console.warn(`No Label To Evaluate On ${fieldData.varName}`);
            return '!!! Invalid Label Evaluate UI Config Call !!!';
          } else {
            return null;
          }
        },
        'props.placeholder': (mdl, esc, ctrl) => {
          const that = this;
          const model = this.model;
          const varReg = /{{.*?}}/gi;
          let placeholder = fieldData.placeholder;
          if (placeholder) {
            const varMatch = placeholder.match(varReg);
            if (varMatch && varMatch.length > 0) {
              varMatch.forEach(el => {
                let varName = el.replace('{{', '');
                varName = varName.replace('}}', '');

                if (fieldData.evalPlaceholder == 'solve') {
                  placeholder = placeholder.replace(
                    el,
                    that.evalConditional(varName)
                  );
                } else if (fieldData.evalPlaceholder == 'display') {
                  const val = this.vars.find(z => {
                    return z.varName == varName;
                  });
                  if (val && val.display) {
                    placeholder = placeholder.replace(el, val.display);
                  }
                } else if (typeof varName != 'undefined') {
                  const split = varName.split('.');
                  varName =
                    split.length > 2
                      ? `${split[0]}.${split[1]}_${split[2]}`
                      : varName.replace('.', '_');
                  if (split.length > 2) {
                    placeholder = placeholder.replace(
                      el,
                      model[split[0]][`${split[1]}_${split[2]}`]
                    );
                  } else {
                    placeholder = placeholder.replace(el, model[varName]);
                  }
                } else {
                  return null;
                }
              });
            }
            return (
              placeholder +
              ((fieldData.formCheck?.includes('required') &&
                ctrl?.props.disabled) ||
              (fieldData.formCheck?.includes('regRequired') &&
                !model['regSkipped'])
                ? '*'
                : '')
            );
          } else if (fieldData.evalPlaceholder && !placeholder) {
            console.warn(`No Placeholder To Evaluate on ${fieldData.varName}`);
            return '!!! Invalid Placeholder Evaluate UI Config Call !!!';
          } else {
            return null;
          }
        },
        'props.disabled': (mdl, esc, ctrl) => {
          const that = this;
          const localModel = mdl.model;

          if (
            this.vars &&
            this.vars.find(
              varcheck =>
                varcheck.varName === 'ClientProfilingOut.SubmitStatus' &&
                varcheck.rawValue === 'S'
            ) &&
            this.rights?.includes('locksubmittedcase')
          ) {
            return true;
          }

          if (fieldData.readonly) {
            const value =
              typeof fieldData.readonly == 'string'
                ? that.evalConditional(fieldData.readonly, localModel)
                : fieldData.readonly;
            return value;
          } else if (ctrl?.props.disabled) {
            return true;
          } else {
            return null;
          }
        },
      },
    };
  }

  buildConditional(cond, group?) {
    const conditionals = this.formatConditional(cond);
    let condit = '';

    for (let i = 0; i < conditionals.split.length; i++) {
      if (i == 0 || i == 4 || i == 8 || i == 12 || i == 16 || i == 20) {
        if (
          (group &&
            !this.vars.find(
              t => t.varName.replace('.', '_') == conditionals.split[i]
            )) ||
          (group &&
            !this.vars.find(
              t => t.varName.replace('.', '_') == conditionals.split[i]
            ))
        ) {
          condit += 'model.' + group + '.' + conditionals.split[i];
        } else {
          condit += 'model.' + conditionals.split[i];
        }
      } else if (i == 3 || i == 7 || i == 11 || i == 15 || i == 19) {
        condit += conditionals.split[i];
      } else {
        condit += conditionals.split[i];
      }
    }

    return condit;
  }

  formatConditional(x) {
    const condi = x && x.length > 1 ? x.split(' ') : x,
      reg = new RegExp('[a-z0-9].[a-z0-9]', 'i');

    const newArr = [];

    condi.forEach(e => {
      if (reg.test(e)) {
        newArr.push(e.replace('.', '_'));
      } else {
        newArr.push(e);
      }
    });

    const newCondi = { full: newArr.join(' '), split: newArr, var: newArr[0] };
    return newCondi;
  }

  triggerInit() {
    setTimeout(() => {
      if (
        (this.model['ValidateAnnuity'] &&
          this.model['ValidateAnnuity'].AnnuityWizard_CarrierGroup &&
          this.model['ValidateAnnuity'].AnnuityWizard_CusipToValidate) ||
        (this.model['LIRecommendation'] &&
          this.model['LIRecommendation']
            .LIPolicyValidation_CarrierGroupToValidate &&
          this.model['LIRecommendation'].LIPolicyValidation_LifeItemToValidate)
      ) {
        this.initRegProcess(false);
      } else if (
        this.model['ValidateAnnuity'] &&
        Object.keys(this.model['ValidateAnnuity']).length === 0 &&
        this.model['AnnuityWizard_AssessmentType'] == 2
      ) {
        this.initRegProcess();
      } else if (
        this.model['ValidateAnnuity'] &&
        Object.keys(this.model['ValidateAnnuity']).length > 0 &&
        this.model['AnnuityWizard_AssessmentType'] == 2
      ) {
        this.initRegProcess(false);
      } else if (
        this.model['UnitQuestions_TriggerValidation'] &&
        this.model['LifeWizard_AssessmentType'] == 2
      ) {
        this.initRegProcess();
      } else if (
        (this.model['LifeWizard_PurchaseType'] &&
          this.model['LifeWizard_PurchaseType'] == 2) ||
        (this.model['UnitRegulationState_States'] &&
          this.model['UnitRegulationState_States'].includes(
            this.model['LifeWizard_StateOfIssue']
          ))
      ) {
        this.initRegProcess();
      }

      if (this.readOnly) {
        // TODO: When Jacob puts the ticket in add a check for the unit profile and always enable
        setTimeout(() => {
          this.form.disable();
        });
      }
    }, 500);

    this.optOutMessage = this.model['UnitRegulation_OptOutMessage']
      ? this.model['UnitRegulation_OptOutMessage']
      : this.optOutMessage;
  }

  formatChangeProcess(id, obj) {
    this.initRegProcess();
    const trigger = obj.value;
    const action = obj.func;
    const func = new Function(
      'return function (field, ctrl, that){if(ctrl.value == ' +
        trigger +
        '){' +
        action +
        '} }'
    )();

    return func;
  }

  addToModel(field, value) {
    this.model[field] = value;
  }

  getErrors(fg, groupFields = false) {
    // if (!groupFields) {
    //   this.formErrors = [];
    // }

    this.formErrors = [];
    const errors = [];

    
    let controls = [];
    
    if (fg instanceof FormGroup || fg instanceof FormArray) {
      Object.keys(fg.controls).forEach(z => {
        controls.push(fg.controls[z]);
      })
    } else {
      controls = [...fg];
    }

    controls.forEach(field => {
      if (field.formControl && field.formControl.errors && !field.hide) {
         Object.keys(field.formControl.errors).forEach(z => {if (this.isNaN(+z))errors.push(z)});
      } else if (
        (field.formControl instanceof FormGroup ||
          field.formControl instanceof FormArray) &&
        !field.hide
      ) {
        field.fieldGroup.forEach(grpField => {
          if (
            grpField.formControl &&
            grpField.formControl.errors &&
            !grpField.hide
          ) {
            Object.keys(grpField.formControl.errors).forEach(z => {if (this.isNaN(+z))errors.push(z)});
          } else if (
            grpField.formControl &&
            (grpField.formControl instanceof FormGroup ||
              grpField.formControl instanceof FormArray) &&
            !grpField.hide
          ) {
            grpField.fieldGroup.forEach(subField => {
              if (
                subField.formControl &&
                (subField.formControl instanceof FormGroup ||
                  subField.formControl instanceof FormArray) &&
                !subField.hide
              ) {
                subField.fieldGroup.forEach(subGroupField => {
                  if (
                    subGroupField.formControl &&
                    (subGroupField.formControl instanceof FormGroup ||
                      subGroupField.formControl instanceof FormArray) &&
                    !subGroupField.hide
                  ) {
                    subGroupField.fieldGroup.forEach(nestedLoopingField => {
                      if (
                        nestedLoopingField.formControl &&
                        nestedLoopingField.formControl.errors &&
                        !nestedLoopingField.hide
                      ) {
                        Object.keys(nestedLoopingField.formControl.errors).forEach(z => {if (this.isNaN(+z))errors.push(z)});
                      }
                    });
                  } else if (
                    subField.formControl &&
                    subField.formControl.errors &&
                    !subField.hide
                  ) {
                    Object.keys(subField.formControl.errors).forEach(z => {if (this.isNaN(+z))errors.push(z)});
                  }
                });
              } else if (
                subField.formControl &&
                subField.formControl.errors &&
                !subField.hide
              ) {
                Object.keys(subField.formControl.errors).forEach(z => {if (this.isNaN(+z))errors.push(z)});
              }
            });
          }
        });
      }
    });
// 
// TODO: Figure out why were getting errors {0: true}
// 
    this.formErrors = [...this.formErrors, ...new Set(errors)];

    if (this.formErrors && this.formErrors.length > 0) {
      const errList = [...new Set(this.formErrors)];
      const errListFormatted = [];

      errList.forEach(x => {
        const err = {};
        err[x] = true;
        errListFormatted.push(err);
      });
      
      this.form.setErrors(errListFormatted);
    }

    this.checkCustomError();
  }

  validateFields(fg, group?) {
    if (typeof fg === 'object' && !(fg instanceof FormGroup) && !(fg instanceof FormArray)) {
      Object.keys(fg).forEach(key => {
        const control = fg[key].formControl ? fg[key].formControl : fg[key];
        if (control instanceof FormGroup ||
          control instanceof FormArray) {
          control.markAsTouched();
          control.updateValueAndValidity();
          this.validateFields(control, true);
        } else if (!(control instanceof FormControl) && !(control instanceof FormGroup) && !(control instanceof FormArray) && control.type === 'custom-rankorder') {
          control.formControl.markAsTouched();
        } else {
          if (control instanceof FormControl) {
            control.markAsTouched();
            control.updateValueAndValidity();
          } else if (control.formControl) {
            control.formControl.markAsTouched();
            control.formControl.updateValueAndValidity();
            this.validateFields(control, true);
          }
        }
      });
    } else if (Array.isArray(fg) && !group) {
      fg.forEach(field => {
        const control = field;
        if (control instanceof FormArray) {
          control.markAsTouched();
          control.updateValueAndValidity();
        } else if (
          control instanceof FormGroup ||
          control instanceof FormArray
        ) {
          control.markAsTouched();
          control.updateValueAndValidity();
          this.validateFields(control, true);
        } else if (!(control instanceof FormControl) && !(control instanceof FormGroup) && !(control instanceof FormArray) && control.type === 'custom-rankorder') {
          control.formControl.markAsTouched();
        } else {
          if (control instanceof FormControl) {
            control.markAsTouched();
            control.updateValueAndValidity();
          } else if (control.formControl) {
            control.formControl.markAsTouched();
            control.formControl.updateValueAndValidity();
            this.validateFields(control, true);
          }
        }
      });
    } else if (group && !(fg instanceof FormControl)) {
      Object.keys(fg.controls).forEach(field => {
        const control = fg.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched();
          control.updateValueAndValidity();
          fg.markAsTouched();
          fg.updateValueAndValidity();
        } else if (
          control instanceof FormGroup ||
          control instanceof FormArray
        ) {
          control.markAsTouched();
          control.updateValueAndValidity();
          this.validateFields(control, true);
        }
      });
    } else if (fg instanceof FormGroup || fg instanceof FormArray) {
      fg.markAsTouched();
      fg.updateValueAndValidity();
      this.validateFields(fg, true);
    } else {
      fg.markAsTouched();
      fg.updateValueAndValidity();
    }
    // this.getErrors(this.field.fieldGroup);

    this.form.markAsTouched();
    this.form.updateValueAndValidity();

// console.log('@@@@@@', this.fields[0], this.form)

    setTimeout(() => {
      this.getErrors(fg);
      this.modelUpdate();
    }, 500);
  }

  saveInProgress() {
    this.saveForm(this.model, true);
  }

  saveForm(data, inProgressSave = false, validate = true): void {

    let id;
    this.route.params.subscribe(params => {
      id = params.id;
    });

    if (this.noSaveProfile && this.formContext?.type != 'unitProfile') {
      const noSaveData = this.ss.get('noSave');
      noSaveData.model = this.model;
      noSaveData.questionnaire = this.data;
      this.ss.set('noSave', noSaveData);
    }

    if (
      id &&
      typeof id != 'undefined' &&
      (!this.profile ||
        (this.profile && this.profile.length < 1) ||
        this.profile != id)
    ) {
      this.profile = id;
      console.warn('ID mismatch');
    }

    let timeoutLength = 0;

    if (this.context && this.context.type == 'unified') {
      this.unfFlowSvc.setSaving(true);
      this.submitted = true;
      // if (validate) {
        // this.validateFields(this.form);
      // }
    }

    // if (!inProgressSave) {
    //   this.validateFields(this.form);
    // }

    if (
      !this.form.valid &&
      !this.form.disabled &&
      !inProgressSave &&
      this.context &&
      this.context.type != 'unified'
    ) {
      this.submitted = true;
    } else {
      const yesno = { list: [], data: [] };
      const hash = [];
      this.questions.forEach(y => {
        if (y.controlType.toLowerCase() === 'validatedcheck') {
          yesno.list.push(y.varName);
          yesno.data.push({ varName: y.varName, values: y.options });
        }

        if (y.controlType.toLowerCase() === 'hash') {
          hash.push(y.varName);
        }

        if (y.controlType === 'dataGrid') {
          delete data[y.varName];
        }
      });

      this.loading =
        this.context && this.context.type && this.context.type == 'unified'
          ? false
          : true;

      let nextStep = '';
      const percRegex = new RegExp(/^(?!\D\,)(\d*\.*?\d*?%).*/gim),
        moneyRegex = new RegExp(/^\$/),
        percCommaRegex = new RegExp(/(%,\s)|(%,)/);

      const cloned = cloneDeep(data);
      const flattened = this.flatten(cloned);
      this.renamed = {};
      if (this.hashStatus.length != this.hashQuestion.length) {
        hash.forEach((x, i) => {
          const question = this.questions.find(z => z.varName == hash[i]);
          if (
            flattened[`${x.replace('.', '_')}`] &&
            flattened[`${x.replace('.', '_')}`].length > 0 &&
            flattened[`${x.replace('.', '_')}`].length < 15
          ) {
            const sbmt = flattened[`${x.replace('.', '_')}`].replace(/\D/g, '');
            this.getHash(sbmt, question.hashtype).then(hsh => {
              flattened[`${x.replace('.', '_')}`] = hsh['result'];
            });
          }
        });

        timeoutLength = 2000;
      }

      if (this.vars) {
        this.vars.forEach(e => {
          if (
            e.varName != 'CurrentAnnuityDetailsArray.HistoricalLookup' &&
            e.varName != 'CurrentAnnuityDetailsArray.HasWithdrawalBenefit' &&
            e.varName != 'CurrentAnnuityDetailsArray.HasDeathBenefit' &&
            e.varName != 'ClientProfilingOut.CIStatus' &&
            e.varName != 'PurposeRankOrder.OrderedSelection'
          ) {
            delete flattened[e.varName.replace('.', '_')];
          }
        });
      }



      setTimeout(() => {
        Object.keys(flattened).forEach(z => {

          let fieldDef;

          if (this.getField(z, this.fields)) {
            fieldDef = this.getField(z, this.fields);
          } else if (this.fields[0].get(z)) {
            fieldDef = this.fields[0].get(z);
          }

          const question = this.questions.find(
            quest => quest.varName === z.replace('_', '.')
          );
          const exempt = question
            ? question.controlType === 'multiselect'
            : false;
          const textArray = question
            ? question.controlType.toLowerCase() === 'textarray'
            : false;
          const integerArray = question
            ? question.controlType.toLowerCase() === 'integerarray'
            : false;
          const rankedOrderArray = question
            ? question.controlType.toLowerCase() === 'rankorder'
            : false;
          if (
            (flattened[z] &&
              Object.keys(flattened[z]).length === 0 &&
              flattened[z].constructor === Object) ||
            (flattened[z] &&
              flattened[z].length === 0 &&
              Array.isArray(flattened[z]))
          ) {
            const repeating = this.groups.find(x => z === x.id);
            if (!repeating || !repeating.looping) {
              if (!exempt && !rankedOrderArray) {
                delete flattened[z];
              } else {
                this.renamed[z.replace('_', '.')] = null;
              }
            } else {
              const lpQstn = this.questions.filter(x => x.group === z);
              const varset =
                repeating.varset || lpQstn[0]['varName'].split('.')[0];
              // let emptyObj = {};
              // lpQstn.forEach(el => {
              //   emptyObj[el.varName.split('.')[1]] = null;
              // });
              if (!this.renamed[varset]) {
                this.renamed[varset] = [];
              }
              //this.renamed[varset].push(emptyObj);
            }
          } else if (checkPercentVar(z, this.questions)) {
            if (
              z.includes('CDSCSchedule') &&
              flattened[z] &&
              typeof flattened[z] === 'string' &&
              (flattened[z].includes('%,') || flattened[z].includes('%, '))
            ) {
              this.renamed[z.replace('_', '.')] = this.removePercentMask(
                flattened[z]
              );
            } else if (
              z.includes('CDSCSchedule') &&
              flattened[z] &&
              typeof flattened[z] === 'string' &&
              !(flattened[z].includes('%,') && !flattened[z].includes('%, '))
            ) {
              this.renamed[z.replace('_', '.')] = flattened[z];
            } else {
              this.renamed[z.replace('_', '.')] = flattened[z]
                ? +this.removePercentMask(flattened[z])
                : this.cleanHtml(flattened[z]);
            }
          } else if (
            fieldDef &&
            (fieldDef.type === 'custom-currency' ||
              fieldDef.type === 'custom-currency-exp') &&
            moneyRegex.test(flattened[z])
          ) {
            this.renamed[z.replace('_', '.')] = this.removeMoneyMask(
              flattened[z]
            );
          } else if (
            flattened[z] &&
            flattened[z].constructor === Array &&
            Object.prototype.toString.call(flattened[z][0]) ===
              '[object Object]' &&
            !rankedOrderArray
          ) {
            if (z == 'fundDetailsTSP') {
              flattened[z] = flattened[z].filter(el => {
                return (
                  el.TSPFundDetails_Amount &&
                  (el.TSPFundDetails_Amount.length > 0 ||
                    (el.TSPFundDetails_Amount &&
                      el.TSPFundDetails_Amount > 0)) &&
                  el.TSPFundDetails_FundID &&
                  el.TSPFundDetails_FundID.length > 0
                );
              });
              this.processGroup(flattened[z], z, yesno);
            } else {
              this.processGroup(flattened[z], z, yesno);
            }
          } else if (rankedOrderArray) {
            const newArray = [];
            flattened[z].map(obj => {
              if (obj) {
                newArray.push(obj.value);
              }
            });
            this.renamed[z.replace('_', '.')] = newArray;
          } else if (textArray || integerArray) {
            let value = this.cleanHtml(flattened[z]);
            if (value && !Array.isArray(value)) {
              if (value.includes(',')) {
                value = value.split(',');
              } else if (value.includes('|')) {
                value = value.split('|');
              }
            }
            if (integerArray) {
              if (value) {
                value = value.map(el => {
                  return +el;
                });
              }
              this.renamed[z.replace('_', '.')] = this.cleanHtml(value);
            } else {
              this.renamed[z.replace('_', '.')] = this.cleanHtml(value);
            }
          } else {
            if (z == 'Client_Rep_RepCode' && flattened[z]) {
              this.renamed['Client_Rep.RepCode'] = this.cleanHtml(flattened[z]);
            } else if (
              z === 'RetirementSavings_AnnualContributionPercent' ||
              z === 'SpouseRetirementSavings_AnnualContributionPercent' ||
              z === 'RetirementIncomeAssumptions_WageInflationRate'
            ) {
              this.renamed[z.replace('_', '.')] = flattened[z] / 100;
            } else {
              this.renamed[z.replace('_', '.')] = this.cleanHtml(flattened[z]);
            }
            if (yesno.list.includes(z.replace('_', '.'))) {
              const values = yesno.data.find(
                y => y.varName === z.replace('_', '.')
              );
              let val;
              if (flattened[z]) {
                val = values.values.find(x => {
                  const valid =
                    (typeof x.value === 'boolean' && x.value) ||
                    (+x.value !== 0 && x.value.toLowerCase() !== 'n');
                  return valid;
                });
              } else {
                val = values.values.find(x => {
                  const valid =
                    (typeof x.value === 'boolean' && x.value) ||
                    +x.value === 0 ||
                    x.value.toLowerCase() === 'n';
                  return valid;
                });
              }
              this.renamed[z.replace('_', '.')] = this.cleanHtml(val.value);
            }
          }
        });
        if (
          typeof this.model['regSkipped'] === 'boolean' &&
          this.model['regSkipped'] === false
        ) {
          if (this.app == 'aw') {
            this.renamed['RequiredQuestions.AWSkipped'] = false;
          } else {
            this.renamed['RequiredQuestions.Skipped'] = false;
          }
        } else if (this.model['regSkipped']) {
          if (this.app == 'aw') {
            this.renamed['RequiredQuestions.AWSkipped'] = true;
          } else {
            this.renamed['RequiredQuestions.Skipped'] = true;
          }
        }

        const payload = JSON.stringify(this.renamed);

        if (this.noSaveProfile && this.formContext?.type != 'unitProfile') {
          const profilePayload = this.ss.get('noSave');
          profilePayload.profile = this.renamed;
          this.ss.set('noSave', profilePayload);
          this.loading = false;
          this.unfFlowSvc.setSaving(false);
        } else if (
          this.profile &&
          this.renamed.hasOwnProperty('Client_Rep.RepCode') &&
          (this.renamed['Client_Rep.RepCode'] == '' ||
            this.renamed['Client_Rep.RepCode'] == 'no_rep_codes' ||
            !this.renamed['Client_Rep.RepCode'])
        ) {
          nextStep = this.navOption();
          if (this.renamed['AnnuityWizard.PurchaseType'] == '3') {
            this.router.navigate([nextStep, { exc: true }]);
          } else {
            this.router.navigate([nextStep]);
          }
          this.loading = false;
        } else {
          if (this.formContext && this.formContext.type === 'unitProfile') {
            const dialogRef = this.dialog.open(SaveUnitProfileDialogComponent);
            dialogRef.afterClosed().subscribe(resp => {
              if (resp?.status == 'continue') {
                this.frmSrvc
                  .saveUnitProfile(
                    this.formContext.unit,
                    payload,
                    this.formContext.app,
                    resp.note
                  )
                  .subscribe(() => {
                    this.snacky.open('Unit Profile has been saved', 'Close', {
                      duration: 2000,
                    });
                    this.loading = false;
                  });
              } else {
                this.loading = false;
              }
            });
          } else if (this.formContext && this.formContext.type === 'unified') {
            if (
              id &&
              typeof id != 'undefined' &&
              (!this.profile ||
                (this.profile && this.profile.length < 1) ||
                this.profile != id)
            ) {
              this.profile = id;
              console.warn('ID mismatch');
            }

            const keys = Object.keys(JSON.parse(payload));
            if (keys.length > 5) {
              this.rbs
                .saveProfile(this.profile, payload, true, false, this.app)
                .subscribe(() => {
                  this.loading = false;
                  setTimeout(() => {
                    this.unfFlowSvc.setSaving(false);
                    // this.modStatusSvc.getApps(this.profile);
                  }, 250);
                });
            }
          } else {
            this.rbs.newProfile(payload, true).subscribe(data => {
              this.profile = data.results;
              nextStep = this.navOption();

              if (
                this.context &&
                this.context.type &&
                this.context.type == 'unified'
              ) {
                this.unfFlowSvc.setNewProfile(this.profile);
                setTimeout(() => {
                  this.unfFlowSvc.setSaving(false);
                }, 250);
              } else if (inProgressSave) {
                this.loading = false;
                this.location.replaceState(
                  '/' + this.app + '/questionnaire/' + this.profile
                );
                if (
                  !this.context ||
                  (this.context && this.context.type != 'unified')
                ) {
                  this.snacky.open(
                    'In progress profile data has been saved.',
                    'Close',
                    {
                      duration: 4000,
                    }
                  );
                }
              } else {
                if (this.renamed['AnnuityWizard.PurchaseType'] == '3') {
                  this.router.navigate([nextStep, { exc: true }]);
                } else {
                  this.router.navigate([nextStep]);
                }
                this.loading = false;
                this.location.replaceState(
                  '/' + this.app + '/questionnaire/' + this.profile
                );
              }
            });
          }
        }
      }, timeoutLength);
    }

    // }
  }

  processGroup(group, z, yesno) {
    const percRegex = new RegExp(/^(?!\D\,)(\d*\.*?\d*?%).*/gim),
      moneyRegex = new RegExp(/^\$/),
      letterRegex = new RegExp(/[a-zA-Z]/),
      percCommaRegex = new RegExp(/(%,\s)|(%,)/);
    group.forEach(e => {
      const obj = {};
      let varset;

      Object.keys(e).forEach(y => {
        const notJunkVar = y.split('_')[1];
        const varArray = y.split('_');
        let varname;
        varArray.shift();

        if (e[y] && Array.isArray(e[y])) {
          this.processGroup(e[y], y, yesno);
        }

        if (varArray.length > 1) {
          varname = varArray.join('_');
        } else {
          varname = varArray[0];
        }

        if (notJunkVar) {
          varset = y.split('_')[0];
        }

        if (!this.renamed.hasOwnProperty(varset)) {
          this.renamed[varset] = [];
        }

        if (checkPercentVar(y, this.questions)) {
          if (
            y.includes('CDSCSchedule') &&
            e[y] &&
            typeof e[y] === 'string' &&
            (e[y].includes('%,') || e[y].includes('%, '))
          ) {
            obj[varname] = this.removePercentMask(e[y]);
          } else if (
            y.includes('CDSCSchedule') &&
            e[y] &&
            typeof e[y] === 'string' &&
            !(e[y].includes('%,') && !e[y].includes('%, '))
          ) {
            obj[varname] = e[y] ? e[y] : null;
          } else if (letterRegex.test(e[y]) || percCommaRegex.test(e[y])) {
            obj[varname] = e[y] ? e[y] : null;
          } else {
            let value = null;
            if (e[y]) {
              value = +this.removePercentMask(e[y]);
            }

            obj[varname] = value;
          }
        } else if (moneyRegex.test(e[y])) {
          obj[varname] = this.removeMoneyMask(e[y]);
        } else if (y == 'CurrentPlanDetailsArray_CompanyStockPercent') {
          obj[varname] = e[y] ? e[y] : null;
        } else if (
          y == 'CurrentPlanDetailsArray_F5500AdminFee' ||
          y == 'CurrentPlanDetailsArray_F5500FundFee' ||
          y == 'CurrentNonERISADetailsArray_TSPAdminFee' ||
          y == 'CurrentNonERISADetailsArray_NonERISAManualExpenseRatio' ||
          y == 'SelectedFunds_Percent'
        ) {
          obj[varname] = e[y] / 100;
        } else if (
          !Array.isArray(e[y]) &&
          typeof e[y] == 'object' &&
          e[y] &&
          e[y].getDate()
        ) {
          const date = new Date(e[y]);
          obj[varname] = date
            .toLocaleDateString('en-US')
            .replace(/[^ -~]/g, '');
        } else if (varname) {
          const value =
            (!e[y] && typeof e[y] != 'boolean') || e[y] === undefined
              ? null
              : e[y];
          obj[varname] = value;
        }

        if (yesno.list.includes(y.replace('_', '.'))) {
          const values = yesno.data.find(
            z => z.varName === y.replace('_', '.')
          );
          let val;
          if (e[y]) {
            val = values.values.find(x => {
              const valid =
                (typeof x.value === 'boolean' && x.value) ||
                (+x.value !== 0 && x.value.toLowerCase() !== 'n');
              return valid;
            });
          } else {
            val = values.values.find(x => {
              const valid =
                (typeof x.value === 'boolean' && x.value) ||
                +x.value === 0 ||
                x.value.toLowerCase() === 'n';
              return valid;
            });
          }
          obj[varname] = val.value;
        }
      });

      if (varset) {
        const lpQstn = this.questions.filter(x => x.group === z);
        lpQstn.forEach(el => {
          const varName = el.varName.split('.')[1];
          const exists = obj.hasOwnProperty(varName);
          if (!exists) {
            obj[varName] = null;
          }
        });

        const stringObj = JSON.stringify(obj);
        const existingEntry = this.renamed[varset].some(
          entry => JSON.stringify(entry) === stringObj
        );

        if (!existingEntry) {
          this.renamed[varset].push(obj);
        }
      }
    });
  }

  async getHash(val, type) {
    return await this.frmSrvc.hashInput(val, type).toPromise();
  }

  logBeacon() {
    this.beaconLog.forEach(el => {
      this.beaconSrc
        .rbBeaconCounter(this.profile, el['prodId'], el['name'], el['carrier'])
        .subscribe(() => {
          console.warn('Beacon Usage Logged');
        });
    });
  }

  navOption() {
    switch (this.app) {
      case 'iw':
        if (
          this.model['ValidateProduct'].InvestmentWizard_AssessmentType === 2
        ) {
          return '/iw/validate/' + this.profile;
        } else {
          return '/iw/program-selection/' + this.profile;
        }
      case 'aw':
        return '/aw/results/' + this.profile;
      case 'pp':
        return '/pp/results/' + this.profile;
      case 'rw':
        return '/rw/results/' + this.profile;
      case 'cp':
        return '/cp/results/' + this.profile;
      case 'li':
        const gotoAnalysis = this.model['UnitNeeds_PurposesUsingCalc'].includes(
          this.model['LifeWizard_InsurancePurpose']
        );
        const gotoStrategies = !this.model[
          'UnitNeeds_PurposesUsingCalc'
        ].includes(this.model['LifeWizard_InsurancePurpose']);
        if (
          (this.model['LifeWizard_AssessmentType'] == 2 &&
            this.model['UnitRegulationState_States'].includes(
              this.model['LifeWizard_StateOfIssue']
            )) ||
          this.model['LifeWizard_PurchaseType'] == 2
        ) {
          return '/li/validate/' + this.profile;
        } else if (gotoStrategies) {
          return '/li/compare/' + this.profile;
        } else if (gotoAnalysis) {
          return '/li/results/' + this.profile;
        } else {
          return '/li/results/' + this.profile;
        }
    }
  }

  //Begin reg/validate functions
  checkRegInit() {
    if (
      this.model['LifeWizard_PurchaseType'] &&
      this.model['LifeWizard_PurchaseType'] == 2
    ) {
      this.initRegProcess(false);
    }
  }

  initRegProcess(clear = true) {
    this.app = this.ss.get('currentApp');

    
    if (!clear && this.app == 'aw') {
      this.getCarriers(false);
      this.getRegAnnuities(
        this.model['ValidateAnnuity'].AnnuityWizard_CarrierGroup,
        false
      );
    } else if (!clear && this.app == 'li') {
      this.getCarriers(false);
      this.getRegProducts(
        this.model['LIRecommendation']
          .LIPolicyValidation_CarrierGroupToValidate,
        false
      );
    } else if (clear && this.app == 'li') {
      this.clearRegData();
    } else {
      this.clearRegData();
    }
  }

  purposeChange() {
    this.initRegProcess();
  }

  clearRegData() {
    if (this.app == 'aw') {
      this.clearField(
        [
          { name: 'AnnuityWizard_CusipToValidate', group: 'ValidateAnnuity' },
          { name: 'AnnuityWizard_RiderToValidate', group: 'ValidateAnnuity' },
          {
            name: 'AnnuityWizard_MultipleTermValidate',
            group: 'ValidateAnnuity',
          },
          { name: 'AnnuityWizard_TermToValidate', group: 'ValidateAnnuity' },
        ],
        true
      );

      Object.keys(this.model['ValidateAnnuity']).forEach(x => {
        if (x !== 'AnnuityWizard_AssessmentType') {
          this.model['ValidateAnnuity'][x] = null;
        }
      });
    } else if (this.app == 'li') {
      this.clearField(
        [
          {
            name: 'LIPolicyValidation_CarrierGroupToValidate',
            group: 'LIRecommendation',
          },
          {
            name: 'LIPolicyValidation_LifeItemToValidate',
            group: 'LIRecommendation',
          },
          {
            name: 'LIPolicyValidation_RiderToValidate',
            group: 'LIRecommendation',
          },
        ],
        true
      );
      Object.keys(this.model['LIRecommendation']);
    }
    this.getCarriers();
  }

  clearAnnField() {
    const annField = this.getField(
      'AnnuityWizard_CusipToValidate',
      this.fields,
      'ValidateAnnuity'
    );
    annField.props.disabled = true;
    annField.props.options = null;
    annField.formControl.setValue(null);
  }

  enableAnnField() {
    const annField = this.getField(
      'AnnuityWizard_CusipToValidate',
      this.fields,
      'ValidateAnnuity'
    );
    annField.props.disabled = false;
    annField.props.options = null;
  }

  enableRiderField() {
    const riderField = this.getField(
      'AnnuityWizard_RiderToValidate',
      this.fields,
      'ValidateAnnuity'
    );
    riderField.props.disabled = false;
    riderField.props.options = null;
  }

  clearRiders() {
    const riderField = this.getField(
      'AnnuityWizard_RiderToValidate',
      this.fields,
      'ValidateAnnuity'
    );
    riderField.props.disabled = true;
    riderField.props.options = null;
    riderField.formControl.setValue(null);
  }

  clearField(fields, clear = false, disable = true) {
    fields.forEach(el => {
      const field = this.getField(el.name, this.fields, el.group);
      field.props.disabled = disable;
      field.props.options = null;
      if (clear) {
        this.model[el.group][el.name] = null;
        if (field && field.formControl) {
          field.formControl.setValue(null);
          field.formControl.markAsUntouched();
        }
      }
    });
  }

  enableField(fields, clear = false) {
    fields.forEach(el => {
      const field = this.getField(el.name, this.fields, el.group);
      field.formControl.enable();
      if (clear) {
        this.model[el.group][el.name] = null;
        if (field && field.formControl) {
          field.formControl.setValue(null);
          field.formControl.markAsUntouched();
        }
      }
    });
  }

  getCarriers(clear = true) {
    if (this.app == 'aw') {
      const field = this.getField(
        'AnnuityWizard_CarrierGroup',
        this.fields,
        'ValidateAnnuity'
      );

      if (clear) {
        this.model['ValidateAnnuity']['AnnuityWizard_CarrierGroup'] = null;
        field.formControl.setValue(null);
        field.formControl.setErrors(null);
        this.clearAnnField();
        this.clearRiders();
      }

      let carriers = [];
      this.rbs.getCarrierGroups(true).subscribe(x => {
        carriers = x['CarrierGroups'].map(z => {
          return { value: z.GroupID, label: z.GroupName };
        });
        carriers.sort(this.alphabetizeGroups);
        if (carriers.length === 1) {
          field.formControl.setValue(carriers[0].value);
          this.getRegAnnuities(carriers[0].value, false);
        }
        field.props.options = carriers;
      });
    } else if (this.app == 'li') {
      const field = this.getField(
        'LIPolicyValidation_CarrierGroupToValidate',
        this.fields,
        'LIRecommendation'
      );

      this.clearField([
        {
          name: 'LIPolicyValidation_CarrierGroupToValidate',
          group: 'LIRecommendation',
        },
        {
          name: 'LIPolicyValidation_LifeItemToValidate',
          group: 'LIRecommendation',
        },
        {
          name: 'LIPolicyValidation_RiderToValidate',
          group: 'LIRecommendation',
        },
      ]);

      let carriers = [];
      this.lws.getValidateCarriers().subscribe(x => {
        carriers = x['CarrierGroups'].map(z => {
          return { value: z.GroupID, label: z.GroupName };
        });

        carriers.sort(this.alphabetizeGroups);
        field.props.options = carriers;

        if (carriers.length === 1) {
          field.formControl.setValue(carriers[0].value);
          this.getRegProducts(carriers[0].value);
        }

        if (
          this.model['LIRecommendation']
            .LIPolicyValidation_CarrierGroupToValidate
        ) {
          this.getRegProducts(
            this.model['LIRecommendation']
              .LIPolicyValidation_CarrierGroupToValidate
          );
        }

        this.enableField([
          {
            name: 'LIPolicyValidation_CarrierGroupToValidate',
            group: 'LIRecommendation',
          },
        ]);
      });
    }
  }

  getRegAnnuities(id, clear = true) {
    if (clear) {
      this.clearAnnField();
      this.clearRiders();
      const multiTerm = this.getField('AnnuityWizard_MultipleTermValidate');
      multiTerm.formControl.setValue('N');
      const multiControl = this.getField('AnnuityWizard_TermToValidate');
      multiControl.formControl.setValue(null);
      multiControl.props.options = [];
    }
    let annOptions = [];
    const annField = this.getField(
      'AnnuityWizard_CusipToValidate',
      this.fields,
      'ValidateAnnuity'
    );
    const state = this.model['AnnuityWizard_StateOfIssueAlt']
      ? this.model['AnnuityWizard_StateOfIssueAlt']
      : this.model['AnnuityWizard_StateOfIssue'];
    this.rbs
      .getRegulationAnnuities(
        id,
        state,
        this.model['AnnuityWizard_Purpose'],
        this.model['AnnuityWizard_AccountPref'] || ''
      )
      .subscribe(x => {
        this.regAnnuities = x['Annuities'];
        annOptions = x['Annuities'].map(z => {
          return { value: z.Cusip, label: z.Name };
        });

        const noCusipField = this.getField(
          'AnnuityWizard_NoCusips',
          this.fields
        );

        if (this.regAnnuities.length > 0) {
          this.enableAnnField();
          annField.props.options = annOptions.sort(this.alphabetizeGroups);
          noCusipField.formControl.setValue(false);
        } else {
          noCusipField.formControl.setValue(true);
        }

        if (!clear) {
          this.getRegRiders(
            this.model['ValidateAnnuity'].AnnuityWizard_CusipToValidate
          );
        }
      });
  }

  getRegProducts(id, clear = true) {
    this.clearField([
      {
        name: 'LIPolicyValidation_LifeItemToValidate',
        group: 'LIRecommendation',
      },
      { name: 'LIPolicyValidation_RiderToValidate', group: 'LIRecommendation' },
    ]);
    this.model['LIPolicyValidation_PremiumType'] = null;
    this.model['LIPolicyValidation_ProductType'] = null;
    const prodId = id
      ? id
      : this.model['LIRecommendation']
          .LIPolicyValidation_CarrierGroupToValidate;
    let prodOptions = [];
    const field = this.getField(
      'LIPolicyValidation_LifeItemToValidate',
      this.fields,
      'LIRecommendation'
    );
    const state = this.model['LifeWizard_StateOfIssueAlt']
      ? this.model['LifeWizard_StateOfIssueAlt']
      : this.model['LifeWizard_StateOfIssue'];
    this.lws.getValidateProducts(prodId, state).subscribe(x => {
      this.regProducts = x['LifeItems'];
      prodOptions = x['LifeItems'].map(z => {
        return { value: z.LifeItemID, label: z.Contract };
      });
      if (this.regProducts.length > 0) {
        field.props.options = prodOptions.sort(this.alphabetizeGroups);
        this.enableField([
          {
            name: 'LIPolicyValidation_LifeItemToValidate',
            group: 'LIRecommendation',
          },
        ]);

        this.model['LifeWizard_NoProducts'] = false;
        if (!clear) {
          this.getRegRiders(
            this.model['LIRecommendation'].LIPolicyValidation_LifeItemToValidate
          );
        }
      } else {
        this.model['LifeWizard_NoProducts'] = true;
      }
    });
  }

  getRegRiders(product, clear = false) {
    if (this.app == 'aw') {
      const multiTerm = this.getField('AnnuityWizard_MultipleTermValidate');
      multiTerm.formControl.setValue('N');

      const multiControl = this.getField('AnnuityWizard_TermToValidate');

      let savedTerm;

      if (multiControl) {
        savedTerm = multiControl.formControl.value
          ? multiControl.formControl.value
          : null;
        multiControl.formControl.setValue(null);
        multiControl.props.options = [];
      }
      const selectedAnnuity = this.regAnnuities.find(z => z.Cusip === product);

      if (selectedAnnuity.MultipleTerm === 'Y') {
        multiTerm.formControl.setValue('Y');
        this.model['multiTermOptions'] = selectedAnnuity.Terms.map(x => {
          return { value: x, label: `${x}-Year Term` };
        });

        multiControl.props.options = [...this.model['multiTermOptions']];
        multiControl.formControl.enable();
        multiControl.formControl.setValue(savedTerm);
      }

      if (
        selectedAnnuity &&
        (selectedAnnuity.ProductType.toLowerCase() === 'fixed' ||
          selectedAnnuity.ProductType.toLowerCase() === 'spia' ||
          selectedAnnuity.ProductType.toLowerCase() === 'dia' ||
          (selectedAnnuity.LBRiders.length < 1 &&
            selectedAnnuity.DBRiders.length < 1 &&
            selectedAnnuity.AccRiders.length < 1))
      ) {
        this.model['AnnuityWizard_NoRiders'] = true;
      } else {
        this.model['AnnuityWizard_NoRiders'] = false;
        const lb = selectedAnnuity.LBRiders;
        const acc = selectedAnnuity.AccRiders;
        const db = selectedAnnuity.DBRiders;
        const riderField = this.getField(
          'AnnuityWizard_RiderToValidate',
          this.fields,
          'ValidateAnnuity'
        );
        let riders = [];
        if (lb && lb.length > 0) {
          riders = lb.map(z => {
            return { value: z.FeatureKey, label: z.Name };
          });
        } else if (acc && acc.length > 0) {
          riders = acc.map(z => {
            return { value: z.FeatureKey, label: z.Name };
          });
        } else if (db && db.length > 0) {
          riders = db.map(z => {
            return { value: z.FeatureKey, label: z.Name };
          });
        }

        riderField.props.options = riders;
        riderField.props.disabled = false;
      }
      const annTypeField = this.getField('AnnuityWizard_ProductType');
      annTypeField.formControl.setValue(selectedAnnuity.ProductType);
    } else if (this.app == 'li') {
      this.clearField(
        [
          {
            name: 'LIPolicyValidation_RiderToValidate',
            group: 'LIRecommendation',
          },
        ],
        clear
      );
      const selectedProduct = this.regProducts.find(z => {
        return z.LifeItemID == product;
      });

      if (selectedProduct) {
        this.model['LIPolicyValidation_PremiumType'] =
          selectedProduct.PremiumType;
        this.model['LIPolicyValidation_ProductType'] =
          selectedProduct.ProductType;
      }

      if (selectedProduct && selectedProduct.Riders.length > 0) {
        const field = this.getField(
          'LIPolicyValidation_RiderToValidate',
          this.fields,
          'LIRecommendation'
        );
        field.props.options = selectedProduct.Riders.map(z => {
          return { value: z.RiderID, label: z.Name };
        });
        this.enableField([
          {
            name: 'LIPolicyValidation_RiderToValidate',
            group: 'LIRecommendation',
          },
        ]);
        if (clear) {
          field.formControl.setValue([]);
        }

        this.model['LifeWizard_NoRiders'] = false;
      } else {
        this.model['LifeWizard_NoRiders'] = true;
        this.clearField(
          [
            {
              name: 'LIPolicyValidation_RiderToValidate',
              group: 'LIRecommendation',
            },
          ],
          true
        );
      }
    }
  }

  //End reg/validate functions
  fourOhFourChange(ctrl, field) {
    const idx = field.parent.key;

    const varSet = field.key.split('_')[0];

    if (ctrl && ctrl.parent) {
      if (
        ctrl.parent.controls[`${varSet}_F5500AdminFee`] &&
        this.rolloverData[idx]
      ) {
        ctrl.parent.controls[`${varSet}_F5500AdminFee`].setValue(
          `${this.rolloverData[idx]['AdminExpRate']}%`
        );
      }

      if (
        ctrl.parent.controls[`${varSet}_F5500FundFee`] &&
        this.rolloverData[idx]
      ) {
        ctrl.parent.controls[`${varSet}_F5500FundFee`].setValue(
          `${this.rolloverData[idx]['FundFee']}%`
        );
      }

      if (
        ctrl.parent.controls[`${varSet}_ExternalFundFeesEstimate`] &&
        this.rolloverData[idx]
      ) {
        ctrl.parent.controls[`${varSet}_ExternalFundFeesEstimate`].setValue(
          `${this.rolloverData[idx]['AverageFundFee']}%`
        );
      }
    }
  }

  //End reg/validate functions
  fourOhFourChangeNP(ctrl) {
    if (ctrl && ctrl.parent) {
      if (ctrl.parent.controls['NewPlanDetails_F5500AdminFee']) {
        ctrl.parent.controls['NewPlanDetails_F5500AdminFee'].setValue(
          `${this.newPlanData['AdminExpRate']}%`
        );
      }

      if (ctrl.parent.controls['NewPlanDetails_F5500FundFee']) {
        ctrl.parent.controls['NewPlanDetails_F5500FundFee'].setValue(
          `${this.newPlanData['FundFee']}%`
        );
      }
    }
  }

  mapPurposeValue(ctrl) {
    const purposeField = this.getField('AnnuityWizard_Purpose');
    switch (+ctrl.value) {
      case 1:
        this.model['AnnuityWizard_Purpose'] = 1;
        purposeField.formControl.setValue(1);
        break;
      case 2:
        this.model['AnnuityWizard_Purpose'] = 1;
        purposeField.formControl.setValue(1);
        break;
      case 3:
        this.model['AnnuityWizard_Purpose'] = 3;
        purposeField.formControl.setValue(3);
        break;
      case 5:
        this.model['AnnuityWizard_Purpose'] = 5;
        purposeField.formControl.setValue(5);
        break;
    }

    this.purposeChange();
  }

  clearIWFunds() {
    if (
      this.model['ValidateProduct'] &&
      this.model['ValidateProduct'].InvestmentValidation_Program &&
      ((this.model['SelectedFunds'] &&
        this.model['SelectedFunds'].length > 0) ||
        (this.model['Selected529PlansArray'] &&
          this.model['Selected529PlansArray'].length > 0) ||
        (this.model['Five29Plans'] &&
          this.model['Five29Plans'].length > 0 &&
          this.model['Five29Plans'][0]['Selected529PlansArray_PlanID']) ||
        (this.model['Five29FundsPortfolios'] &&
          this.model['Five29FundsPortfolios'].length > 0) ||
        (this.model['AlternativeInvestments'] &&
          this.model['AlternativeInvestments'].length > 0))
    ) {
      const dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            'If you choose to make this change all of your previous fund selections will be cleared.',
          confirm: 'OK',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'continue') {
          if (this.model['SelectedFunds']) {
            this.model['SelectedFunds'].length = 0;
          }

          if (this.model['Selected529PlansArray']) {
            this.model['Selected529PlansArray'].length = 0;
          }

          if (this.model['Five29FundsPortfolios']) {
            this.model['Five29FundsPortfolios'].length = 0;
          }

          if (this.model['Five29Plans']) {
            this.model['Five29Plans'].length = 0;
          }

          if (this.model['AlternativeInvestments']) {
            this.model['AlternativeInvestments'].length = 0;
          }
        }
        this.frmSrvc.clear529Picker();
      });
    } else {
      console.warn('No IW Funds to be Cleared');
    }
  }

  handleF5500(ev) {
    try {
      this.usedRixtrema = true;
      this.planData = ev;
      if (this.planData['buttonType'] == 'newPlanSearch') {
        this.handleNewPlan(ev);
        return;
      }

      const index = ev['groupIndex'];

      let group = 'rollover';
      let varSet = 'CurrentPlanDetailsArray';

      switch (this.planData.buttonType) {
        case 'search401k':
          varSet = 'CurrentPlanDetailsArray';
          group = 'rollover';
          break;
        case 'search403b':
          varSet = 'Current403BDetailsArray';
          group = 'fourOthreeB';
          break;
        case 'searchMP':
          varSet = 'CurrentMoneyPurchaseDetailsArray';
          group = 'moneyPurchase';
          break;
        case 'searchPension':
          varSet = 'CurrentPensionDetailsArray';
          group = 'pension';
          break;
      }

      const controlGroup = this.form.controls[group];
      const controls = controlGroup['controls'][index].controls;

      if (this.planData['PLAN_NAME'] !== undefined) {
        this.rolloverData[index] = this.planData;
        let annuityRlcData, rlcExpenses, rlcInservice, rlcMatch;
        this.safeSetValue(controls, `${varSet}_F5500PlanFound`, '1');
        setTimeout(() => {
          if (
            this.planData.Annuities ||
            typeof this.planData.Annuities === 'boolean'
          ) {
            annuityRlcData = this.planData.Annuities ? 'Yes' : 'No';
          } else {
            annuityRlcData =
              this.planData['InsuranceBenefitIndicator'] == '1' ||
              this.planData['Sec412BenefitIndicator'] == '1'
                ? 'Yes'
                : 'No';
          }

          if (
            this.planData['InService'] ||
            typeof this.planData['InService'] === 'boolean'
          ) {
            rlcInservice = this.planData['InService'] ? 'Y' : 'N';
          }

          if (
            this.planData['Match'] ||
            typeof this.planData['SafeHarborMatch'] === 'boolean' ||
            this.planData['SafeHarborMatch'] ||
            typeof this.planData['SafeHarborMatch'] === 'boolean'
          ) {
            rlcMatch =
              this.planData['Match'] || this.planData['SafeHarborMatch']
                ? 'Y'
                : 'N';
          }

          if (
            this.planData['Expenses'] ||
            (!this.planData['Expenses'] &&
              typeof this.planData['Expenses'] === 'boolean')
          ) {
            rlcExpenses = this.planData['Expenses'] ? 'Y' : 'N';
          }

          this.safeSetValue(
            controls,
            `${varSet}_F5500PlanName`,
            this.planData['PLAN_NAME']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500ACKID`,
            this.planData['ACK_ID']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500PlanType`,
            this.planData['PlanType']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500SubplanID`,
            this.planData['subplanID']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500FundFeeExactOrEstimate`,
            this.planData['FundFeeExactOrEstimate']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500SelfDirectedFeature`,
            this.planData['SelfDirectedFeature']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500LifeInsuranceFeature`,
            this.planData['LifeInsuranceFeature']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500FilingType`,
            this.planData['FilingType']
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500AdminFee`,
            this.planData['AdminExpRate'] + '%'
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500FundFee`,
            this.planData['FundFee'] + '%'
          );
          this.safeSetValue(
            controls,
            `${varSet}_F5500AnnuityFeature`,
            annuityRlcData
          );
          this.safeSetValue(
            controls,
            `${varSet}_RLCLoans`,
            this.planData['Loans']
          );
          this.safeSetValue(
            controls,
            `${varSet}_RLCCompanyStock`,
            this.planData['EmployerStock']
          );
          this.safeSetValue(controls, `${varSet}_SponsorSubsidy`, rlcExpenses);
          this.safeSetValue(
            controls,
            `${varSet}_InserviceDistributions`,
            rlcInservice
          );
          this.safeSetValue(controls, `${varSet}_CompanyMatch`, rlcMatch);
        }, 1000);
      }
    } catch (exception) {
      console.warn('Error loading Rixtrema data: ' + exception);
    }
  }

  handleNewPlan(ev) {
    this.model['newPlan']['NewPlanDetails_F5500PlanFound'] = 1; // This is needed to support the simple conditional they use for display. It's technically wrong though.
    this.form.controls.newPlan['controls'][
      'NewPlanDetails_F5500PlanFound'
    ].setValue('1');

    try {
      setTimeout(() => {
        this.usedRixtrema = true;
        this.newPlanData = ev;

        if (this.newPlanData['PLAN_NAME'] !== undefined) {
          setTimeout(() => {
            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500PlanName'
            ].setValue(this.newPlanData['PLAN_NAME']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500ACKID'
            ].setValue(this.newPlanData['ACK_ID']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500SubplanID'
            ].setValue(this.newPlanData['subplanID']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500FundFeeExactOrEstimate'
            ].setValue(this.newPlanData['FundFeeExactOrEstimate']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500SelfDirectedFeature'
            ].setValue(this.newPlanData['SelfDirectedFeature']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500LifeInsuranceFeature'
            ].setValue(this.newPlanData['LifeInsuranceFeature']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500FilingType'
            ].setValue(this.newPlanData['FilingType']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500AnnuityFeature'
            ].setValue(this.newPlanData['InsuranceBenefitIndicator']);

            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500AnnuityFeature'
            ].setValue(
              this.newPlanData['InsuranceBenefitIndicator'] == '1' ||
                this.newPlanData['Sec412BenefitIndicator'] == '1'
                ? 'Yes'
                : 'No'
            );

            if (
              this.form.controls.newPlan['controls'][
                'NewPlanDetails_F5500FundFee'
              ]
            ) {
              this.form.controls.newPlan['controls'][
                'NewPlanDetails_F5500FundFee'
              ].setValue(this.newPlanData['FundFee']);
            }

            if (
              this.form.controls.newPlan['controls'][
                'NewPlanDetails_F5500AdminFee'
              ]
            ) {
              this.form.controls.newPlan['controls'][
                'NewPlanDetails_F5500AdminFee'
              ].setValue(this.newPlanData['AdminExpRate']);
            }
          });
        } else {
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500PlanFound'
          ].setValue('0');
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500ACKID'
          ].reset();
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500SubplanID'
          ].reset();
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500FundFeeExactOrEstimate'
          ].reset();
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500SelfDirectedFeature'
          ].reset();
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500LifeInsuranceFeature'
          ].reset();
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500FilingType'
          ].reset();
          this.form.controls.newPlan['controls'][
            'NewPlanDetails_F5500AnnuityFeature'
          ].reset();

          if (
            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500AdminFee'
            ]
          ) {
            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500AdminFee'
            ].reset();
          }

          if (
            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500FundFee'
            ]
          ) {
            this.form.controls.newPlan['controls'][
              'NewPlanDetails_F5500FundFee'
            ].reset();
          }
        }
      });
    } catch (exception) {
      console.warn('Error loading Rixtrema data: ' + exception);
    }
  }

  safeSetValue(controls, field, value) {
    if (!value && typeof value != 'boolean') {
      controls[field].reset();
    } else if (controls[field] != undefined) {
      if (!this.isNumber(value) && value.includes('%')) {
        const percentVal = new Decimal(value.replace('%', '') * 1)
          .todp(3)
          .toNumber();
        controls[field].setValue(percentVal);
      } else if (this.isNumber(value)) {
        controls[field].setValue(value.toString());
      } else {
        controls[field].setValue(value);
      }
    } else {
      controls[field] = new FormControl();
      if (value.includes('%')) {
        const percentVal = new Decimal(value.replace('%', '') * 1)
          .todp(3)
          .toNumber();
        controls[field].setValue(percentVal);
      } else {
        controls[field].setValue(value);
      }
    }
  }

  safeSetValueSearch(varName, fields = this.fields, group, value) {
    const control = this.getField(varName, fields, group);

    if (control.formControl) {
      control.formControl.setValue(value.toString());
    } else {
      console.warn('Error setting field ' + varName + ' to value ' + value);
    }
  }

  safeClearValue(controls, field) {
    if (controls[field] != undefined) {
      controls[field].reset();
    }
  }

  safeClearValueSearch(varName, fields = this.fields, group) {
    const control = this.getField(varName, fields, group);

    if (control.formControl) {
      control.formControl.reset();
    }
  }

  handleBeacon(ev) {
    this.usedBeacon = true;
    this.beaconData = ev.data;
    const idx = ev.index;
    const exchangeFields = this.getGroup(ev.group);
    const annuityFields =
      ev.index || ev.index === 0
        ? exchangeFields.fieldGroup[idx].fieldGroup
        : exchangeFields.fieldGroup;
    annuityFields.forEach(el => {
      const varname = el['key'] as string;
      if (el['formControl'] && !varname.toLowerCase().includes('isexchange')) {
        el['formControl'].setValue(null);
      }
    });

    if (ev.index || ev.index == 0) {
      this.beaconFieldArrayPopulation(ev.group, ev.index);
    } else {
      this.beaconFieldPopulation(ev.varset, ev.group);
    }
  }

  beaconFieldPopulation(varset, group) {
    this.model[group][`${varset}_HistoricalLookup`] = true;
    if (this.form.controls[group]['controls'][`${varset}_HistoricalLookup`]) {
      this.form.controls[group]['controls'][
        `${varset}_HistoricalLookup`
      ].enable();
      this.form.controls[group]['controls'][
        `${varset}_HistoricalLookup`
      ].setValue(true);
    }

    if (
      this.beaconData.CurrentAnnuityDetailsArray_HasWithdrawalBenefit == 'Y' &&
      this.form.controls[group]['controls'][`${varset}_HasWithdrawalBenefit`]
    ) {
      this.form.controls[group]['controls'][
        `${varset}_HasWithdrawalBenefit`
      ].setValue(this.beaconData[`${varset}_HasWithdrawalBenefit`]);
      // this.model[`${varset}_HasWithdrawalBenefit`] = 'Y';
    } else if (
      this.form.controls[group]['controls'][`${varset}_HasWithdrawalBenefit`]
    ) {
      this.form.controls[group]['controls'][
        `${varset}_HasWithdrawalBenefit`
      ].setValue('N');
      // this.model[`${varset}_HasWithdrawalBenefit`] = 'N';
    }

    if (
      this.beaconData.CurrentAnnuityDetailsArray_HasGMAB == 'Y' &&
      this.form.controls[group]['controls'][`${varset}_HasGMAB`]
    ) {
      this.form.controls[group]['controls'][`${varset}_HasGMAB`].setValue(
        this.beaconData[`${varset}_HasGMAB`]
      );
      // this.model[`${varset}_HasGMAB`] = 'Y';
    } else if (this.form.controls[group]['controls'][`${varset}_HasGMAB`]) {
      this.form.controls[group]['controls'][`${varset}_HasGMAB`].setValue('N');
      // this.model[`${varset}_HasGMAB`] = 'N';
    }

    if (
      this.beaconData.CurrentAnnuityDetailsArray_HasDeathBenefit == 'Y' &&
      this.form.controls[group]['controls'][`${varset}_HasDeathBenefit`]
    ) {
      this.form.controls[group]['controls'][
        `${varset}_HasDeathBenefit`
      ].setValue(this.beaconData[`${varset}_HasDeathBenefit`]);
      // this.model[`${varset}_HasDeathBenefit`] = 'Y';
    } else if (
      this.form.controls[group]['controls'][`${varset}_HasDeathBenefit`]
    ) {
      this.form.controls[group]['controls'][
        `${varset}_HasDeathBenefit`
      ].setValue('N');
      // this.model['CurrentAnnuityDetailsArray_HasDeathBenefit'] = 'N';
    }

    Object.keys(this.beaconData).forEach(x => {
      setTimeout(() => {
        try {
          if (x != 'prodId') {
            if (typeof this.beaconData[x] === 'string') {
              this.form.controls[group]['controls'][x].setValue(
                this.beaconData[x]
              );
            } else if (
              typeof this.beaconData[x] != 'undefined' &&
              !isNaN(+this.beaconData[x])
            ) {
              let inpt;
              if (
                this.beaconData[x] &&
                !this.isNumber(this.beaconData[x]) &&
                this.beaconData[x].includes('%')
              ) {
                inpt = new Decimal(+this.beaconData[x].replace('%', ''))
                  .todp(3)
                  .toNumber()
                  .toString();
              } else if (
                !x.includes('RemainingCDSCYears') &&
                !x.includes('ShareClass')
              ) {
                inpt = new Decimal(+this.beaconData[x]).toNumber().toString();
              } else {
                inpt = this.beaconData[x].toString();
              }
              if (x !== 'AdditionalDepositAnnuity_DeathBenefitBase') {
                this.form.controls[group]['controls'][x].setValue(inpt);
              } else {
                setTimeout(() => {
                  this.form.controls[group]['controls'][x].setValue(inpt);
                });
              }
            } else {
              this.form.controls[group]['controls'][x].setValue(
                this.beaconData[x].toString()
              );
            }
          }
        } catch (err) {
          console.warn(
            'Error Setting ' +
              x +
              ' missing! Value: ' +
              this.beaconData[x] +
              'ERROR: ' +
              err
          );
        }
      }, 50);
    });

    this.validateFields(this.form);
  }

  beaconFieldArrayPopulation(group, idx) {
    const maskedInputChecks = [
      'CurrentAnnuityDetailsArray_BrokeragePercentFee',
      'CurrentAnnuityDetailsArray_SurrenderFee',
      'CurrentAnnuityDetailsArray_TotalAssets',
    ];
    this.model[group][idx]['CurrentAnnuityDetailsArray_HistoricalLookup'] =
      true;

    // setTimeout(() => {
    if (
      this.beaconData.CurrentAnnuityDetailsArray_PlanName &&
      this.model['annuity']
    ) {
      this.model['annuity'][idx].CurrentAnnuityDetailsArray_PlanName =
        this.beaconData.CurrentAnnuityDetailsArray_PlanName;
    }
    if (
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HistoricalLookup'
      ]
    ) {
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HistoricalLookup'
      ].setValue(true);
    }

    maskedInputChecks.map(inputCheck => {
      if (
        this.beaconData[inputCheck] &&
        this.form.controls[group]['controls'][idx]['controls'][inputCheck] &&
        this.isNumber(this.beaconData[inputCheck])
      ) {
        this.form.controls[group]['controls'][idx]['controls'][
          inputCheck
        ].setValue(this.beaconData[inputCheck].toString());
      }
    });

    if (
      this.beaconData.CurrentAnnuityDetailsArray_HasWithdrawalBenefit == 'Y' &&
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasWithdrawalBenefit'
      ]
    ) {
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasWithdrawalBenefit'
      ].setValue(
        this.beaconData['CurrentAnnuityDetailsArray_HasWithdrawalBenefit']
      );
      // this.model['CurrentAnnuityDetailsArray_HasWithdrawalBenefit'] = 'Y';
    } else if (
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasWithdrawalBenefit'
      ]
    ) {
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasWithdrawalBenefit'
      ].setValue('N');
      // this.model['CurrentAnnuityDetailsArray_HasWithdrawalBenefit'] = 'N';
    }
    setTimeout(() => {
      if (
        this.beaconData.CurrentAnnuityDetailsArray_HasGMAB == 'Y' &&
        this.form.controls[group]['controls'][idx]['controls'][
          'CurrentAnnuityDetailsArray_HasGMAB'
        ]
      ) {
        this.form.controls[group]['controls'][idx]['controls'][
          'CurrentAnnuityDetailsArray_HasGMAB'
        ].setValue(this.beaconData['CurrentAnnuityDetailsArray_HasGMAB']);
        // this.model['CurrentAnnuityDetailsArray_HasGMAB'] = 'Y';
      } else if (
        this.form.controls[group]['controls'][idx]['controls'][
          'CurrentAnnuityDetailsArray_HasGMAB'
        ]
      ) {
        this.form.controls[group]['controls'][idx]['controls'][
          'CurrentAnnuityDetailsArray_HasGMAB'
        ].setValue('N');
        // this.model['CurrentAnnuityDetailsArray_HasGMAB'] = 'N';
      }
    });

    if (
      this.beaconData.CurrentAnnuityDetailsArray_HasDeathBenefit == 'Y' &&
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasDeathBenefit'
      ]
    ) {
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasDeathBenefit'
      ].setValue(this.beaconData['CurrentAnnuityDetailsArray_HasDeathBenefit']);
      // this.model['CurrentAnnuityDetailsArray_HasDeathBenefit'] = 'Y';
    } else if (
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasDeathBenefit'
      ]
    ) {
      this.form.controls[group]['controls'][idx]['controls'][
        'CurrentAnnuityDetailsArray_HasDeathBenefit'
      ].setValue('N');
    }

    Object.keys(this.beaconData).forEach(x => {
      setTimeout(() => {
        try {
          if (x != 'prodId') {
            if (
              typeof this.beaconData[x] === 'string' &&
              !this.isNumber(this.beaconData[x])
            ) {
              if (x.includes('PurchaseDate')) {
                const dateString = this.beaconData[x].replace('Z', '');
                this.form.controls[group]['controls'][idx]['controls'][
                  x
                ].setValue(dateString);
              } else {
                this.form.controls[group]['controls'][idx]['controls'][
                  x
                ].setValue(this.beaconData[x].toString());
              }
            } else if (
              typeof this.beaconData[x] != 'undefined' &&
              !isNaN(+this.beaconData[x])
            ) {
              let inpt;
              if (
                this.beaconData[x] &&
                !this.isNumber(this.beaconData[x]) &&
                this.beaconData[x].includes('%')
              ) {
                inpt = new Decimal(+this.beaconData[x].replace('%', ''))
                  .todp(3)
                  .toNumber()
                  .toString();
              } else if (
                !x.includes('RemainingCDSCYears') &&
                !x.includes('ShareClass')
              ) {
                if (
                  typeof this.beaconData[x] === 'string' &&
                  this.beaconData[x].length < 1
                ) {
                  inpt = null;
                } else {
                  inpt = new Decimal(+this.beaconData[x]).toNumber().toString();
                }
              } else {
                inpt = this.beaconData[x].toString();
              }

              if (this.beaconData[x]) {
                this.form.controls[group]['controls'][idx]['controls'][
                  x
                ].setValue(inpt);
              }
            } else {
              if (this.model[group][idx][x] === null && this.beaconData[x]) {
                this.model[group][idx][x] = this.beaconData[x].toString();
              }
              this.form.controls[group]['controls'][idx]['controls'][
                x
              ].setValue(this.beaconData[x].toString());
            }
          }
        } catch (err) {
          console.warn(
            'Error Setting ' +
              x +
              ' missing! Value: ' +
              this.beaconData[x] +
              ' -- ERROR: ' +
              err
          );
        }
      });
    });
    this.validateFields(this.form);
    // });
  }

  handleFeeData(ev) {
    try {
      const index = ev['groupIndex'];
      const controls = (<FormGroup>(
        (<FormArray>this.form.controls[ev.group]).controls[index]
      )).controls;
      setTimeout(() => {
        this.safeSetValue(
          controls,
          `${ev.varset}_CalcedFundFee`,
          ev.results.AverageWeightedExpenseRatio * 100
        );
      });
    } catch (exception) {
      console.warn('Error with fund fee data: ' + exception);
    }
  }

  checkHash() {
    while (this.hashStatus.length > 0) {
      setTimeout(() => this.checkHash(), 500);
    }
    this.saveForm(this.model);
  }

  checkCustomError() {
    let custom = this.formErrors.filter(z => {
      return z.toLowerCase() != 'required' && z.toLowerCase() != 'regrequired';
    });

    custom = [...new Set(custom)];
    this.customMessages = [];
    if (custom.length > 0) {
      custom.forEach(z => {
        const mssg = this.model['formChecks'].find(x => {
          return x.id == z;
        })?.message;
        if (mssg && !this.customMessages.includes(mssg)) {
          this.customMessages.push(mssg);
        }
      });
    }

    return this.customMessages.length > 0;
  }

  flatten(object) {
    const isValidObject = (value: {}): boolean => {
      if (!value) {
        return false;
      }

      const isArray = Array.isArray(value);
      // const isBuffer = Buffer.isBuffer(value);
      const isΟbject =
        Object.prototype.toString.call(value) === '[object Object]';
      const hasKeys = !!Object.keys(value).length;
      // return !isArray && !isBuffer && isΟbject && hasKeys;
      return !isArray && isΟbject && hasKeys;
    };

    const walker = (child: {}, path: Array<string> = []) => {
      return Object.assign(
        {},
        ...Object.keys(child).map(key => {
          return isValidObject(child[key])
            ? walker(child[key], path.concat([key]))
            : { [key]: child[key] };
        })
      );
    };

    return Object.assign({}, walker(object));
  }

  getField(
    key: string,
    fields = this.fields,
    group?: string
  ): FormlyFieldConfig {
    let formattedFields;

    if (
      Array.isArray(fields) &&
      (fields[0].type === 'stepper' || this.fields[0].type == 'unified')
    ) {
      formattedFields = [];
      fields[0].fieldGroup.forEach(x => {
        x.fieldGroup
          ? x.fieldGroup.forEach(el => {
              if (
                !el.fieldGroup ||
                (el.fieldGroup && el.fieldGroup.length < 1)
              ) {
                formattedFields.push(el);
              } else if (el.fieldGroup && el.fieldGroup.length > 0) {
                el.fieldGroup.forEach(el_lvl2 => {
                  if (
                    !el_lvl2.fieldGroup ||
                    (el_lvl2.fieldGroup && el_lvl2.fieldGroup.length < 1)
                  ) {
                    formattedFields.push(el_lvl2);
                  } else if (
                    el_lvl2.fieldGroup &&
                    el_lvl2.fieldGroup.length > 0
                  ) {
                    el_lvl2.fieldGroup.forEach(el_lvl3 => {
                      formattedFields.push(el_lvl3);
                    });
                  }
                });
              }
            })
          : null;
      });
    } else if (group) {
      const fg = Array.isArray(fields)
        ? fields[0].fieldGroup
        : fields['fieldGroup'];
      formattedFields = fg.find(x => x.key === group).fieldGroup;
    } else {
      formattedFields = fields;
    }

    for (const field of formattedFields) {
      if (field.key === key) {
        return field;
      } else if (field.fieldGroup && field.fieldGroup.length > 0) {
        return this.getField(key, field.fieldGroup);
      }
    }
  }

  getGroup(key: string, fields = this.fields): FormlyFieldConfig {
    let formattedFields;

    if (
      Array.isArray(fields) &&
      (fields[0].type === 'stepper' || fields[0].type === 'unified')
    ) {
      formattedFields = [];
      fields[0].fieldGroup.forEach(x => {
        x.fieldGroup.forEach(el => {
          if (el.fieldGroup && el.fieldGroup.length > 0) {
            formattedFields.push(el);
            el.fieldGroup.forEach(el_lvl2 => {
              if (el_lvl2.fieldGroup && el_lvl2.fieldGroup.length < 1) {
                formattedFields.push(el_lvl2);
              }
            });
          }
        });
      });
    } else {
      formattedFields = fields[0];
    }

    for (const field of formattedFields) {
      if (field.key === key) {
        return field;
      }
    }
  }

  handleRegClick() {
    this.modelUpdate();
    this.validateFields(this.form);
  }

  modelUpdate(ev?) {
    const emitted = ev ? ev : this.model;
    this.formChange.emit({ model: emitted });
    this.frmSrvc.updateFormValidity({ model: emitted, form: this.form });
  }

  formatSteps(group) {
    const stepsOutput = group.map((stp, idx) => {
      const step = {
        visited: stp.interacted,
        completed: stp.completed,
        valid: !this.formErrors[`step${idx}`],
        state: stp.state,
      };

      return step;
    });
    return stepsOutput;
  }

  isNumber(value) {
    const formatted = +this.removePercentMask(value);
    return !isNaN(formatted);
  }

  alphabetizeGroups(a, b) {
    const groupA = a.label.toUpperCase();
    const groupB = b.label.toUpperCase();

    let comparison = 0;
    if (groupA > groupB) {
      comparison = 1;
    } else if (groupA < groupB) {
      comparison = -1;
    }
    return comparison;
  }

  removeMoneyMask(obj) {
    if (obj) {
      return Number(obj.replace(/\,/gi, '').replace('$', ''));
    }
  }

  removePercentMask(obj) {
    if (typeof obj === 'string' || obj instanceof String) {
      if (obj.includes('%,') || obj.includes('%, ')) {
        return obj.replace(/%/g, '').replace(/\s/g, '').trim();
      } else {
        const formatted = obj
          .replace(/\,/gi, '')
          .replace(/%/g, '')
          .replace(/\s/g, '');
        return Number(formatted) / 100;
      }
    }
    return Number(obj) / 100;
  }

  formatPercent(num, precision?) {
    const inpt = new Decimal(num);
    return inpt.times(100).todp(precision ? precision : 3);
  }

  deconstructPercent(num) {
    return (num / 1000) * 100;
  }

  getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  }

  evalConditional(condit, passedModel?, passedField?) {
    const varType = typeof condit;
    if (varType === 'boolean') {
      return condit;
    } else {
      const actualModel =
        passedModel && Object.keys(passedModel).length > 0
          ? passedModel
          : this.model;
      let localModelData;

      if (passedModel) {
        localModelData = passedModel;
      } else if (passedField) {
        localModelData = passedField.model;
      }

      try {
        const parser = new ES6Parser();
        const staticEval = new ES6StaticEval();
        const ast = parser.parse(condit);
        const value = staticEval.evaluate(ast, {
          model: actualModel,
          root: this.model,
          mainModel: this.model,
          field: passedField,
          localModel: localModelData,
        });
        return value;
      } catch (err) {
        console.warn(`   `);

        console.warn(`***** ***** ***** ***** ***** ***** **** *****`);
        console.warn(`**** Can't process conditional: ${condit} ****`);
        console.warn(`**** ***** Processing Error: ${err} ***** ****`);
        console.warn(`***** ***** ***** ***** ***** ***** **** *****`);
        console.warn(`   `);
      }
    }
  }

  evalNonStandardConditional(condit) {
    const model = this.model;
    return new Function('model', 'return (' + condit + ')')(model);
  }

  stringSplit(str) {
    if (str.includes(', ')) {
      return str.split(', ');
    } else if (str.includes(',')) {
      return str.split(',');
    } else if (str.includes('|')) {
      return str.split('|');
    } else if (str.includes('| ')) {
      return str.split('| ');
    }
  }

  setGroupModelProperties(group, field, value, index?, parent?) {
    const formattedVarName = field.varName.replace('.', '_');
    if (index > -1) {
      if (!this.model[field.group]) {
        this.model[field.group] = [];
      }

      if (!this.model[field.group][index]) {
        this.model[field.group][index] = {};
      }
      this.model[field.group][index][formattedVarName] = value;
    } else {
      if (!this.model[field.group]) {
        this.model[field.group] = {};
      }
      this.model[field.group][formattedVarName] = value;
    }
  }

  setDefault(fieldData) {
    if (
      fieldData.defaultConditional &&
      this.evalConditional(fieldData.defaultConditional)
    ) {
      return fieldData.defaultIfTrue;
    } else {
      return fieldData.defaultValue;
    }
  }

  nestedLoopTransform() {
    const list = this.deDupObjectArray(this.nestedLoopList);

    list.forEach(x => {
      try {
        if (this.model[x.id]) {
          let fullCount = 0;

          this.model[x.id].forEach(el => {
            const idField: any = Object.keys(el).find(
              k => k.includes('SourceBridgeID') || k.includes('TSPSourceID')
            );

            let parentIdx = this.model[x.parentGroup].findIndex(grp => {
              const grpIdField: any = Object.keys(grp).find(g =>
                g.includes(idField.split('_')[1])
              );
              return grp[grpIdField] === el[idField];
            });

            parentIdx = parentIdx > -1 ? parentIdx : 0;

            if (!this.model[x.parentGroup]) {
              this.model[x.parentGroup] = [];
            }

            if (!this.model[x.parentGroup][parentIdx]) {
              this.model[x.parentGroup][parentIdx] = [];
            }

            if (!this.model[x.parentGroup][parentIdx][x.id]) {
              this.model[x.parentGroup][parentIdx][x.id] = [];
            }

            this.model[x.parentGroup][parentIdx][x.id].push(el);

            fullCount++;

            if (fullCount === this.model[x.id].length) {
              delete this.model[x.id];
              console.warn(`Removed ${x.id} From Model After Transfer`);
            }
          });
        }
      } catch (error) {
        console.warn('Error Setting Nested Group: ', error);
      }
    });
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  cleanHtml(value) {
    const htmlRegEx = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    if (!value || Boolean(value)) {
      return value;
    } else if (Array.isArray(value)) {
      const newValue = value.forEach(x => {
        return x.replace(htmlRegEx, '');
      });
      return newValue;
    } else {
      return value.replace(htmlRegEx, '');
    }
  }
}
