import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AnnuityWizardService } from '../services/annuity-wizard-service.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, UntypedFormGroup, FormGroup } from '@angular/forms';
import {
  GridComponent,
  DataBindingDirective,
  FilterService,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  process,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-via',
  templateUrl: './via.component.html',
  styleUrls: ['./via.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViaComponent implements OnInit {
  @ViewChild(DataBindingDirective) directive;

  public gridData: any;
  public ogData: any;
  public gridColumns: any[] = [];
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  public defaultValue = undefined;
  form = new UntypedFormGroup({
    defaultsControl: new FormControl(),
  });

  public defaults = [
    // {
    //   label: 'Uncapped Rate – Floor Rate Highest to Lowest',
    //   sort: [
    //     { field: 'VARate$Floor', dir: 'desc'},
    //   ],
    //   filter: [
    //     { field: 'VARate$Floor', operator: 'doesnotcontain', value: 'N/A'},
    //     { field: 'VARate$RateCap', operator: 'contains', value: 'Uncapped'}
    //   ]
    // },
    {
      label: 'Uncapped Rate – Buffer Rate Lowest to Highest',
      sort: [{ field: 'VARate$Buffer', dir: 'asc' }],
      filter: [
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: 'N/A' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-9.99' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-.99' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-999' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-99.99' },
        { field: 'VARate$RateCap', operator: 'contains', value: 'Uncapped' },
      ],
    },
    {
      label: 'Highest Cap Rate (excluding uncapped) – Floor Rate Protection',
      sort: [{ field: 'VARate$RateCap', dir: 'desc' }],
      filter: [
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: 'N/A' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-9.99' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-.99' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-999' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-99.99' },
        {
          field: 'VARate$RateCap',
          operator: 'doesnotcontain',
          value: 'Uncapped',
        },
      ],
    },
    {
      label: 'Highest Cap Rate (excluding uncapped) – Buffer Rate Protection',
      sort: [{ field: 'VARate$RateCap', dir: 'desc' }],
      filter: [
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: 'N/A' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-9.99' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-.99' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-999' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-99.99' },
        {
          field: 'VARate$RateCap',
          operator: 'doesnotcontain',
          value: 'Uncapped',
        },
      ],
    },
    {
      label: 'Highest Par Rate – Floor Rate Protection',
      sort: [{ field: 'VARate$ParticipationRate', dir: 'desc' }],
      filter: [
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: 'N/A' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-9.99' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-.99' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-999' },
        { field: 'VARate$Floor', operator: 'doesnotcontain', value: '-99.99' },
      ],
    },
    {
      label: 'Highest Par Rate – Buffer Rate Protection',
      sort: [{ field: 'VARate$ParticipationRate', dir: 'desc' }],
      filter: [
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: 'N/A' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-9.99' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-.99' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-999' },
        { field: 'VARate$Buffer', operator: 'doesnotcontain', value: '-99.99' },
      ],
    },
    {
      label: 'Highest Fixed Rate',
      sort: [{ field: 'VARate$Rate', dir: 'desc' }],
      filter: [{ field: 'VARate$Rate', operator: 'neq', value: 0 }],
    },
  ];

  columnWidths = {
    AnnuityProduct$Carrier: 200,
    AnnuityProduct$Contract: 200,
    InvestProductVA$ShortName: 200,
    InvestProductVA$IndexName: 200,
  };

  cusip: string;
  cusipTitle;
  loading = true;
  loadedUserPrefs = false;
  filterData = {};

  constructor(
    private aws: AnnuityWizardService,
    private ss: SessionStorageService,
    private route: ActivatedRoute,
    private prefSrvc: UserPreferencesService,
    private cstmFltrSrvc: CustomFiltersService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.cusip) {
        this.cusip = params.cusip;
      }
    });

    this.getGridData();
    this.pageSize = this.ss.get('awShelfPageSize') || 25;
    this.state.take = this.ss.get('awShelfPageSize') || 25;
  }

  getGridData() {
    this.aws.getViaRates().subscribe(res => {
      const gd = res.results;
      gd.headers.forEach(x => {
        switch (x.RespVis) {
          case 'xs':
            x.RespVis = '';
            break;
          case 'sm':
            x.RespVis = '(min-width: 700px)';
            break;
          case 'md':
            x.RespVis = '(min-width: 1100px)';
            // this.hideColumn(x.DataField);
            break;
          case 'lg':
            x.RespVis = '(min-width: 1200px)';
            this.hideColumn(x.DataField);
            break;
          case 'xl':
            x.RespVis = '(min-width: 1500px)';
            this.hideColumn(x.DataField);
            break;
        }
        if (Object.keys(this.columnWidths).includes(x.DataField)) {
          x.Width = this.columnWidths[x.DataField];
        } else if (!Object.keys(this.columnWidths).includes(x.DataField)) {
          x.Width = 100;
        }
      });

      this.gridColumns = gd.headers;
      this.ogData = gd.data;
      this.gridData = this.ogData;
      this.buildFilterOptions();

      if (this.cusip && this.cusip.length) {
        this.cusipFilter(this.cusip);
      }

      this.getUserDefinedColumns();
      this.loading = false;
    });
  }

  setHiddenColumns(reset = false) {
    setTimeout(() => {
      const columns = [...this.gridColumns];
      if (!this.loadedUserPrefs && !reset) {
        columns.forEach(x => {
          if (x.Hidden) {
            this.hidden.push(x.DataField);
          }
        });
        this.gridColumns = [...columns];
      } else {
        columns.forEach(x => {
          if (this.hidden.includes(x.DataField)) {
            x.Hidden = true;
          } else {
            x.Hidden = false;
          }
        });
        this.gridColumns = [...columns];
      }

      this.hidden.forEach(x => {
        this.hideColumn(x);
      });
    });
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.group = groups;
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  public cusipFilter(cusip) {
    this.filter['filters'] = [
      {
        field: 'AnnuityProduct$AnnuityID',
        operator: 'eq',
        value: cusip,
      },
    ];
    this.gridData = process(this.ogData, { filter: this.filter });
  }

  updateHidden(ev) {
    this.hidden = ev.value;
    this.setStorage();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
      this.setStorage();
    }
  }

  toggleColumn(columnName: string): void {
    const hiddenColumns = this.hidden;

    if (!this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  showColumn(columnName: string): void {
    const hiddenColumns = this.hidden;

    if (this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  setStorage() {
    this.ss.set('hiddenViaColumns', this.hidden);
  }

  useDefault(ev, clearControl = false) {
    this.cusip = null;
    if (ev.value == 'clear') {
      this.group = [];
      if (this.filter) {
        this.filter['filters'] = [];
      }
      this.sort = [];
      this.skip = 0;
      if (clearControl) {
        this.form.controls.defaultsControl.reset();
        this.setHiddenColumns(true);
        this.buildFilterOptions();
      }

      this.directive.skip = 0;

      if (!this.directive.state.filter) {
        this.directive.state.filter = { filters: [] };
      }
      this.directive.state.filter.filters = [];
    } else {
      this.useDefault({ value: 'clear' });
      const preset = this.defaults.find(x => x.label == ev.value);
      if (preset.filter) {
        if (!this.filter) {
          this.filter = { filters: [], logic: 'and' };
        }
        this.filter['filters'] = preset.filter;
        this.directive.state.filter.filters = preset.filter;
      } else {
        this.filter['filters'] = [];
        this.directive.state.filter.filters = [];
      }

      if (preset.sort) {
        this.sort = preset.sort as [];
      } else {
        this.sort = [];
      }
    }

    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
    this.saveUserDefinedColumns();
  }

  getUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });

    this.prefSrvc.getColumns().subscribe(x => {
      if (x && x.via && x.via.length > 0) {
        this.loadedUserPrefs = true;
        const hidden = fullList.filter(y => x && x.via && !x.via.includes(y));
        this.updateHidden({ value: hidden });
      }
      this.setHiddenColumns();
    });
  }

  saveUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const visible = fullList.filter(x => !this.hidden.includes(x));

    this.prefSrvc.saveColumns({ via: visible }).subscribe();
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    const passedFilterNames = [];
    const passedFilterDatas = {};

    // Extract passed filter data for trickle down column filter option narrowing
    filter.filters.map(filterObj => {
      const filterName = filterObj['filters'][0]['field'];
      passedFilterNames.push(filterName);
      passedFilterDatas[filterName] = this.filterData[filterName];
    });

    this.gridData = filterBy(this.ogData, filter);
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      filterBy(this.ogData, filter),
      this.gridColumns
    );

    // Replace filter options with trickled down options
    passedFilterNames.map(filterName => {
      this.filterData[filterName] = passedFilterDatas[filterName];
    });
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
  }

  exportToExcel(grid: GridComponent): void {
    const newGrid = cloneDeep(grid);
    newGrid.saveAsExcel();
  }

  public excelData = () => {
    const fullData = this.ogData.map(a => ({ ...a }));
    fullData.forEach(el => {
      Object.keys(el).forEach(x => {
        if (
          x === 'VIARate$DeclaredRateCap' ||
          x === 'VIARate$FixedRate' ||
          x === 'VIARate$ParticipationRate' ||
          x === 'VIARate$PerformanceTriggerRate' ||
          x === 'VIARate$PremiumBonus' ||
          x === 'VIARate$Spread'
        ) {
          el[x] = `${this.round(el[x] * 100, 2)}%`;
        }

        if (el[x] == -9.99 || el[x] == -999) {
          el[x] = 'N/A';
        }
      });
    });
    return process(fullData, {});
  };

  round = (num: number, places: number) => {
    return +(Math.round(Number(num + 'e+' + places)) + 'e-' + places);
  };

  dataStateChange(ev) {
    this.ss.set('awShelfPageSize', ev.take);
  }
}
