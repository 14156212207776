<mat-drawer-container>
  @if (loggedIn) {
    <mat-toolbar
      color="primary"
      class="unified-header"
      [class.proxy-active]="proxyActive"
    >
      @if (rights.includes('ToolsLandingPage')) {
        <button class="home-button" (click)="goToPage('/tools')">
          <img src="{{ './assets/' + logoName }}" class="unified-logo" />
        </button>
      } @else {
        <button class="home-button" (click)="goToPage('/unified')">
          <img src="{{ './assets/' + logoName }}" class="unified-logo" />
        </button>
      }

      @if (proxyActive) {
        <button
          mat-button
          class="proxy"
          (click)="endProxy()"
          [matTooltip]="
            user.firstname +
            ' ' +
            user.lastname +
            ' Currently proxying for: ' +
            proxyData.name +
            ' click to end.'
          "
        >
          <mat-icon>co_present</mat-icon>: {{ proxyData.name }}
        </button>
      }
      <button mat-button [matMenuTriggerFor]="userMenu" class="menu-button">
        <span class="username">{{ user?.firstname }} {{ user?.lastname }}</span>
        <mat-icon>account_circle</mat-icon>
      </button>

      <mat-menu
        #userMenu="matMenu"
        direction="down"
        yPosition="below"
        class="unified-menu"
      >
        @if (isAdmin) {
          <button mat-menu-item id="admin-item" [matMenuTriggerFor]="adminMenu">
            <mat-icon>admin_panel_settings</mat-icon> Admin
          </button>
        }
        @if (reporting) {
          <button mat-menu-item id="reporting-item" routerLink="/reporting">
            <mat-icon>assessment</mat-icon>
            Reporting
          </button>
        }
        @if (ProxyinMenu && !proxyActive) {
          <button mat-menu-item id="proxy-item" (click)="proxy()">
            <mat-icon>co_present</mat-icon> Proxy
          </button>
        }
        @if (proxyActive) {
          <button mat-menu-item id="end-proxy-item" (click)="endProxy()">
            <mat-icon>dangerous</mat-icon> End Proxy
          </button>
        }
        @if (toolsLandingPage) {
          <button mat-menu-item id="tools-item" routerLink="/tools">
            <mat-icon>handyman</mat-icon> Tools
          </button>
        }
        @if (displayHelp && displayHelpMenu) {
          <button
            mat-menu-item
            id="help-menu-item"
            (click)="getHelp(null, 'rb')"
            [matMenuTriggerFor]="helpMenu"
          >
            <mat-icon>help</mat-icon> Help
          </button>
        }
        @if (displayHelp && !displayHelpMenu) {
          <button
            mat-menu-item
            id="help-item"
            [ngClass]="{ disabled: noLink }"
            (click)="getHelp(null, 'rb')"
          >
            <mat-icon>help</mat-icon> Help
          </button>
        }
        @if (isAdmin) {
          <button mat-menu-item id="tech-notes-item" (click)="openTechNotes()">
            <mat-icon>edit_note</mat-icon> Tech Notes
          </button>
        }
        <button mat-menu-item id="logout-item" (click)="logout()">
          <mat-icon>logout</mat-icon> Logout
        </button>
      </mat-menu>

      <mat-menu #adminMenu="matMenu">
        @if (isAdmin && rights.includes('RolesAndRights')) {
          <button
            mat-menu-item
            id="roles-rights-item"
            routerLink="/admin/roles-rights"
          >
            <mat-icon>lock_person</mat-icon>Roles/Rights
          </button>
        }
        @if (isAdmin && rights.includes('UnitProfile')) {
          <button
            mat-menu-item
            id="unit-profile-item"
            routerLink="/admin/unit-profile"
          >
            <mat-icon>source</mat-icon>Unit Profiles
          </button>
        }
        @if (isAdmin && rights.includes('RepManagement')) {
          <button
            mat-menu-item
            id="rep-management-item"
            routerLink="/admin/rep-management"
          >
            <mat-icon>perm_contact_calendar</mat-icon>Rep Management
          </button>
        }
        @if (isAdmin && rights.includes('UserManagement')) {
          <button
            mat-menu-item
            id="user-management-item"
            routerLink="/admin/user-management"
          >
            <mat-icon>people_alt</mat-icon>User Management
          </button>
        }
        @if (isAdmin && rights.includes('Unit')) {
          <button
            mat-menu-item
            id="unit-item"
            routerLink="/admin/unit-management"
          >
            <mat-icon>home_work</mat-icon>Unit Management
          </button>
        }
        @if (isAdmin && rights.includes('KnowledgeBase')) {
          <button
            mat-menu-item
            id="knowledge-base-item"
            routerLink="/admin/uploads"
          >
            <mat-icon>upload</mat-icon>Uploads
          </button>
        }
        @if (isAdmin) {
          <button
            mat-menu-item
            id="knowledge-base-history-item"
            routerLink="/admin/kb-change-history"
          >
            <mat-icon>timeline</mat-icon>KB Changes
          </button>
        }

        @if (isAdmin && rights.includes('LegacyReport')) {
          <div>
            <form
              ngNoForm
              method="post"
              action="{{ ceteraAction }}"
              enctype="multipart/form-data"
            >
              <input type="text" hidden name="X_RB_u" value="{{ session }}" />
              <button mat-menu-item id="legacy-report-item" type="submit"
                ><mat-icon>collections_bookmark</mat-icon>Legacy Reports</button
              >
            </form>
          </div>
        }

        @if (isAdmin && rights.includes('Toolbox')) {
          <button mat-menu-item id="toolbox-item" routerLink="/admin/toolbox">
            <mat-icon>home_repair_service</mat-icon>Toolbox
          </button>
        }
      </mat-menu>

      <mat-menu #helpMenu="matMenu">
        @if (displayHelp && rights.includes('PPHelp')) {
          <button
            mat-menu-item
            id="pp-help-item"
            (click)="
              getHelp('https://www.help.rightbridge.net/productprofiler', 'pp')
            "
          >
            <mat-icon class="mr-0" svgIcon="pp_icon"></mat-icon> Product
            Profiler
          </button>
        }
        @if (displayHelp && rights.includes('AWHelp')) {
          <button
            mat-menu-item
            id="aw-help-item"
            (click)="
              getHelp('https://www.help.rightbridge.net/annuitywizard', 'aw')
            "
          >
            <mat-icon class="mr-0" svgIcon="aw_icon"></mat-icon> Annuity Wizard
          </button>
        }
        @if (displayHelp && rights.includes('IWHelp')) {
          <button mat-menu-item id="iw-help-item" (click)="getHelp(null, 'iw')">
            <mat-icon class="mr-0" svgIcon="iw_icon"></mat-icon> Investment
            Wizard
          </button>
        }
        @if (displayHelp && rights.includes('LIHelp')) {
          <button mat-menu-item id="li-help-item" (click)="getHelp(null, 'li')">
            <mat-icon class="mr-0" svgIcon="lw_icon"></mat-icon> Life Insurance
            Wizard
          </button>
        }
      </mat-menu>
    </mat-toolbar>
  }
  <router-outlet></router-outlet>
  @if (loggedIn) {
    <app-footer></app-footer>
  }
</mat-drawer-container>
