import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { SharedReportDialogComponent } from '../report-dialog/report-dialog.component';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SuggestionEngineService } from '../../suggestion-engine/suggestion-engine.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { LifeEventComponent } from '../life-event-dialog/life-event-dialog.component';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})
export class ClientInfoComponent implements OnInit {
  clientData;
  @Output() profileNavigate = new EventEmitter();
  @Input() client: string;
  @Input() name: string;
  @Input() set dataSet(data) {
    this.clientData = data;
    if (data && Object.keys(data).length) {
      this.dataUpdate();
    }
  }

  private environment;
  private baseUrl: string;

  info;
  accounts = { investment: [], annuity: [], lifeInsurance: [], longTerm: [] };
  notes;
  profList;
  noAccounts = true;
  tabIndex = 0;
  tabsOpen = true;
  isProspect = false;
  addToList = true;
  loading = false;

  constructor(
    private rbs: RightBridgeApiService,
    private ses: SuggestionEngineService,
    private router: Router,
    private dialog: MatDialog,
    private ss: SessionStorageService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    this.profList = this.ss.get('seProfileList');
  }

  dataUpdate() {
    this.accounts = {
      investment: this.clientData.InvestmentAccount,
      annuity: this.clientData.AnnuityContract,
      lifeInsurance: this.clientData.LIPolicy,
      longTerm: this.clientData.OtherPolicy,
    };

    this.noAccounts =
      !this.accounts.longTerm['data'].length &&
      !this.accounts.lifeInsurance['data'].length &&
      !this.accounts.annuity['data'].length &&
      !this.accounts.investment['data'].length;

    Object.keys(this.clientData).forEach(el => {
      if (
        !el.includes('InvestmentAccount') ||
        !el.includes('AnnuityContract') ||
        !el.includes('LIPolicy') ||
        !el.includes('OtherPolicy') ||
        el !== 'needs'
      ) {
        this.info = !this.info ? {} : this.info;
        if (this.clientData[el] && this.clientData[el].display) {
          this.info[el.replace('.', '_')] = {
            label: this.clientData[el].label,
            display: this.clientData[el].display[0],
            value: this.clientData[el].raw[0],
          };
        }
      }
    });
    this.addToList = !this.clientData.inCallList;
    this.isProspect = this['info.Client^IsProspect'];
    this.getNotes(this.client);
  }

  addToCallList() {
    const profile = [this.client];
    this.ses.addToCallList(profile).subscribe(() => {
      console.info('Client added to call list');
      this.profileNavigate.emit(this.client);
    });
  }

  removeFromCallList() {
    const profile = [this.client];
    this.ses.removeFromCallList(profile).subscribe(() => {
      console.info('Client removed from call list');
      this.profileNavigate.emit(this.client);
    });
  }

  dlReport() {
    const dialogRef = this.dialog.open(SharedReportDialogComponent, {
      panelClass: 'report-dialog',
      data: { app: 'se', bulk: false, needs: this.clientData.needs },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data != 'cancel') {
        this.loading = true;
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        const client = [this.client];
        const options = { ...result.data };
        this.rbs.getClientReport(client, options).subscribe(x => {
          const filename = result.data.filename
            ? result.data.filename + '.pdf'
            : this.name + '-report.pdf';

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(x);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
            this.loading = false;
          };
        });
      }
    });
  }

  editProfile() {
    this.router.navigate(['/se/client/edit/' + this.client]);
  }

  getNotes(clnt) {
    this.rbs.getNotes(clnt).subscribe(x => {
      this.notes = x;
    });
  }

  addNote() {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      panelClass: 'notes-dialog',
      data: { type: null, headline: null, placeholder: null },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data != 'cancel') {
        this.rbs.addNote(this.client, result.data).subscribe(x => {
          console.info('Note added');
          this.getNotes(this.client);
        });
      }
    });
  }

  togglePin(note) {
    if (note.isPinned) {
      this.rbs.pinToggle(note.profileId, note.note, false).subscribe(() => {
        const idx = this.notes.notes.findIndex(nt => nt.note == note.note);
        this.notes.notes[idx].isPinned = false;
      });
    } else {
      this.rbs.pinToggle(note.profileId, note.note, true).subscribe(() => {
        const idx = this.notes.notes.findIndex(nt => nt.note == note.note);
        this.notes.notes[idx].isPinned = true;
      });
    }
    setTimeout(() => {
      this.getNotes(this.client);
    });
  }

  profNav(client, dir) {
    const idx = this.profList.indexOf(client);
    let newIdx = dir === 'next' ? idx + 1 : idx - 1;
    newIdx = newIdx < 0 ? this.profList.length - 1 : newIdx;
    const newProf =
      dir === 'next' ? this.profList[newIdx] : this.profList[newIdx];
    this.profileNavigate.emit(newProf);
  }

  addLifeEvent() {
    const dialogRef = this.dialog.open(LifeEventComponent, {
      panelClass: 'notes-dialog',
      data: { type: null, headline: null, placeholder: null },
    });
    const user = this.ss.get('globals').user.id;
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data != 'cancel') {
        this.ses
          .saveLifeEvent(
            this.client,
            result.data.type,
            user,
            result.data.date.toLocaleDateString('en-US'),
            result.data.note
          )
          .subscribe(x => {
            console.info('Life event added');
            this.getNotes(this.client);
            this.profileNavigate.emit(this.client);
          });
      }
    });
  }

  tabClick(ev, idx) {
    this.tabsOpen = idx === this.tabIndex ? !this.tabsOpen : true;
  }
}
