import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-sectionHeader',
  styleUrls: ['form-sectionHeader.component.scss'],
  template: `<label
      class="section col-12"
      *ngIf="props.label"
      [innerHTML]="props.label"
      [ngClass]="{ border: to.labelBorder }"
    ></label
    ><p
      *ngIf="props.prompt"
      class="explanation"
      [innerHTML]="props.prompt"
    ></p>`,
})
export class FormlySectionHeader extends FieldType {
  @HostBinding('class') classes = 'header-section';

  constructor() {
    super();
  }
}
