@switch (field.controlType) {
  @case ('integer') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <input
        matInput
        (keyup)="changeText($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
    </mat-form-field>
  }
  @case ('currency') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <input
        matInput
        (keyup)="changeText($event)"
        [maskito]="dollarMask"
        maxlength="20"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
    </mat-form-field>
  }
  @case ('text') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <input
        matInput
        (keyup)="changeText($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
    </mat-form-field>
  }
  @case ('states') {
    <mat-form-field class="mr-2">
      <mat-label>Select States to Filter by</mat-label>
      <mat-select
        multiple
        (selectionChange)="changeSelection($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      >
        @for (state of states; track state.value) {
          <mat-option [value]="state.value">
            {{ state.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @case ('dropdown') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <mat-select
        (selectionChange)="changeSelection($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      >
        @for (opt of field.options; track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.display }}
          </mat-option>
        }
      </mat-select>
      @if (
        filterForm.value[groupKey][field.varName] &&
        filterForm.value[groupKey][field.varName].length >= 1
      ) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('multiselect') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <mat-select
        multiple
        (selectionChange)="changeSelection($event)"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      >
        @for (opt of field.options; track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.display }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @case ('repCode') {
    <mat-form-field class="mr-2 rep-code-select">
      <mat-label>{{ field.label }}</mat-label>
      <input
        type="text"
        [placeholder]="field.label"
        matInput
        [matAutocomplete]="autoRepCode"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
      <mat-autocomplete
        #autoRepCode="matAutocomplete"
        (optionSelected)="changeSelection($event)"
      >
        @for (opt of getDropdownOptions(); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (showClearButton(field.varName)) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('unit') {
    <mat-form-field class="mr-2 unit-select">
      <mat-label>{{ field.label }}</mat-label>
      <input
        type="text"
        [placeholder]="field.label"
        matInput
        [matAutocomplete]="autoUnit"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
      <mat-autocomplete
        #autoUnit="matAutocomplete"
        (optionSelected)="changeSelection($event)"
      >
        @for (opt of getDropdownOptions(); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (showClearButton(field.varName)) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('user') {
    <mat-form-field class="mr-2 user-select">
      <mat-label>{{ field.label }}</mat-label>
      <input
        type="text"
        [placeholder]="field.label"
        matInput
        [matAutocomplete]="autoUser"
        [formControl]="filterForm.controls[groupKey].controls[field.varName]"
      />
      <mat-autocomplete
        #autoUser="matAutocomplete"
        (optionSelected)="changeSelection($event)"
      >
        @for (opt of getDropdownOptions(); track opt.value) {
          <mat-option value="{{ opt.value }}">
            {{ opt.value }}: {{ opt.display }}
          </mat-option>
        }
      </mat-autocomplete>
      @if (showClearButton(field.varName)) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          matTooltip="Clear Filter"
          (click)="resetFilter($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @case ('range') {
    <mat-form-field class="mr-2">
      <mat-label>{{ field.label }}</mat-label>
      <mat-date-range-input [rangePicker]="pickerField">
        <input
          matStartDate
          placeholder="After"
          [value]="filterForm.controls[groupKey].controls[field.varName]?.value"
          (dateChange)="changeDate($event)"
        />
        <input
          matEndDate
          placeholder="Before"
          [value]="filterForm.controls[groupKey].controls[field.varName]?.value"
          (dateChange)="changeDate($event)"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="pickerField"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #pickerField></mat-date-range-picker>
    </mat-form-field>
  }
  @case ('presetRange') {
    <span class="rb-range-picker-container">
      <mat-form-field class="mr-2">
        <mat-label>{{ field.label }}</mat-label>
        <mat-select
          (selectionChange)="rangeSelection($event)"
          [formControl]="filterForm.controls[groupKey].controls[field.varName]"
        >
          @for (opt of floatingRangeOptions; track opt.value) {
            <mat-option value="{{ opt.value }}">
              {{ opt.display }}
            </mat-option>
          }
        </mat-select>
        @if (
          filterForm.value[groupKey][field.varName] &&
          filterForm.value[groupKey][field.varName].length >= 1
        ) {
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            color="warn"
            matTooltip="Clear Filter"
            (click)="clearRange($event)"
          >
            <mat-icon>close</mat-icon>
          </button>
        }
      </mat-form-field>
      <mat-form-field
        class="mr-2 preset-date-picker"
        [ngClass]="{
          visible:
            filterForm.controls[groupKey].controls[field.varName].value ===
            'custom'
        }"
      >
        <mat-label>{{ field.label }}</mat-label>
        <mat-date-range-input [rangePicker]="pickerField">
          <input
            #startDatePicker
            matStartDate
            placeholder="After"
            (dateChange)="changeDate($event)"
            [formControl]="start"
          />
          <input
            #endDatePicker
            matEndDate
            placeholder="Before"
            (dateChange)="changeDate($event)"
            [formControl]="end"
          />
        </mat-date-range-input>
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          color="warn"
          (click)="clearDateField()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="pickerField"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #pickerField></mat-date-range-picker>
      </mat-form-field>
    </span>
  }
  @case ('relatedDate') {
    <span>
      <mat-form-field class="mr-2">
        <mat-label>{{ field.label }}</mat-label>
        <mat-date-range-input [rangePicker]="rangePickerOne">
          <input
            matStartDate
            placeholder="Start date"
            [formControl]="start"
            (dateChange)="changeDate($event)"
          />
          <input
            matEndDate
            placeholder="End date"
            [formControl]="end"
            (dateChange)="changeDate($event)"
          />
        </mat-date-range-input>
        <mat-hint> Cannot be used with '{{ field.relatedLabel }}' </mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="rangePickerOne"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #rangePickerOne></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field class="mr-2">
        <mat-label>{{ field.relatedLabel }}</mat-label>
        <mat-date-range-input [rangePicker]="rangePickerTwo">
          <input
            matStartDate
            placeholder="Start date"
            [formControl]="relatedStart"
            (dateChange)="changeRelatedDate($event)"
          />
          <input
            matEndDate
            placeholder="End date"
            [formControl]="relatedEnd"
            (dateChange)="changeRelatedDate($event)"
          />
        </mat-date-range-input>
        <mat-hint> Cannot be used with '{{ field.label }}' </mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="rangePickerTwo"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #rangePickerTwo></mat-date-range-picker>
      </mat-form-field>
    </span>
  }
}
