import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { CompareDialogComponent } from '../../compare-dialog/compare-dialog.component';
import { ModelDialogComponent } from '../../model-dialog/model-dialog.component';
import { ReportDialogComponent } from '../../report-dialog/report-dialog.component';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { AnnuityWizardService } from '../services/annuity-wizard-service.service';
import { saveAs } from '@progress/kendo-file-saver';
import { cloneDeep } from 'lodash';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConsolidatedReportModalComponent } from '../../shared-components/consolidated-report-modal/consolidated-report-modal.component';
import { CustomReportModalComponent } from '../../shared-components/custom-report-modal/custom-report-modal.component';
import { UnifiedFlowService } from '../../unified-flow/unified.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-annuities-result',
  templateUrl: './annuities-result.component.html',
  styleUrls: ['./annuities-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AnnuitiesResultComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('samlForm') samlForm: ElementRef;
  @Output() downloadFactsheet = new EventEmitter();
  @Input() hasSummaryModule;

  private environment;
  private baseUrl: string;

  id: string;
  chartData;
  tableData = [];
  columns = [];
  rows = [];
  realData = [];
  annuities = [];
  programs = {};
  modelData = { name: '' };
  selProgram: string;
  showAll = false;
  compareList: string[] = [];
  compareChecked = {};
  loading = true;
  scoring = {};
  annuityForm;
  model = {};
  tableList = [];
  vaTable = {};
  iaTable = {};
  faTable = {};
  ioTable = {};
  viaTable = {};
  io_idxTable = {};
  fa2Table = {};
  horzTable1 = {};
  horzTable2 = {};
  clientName: string;
  isExchange = false;
  externalLink;
  externalAudit;
  crossApp;
  hiddenItems = true;
  singleSelected = false;
  path;
  topResultsLimit = null;
  assessmentTypes: string[] = [];
  noSaveProfile = false;
  breadcrumbs = 'annuity-results';
  annuitySelected = false;
  rights = this.ss.get('rights');
  noAnnuitiesMessage =
    "There are no appropriate products on your firm's product shelf for this client.";
  dynamicMessage;
  session: string = this.ss.get('session');
  org: string;
  existingOrderId = false;
  feesLoading = false;
  iwButtonText = 'Select Investments';
  types = [];
  unsubscribe: Subject<any> = new Subject();
  productSearch: '';
  filteredProducts = [];
  filteredProductsSubmitted = false;
  moduleNotes = {
    required: false,
    prompt: '',
    include: false,
    note: '',
  };

  constructor(
    public dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private route: Router,
    private ss: SessionStorageService,
    private tes: TagsEvaluationService,
    private aws: AnnuityWizardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    public unfFlowSvc: UnifiedFlowService,
    private location: Location
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.path = this.environment.assets ? this.environment.assets : '';

    let globals = {};

    this.rbs
      .getGlobals(null, null, 'aw')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(x => {
        globals = x.results;
        if (globals['GlobalsGroup']['UnitNotesSettings']) {
          this.moduleNotes.include =
            globals['GlobalsGroup'].UnitNotesSettings.IncludeSingleAWNote;
          this.moduleNotes.required =
            globals['GlobalsGroup'].UnitNotesSettings.SingleAWNoteRequired;
          this.moduleNotes.prompt =
            globals['GlobalsGroup'].UnitNotesSettings.SingleAWNotePrompt;
        }
        this.setGlobalParams(globals);
      });

    this.params.params.subscribe(params => {
      this.id = params.id;
      this.isExchange = params.exc == 'true';
    });

    this.breadcrumbs = this.noSaveProfile
      ? 'no-save-annuity-results'
      : this.breadcrumbs;
  }

  ngAfterViewInit() {
    this.noSaveProfile = this.ss.get('rights')?.includes('ReadOnly');
    const existing = this.ss.get('noSave');
    if (this.noSaveProfile && existing) {
      this.getLocalProfileResults(existing.profile);
    } else {
      setTimeout(() => {
        this.getResults(this.id);
      }, 750);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setGlobalParams(glbls) {
    this.scoring = glbls['scoring'];
    this.tableList = glbls['tables'];
    this.vaTable = this.tableList?.find(
      x => typeof x.bridgeType == 'string' && x.bridgeType.toLowerCase() == 'va'
    );
    this.iaTable = this.tableList?.find(
      x => typeof x.bridgeType == 'string' && x.bridgeType.toLowerCase() == 'ia'
    );
    this.faTable = this.tableList?.find(
      x => typeof x.bridgeType == 'string' && x.bridgeType.toLowerCase() == 'fa'
    );
    this.ioTable = this.tableList?.find(
      x => typeof x.bridgeType == 'string' && x.bridgeType.toLowerCase() == 'io'
    );
    this.viaTable = this.tableList?.find(
      x =>
        typeof x.bridgeType == 'string' && x.bridgeType.toLowerCase() == 'via'
    );
    this.io_idxTable = this.tableList?.find(
      x =>
        typeof x.bridgeType == 'string' &&
        x.bridgeType.toLowerCase() == 'io_idx'
    );
    this.fa2Table = this.tableList?.find(
      x => x.bridgeType.toLowerCase() == 'fa2'
    );
    this.horzTable1 = this.tableList?.find(x => x.id == 'horzcompare1');
    this.horzTable2 = this.tableList?.find(x => x.id == 'horzcompare2');

    this.externalLink = glbls['standardPostBack'] || null;
    this.externalAudit = glbls['dynamicPostBack'] || null;
    this.crossApp = glbls['crossApp'] || null;
    this.iwButtonText = glbls['IWButtonText']
      ? glbls['IWButtonText']
      : this.iwButtonText;
  }

  expand(event, annuity) {
    const el = this.closestByClass(event.target, 'annuity');
    el.classList.toggle('expanded');

    if (
      (annuity.BridgeType.toLowerCase() == 'va' ||
        annuity.BridgeType.toLowerCase() == 'iova' ||
        annuity.BridgeType.toLowerCase() == 'io' ||
        annuity.BridgeType.toLowerCase() == 'via') &&
      !annuity.vaFundFees
    ) {
      this.feesLoading = true;
      this.aws
        .vaFundFees(annuity.ContractCode, annuity.Cusip)
        .subscribe(fees => {
          annuity.vaFundFees = fees.results;
          this.feesLoading = false;
        });
    }

    if (
      annuity.outline.sections.ShortNameHeader.toLowerCase() == 'fa' &&
      !annuity.faFundFees
    ) {
      this.feesLoading = true;
      this.aws.faFundFees(annuity.name).subscribe(fees => {
        annuity.faFundFees = fees.results;
        this.feesLoading = false;
      });
    }
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }

  getLocalProfileResults(data?) {
    this.setLoading(true);
    let profile;
    if (data) {
      profile = data;
    } else {
      profile = this.ss.get('noSave');
    }

    this.aws.processAnnuitiesGroupProfile(profile).subscribe(data => {
      this.processAnnuityData(data);
      this.setLoading(false);
    });
  }

  getResults(profile) {
    const save = this.unfFlowSvc.getSaving();
    this.unfFlowSvc.setLoading(true);
    if (save) {
      setTimeout(() => {
        this.getResults(this.id);
      }, 250);
    } else {
      this.setLoading(true);
      this.aws.processAnnuities(profile).subscribe(data => {
        if (data.AnnuityWizard && data.AnnuityWizard.Note) {
          this.moduleNotes.note = data.AnnuityWizard.Note;
        }
        this.processAnnuityData(data);
      });
    }
  }

  processAnnuityData(data) {
    this.setLoading(true);
    const annData = data;
    const limit = annData.UnitScoring;
    limit['BridgeLimit_IA'] = limit['BridgeLimit_IDX'];
    this.topResultsLimit = annData?.UnitScoring?.BridgeLimit_ALL;
    this.clientName =
      annData.ClientPerson.FirstName + ' ' + annData.ClientPerson.LastName;
    const count = {};
    Object.keys(limit).forEach(x => {
      let type = x.replace('BridgeLimit_', '');
      if (type === 'IDX') {
        type = 'IA';
      }
      count[type] = 0;
    });
    this.existingOrderId = annData.AWProfilingResults
      ? annData.AWProfilingResults &&
        annData.AWProfilingResults.OrderEntryID &&
        annData.AWProfilingResults.OrderEntryID.length > 0
      : false;

    this.annuities = annData.annuities.filter(x => x.BridgeType != 'END');
    this.unfFlowSvc.setNoteValidity({ app: 'aw', valid: true });
    this.annuities.forEach(x => {
      this.filteredProducts.push(x.name);
      x.negativeReason = false;
      if (this.topResultsLimit) {
        x.hidden = x.AlwaysShow != 'Y' && count['ALL'] > this.topResultsLimit;
      } else {
        x.hidden =
          (x.AlwaysShow !== 'Y' || !x.AlwaysShow) &&
          count[x.BridgeType] > limit[`BridgeLimit_${x.BridgeType}`];
      }
      switch (x.outline.sections.ShortNameHeader) {
        case 'FIA':
          x.indicatorClass = 'fia';
          break;
        case 'VA':
          x.indicatorClass = 'va';
          break;
        case 'DIA':
          x.indicatorClass = 'dia';
          break;
        case 'SPIA':
          x.indicatorClass = 'dia';
          break;
        case 'VIA':
          x.indicatorClass = 'via';
          break;
        case 'FA':
          x.indicatorClass = 'faa';
          break;
      }

      x.selectedClass = x.SelectedBridge == 'Yes' ? 'selected' : '';
      x.exchangeClass =
        x.name.toLowerCase() == 'annuityproductexchange' ||
        x.name.toLowerCase() == 'annuityproductexchangeiova'
          ? 'exchange'
          : '';

      if (x.assessmenttype === 'validate') {
        x.expandedClass = 'expanded';

        this.selectAnnuity(null, x.name, null, true, false);
        this.compare(null, x.name, 'selected');

        if (
          x.outline.sections.ShortNameHeader.toLowerCase() == 'va' &&
          !x.vaFundFees
        ) {
          this.aws.vaFundFees(x.ContractCode, x.Cusip).subscribe(fees => {
            x.vaFundFees = fees.results;
          });
        }

        if (
          x.outline.sections.ShortNameHeader.toLowerCase() == 'fa' &&
          !x.faFundFees
        ) {
          this.aws.faFundFees(x.name).subscribe(fees => {
            x.faFundFees = fees.results;
          });
        }
        x.SelectedBridge = 'Yes';
      }

      if (x.assessmenttype === 'exchange') {
        this.isExchange = this.rights.includes('ExchangeReport');
      }

      if (this.topResultsLimit) {
        count['ALL']++;
      } else if (x.assessmenttype != 'validate') {
        count[x.BridgeType]++;
      }

      if (x.trueRules) {
        x.trueRules.forEach(e => {
          e.explanation = this.tes.evalTags(e.explanation);
          e.headline = this.tes.evalTags(e.headline);
          x.negativeReason =
            e.reasonType == 'Negative' ? true : x.negativeReason;
        });
      }

      const requiredNotes =
        (x.SelectedBridge == 'Yes' ||
          (x.SelectedBridge == 'No' && x.assessmenttype == 'exchange')) &&
        (x.vars.CurrentBridgeNote.Required === 2 ||
          x.vars.CurrentBridgeNote.Required === '2' ||
          ((x.vars.CurrentBridgeNote.Required === 3 ||
            x.vars.CurrentBridgeNote.Required === '3') &&
            x.negativeReason));

      const notesEntered =
        x.vars.CurrentBridgeNote && x.vars.CurrentBridgeNote.NoteText
          ? x.vars.CurrentBridgeNote.NoteText.length > 0
          : false;

      x.notesValid =
        (requiredNotes && notesEntered) || !requiredNotes ? true : false;

      if (this.moduleNotes.include && !this.moduleNotes.note) {
        this.unfFlowSvc.setNoteValidity({ app: 'aw', valid: false });
      } else if (!x.notesValid && !this.moduleNotes.include) {
        this.unfFlowSvc.setNoteValidity({ app: 'aw', valid: false });
      }

      if (x.SelectedBridge && x.SelectedBridge.toLowerCase() == 'yes') {
        this.model['aw-' + x.name] = true;
        this.singleSelected = Object.keys(this.model).length == 1;
        this.annuitySelected = true;
        this.compare(null, x.name, 'selected');
      }

      if (x.isCompare === 'True' || x.isCompare === true) {
        this.compareList.push(x.name);
        this.compareChecked[x.name] = true;
      }

      if (x.ComplianceChart && x.ComplianceChart.length > 0) {
        x.ComplianceChart = JSON.parse(x.ComplianceChart);
      }

      if (
        x.outline.sections.DDW_Purposes &&
        x.outline.sections.DDW_Purposes.length > 1
      ) {
        x.outline.sections.DDW_Purposes =
          x.outline.sections.DDW_Purposes.split('|');
        x.outline.sections.DDW_Edge = x.outline.sections.DDW_Edge.split('|');
        x.outline.sections.DDW_Protection =
          x.outline.sections.DDW_Protection.split('|');
        x.outline.sections.DDW_Upside =
          x.outline.sections.DDW_Upside.split('|');
      }
      if (
        x.assessmenttype &&
        !this.assessmentTypes.includes(x.assessmenttype)
      ) {
        this.types.push(x.assessmenttype);
      }
    });
    this.hiddenItems = this.annuities.find(x => {
      return x.hidden === true || x.hidden === 'true';
    });

    const fullSet = ['validate', 'exchange', 'select'];

    this.assessmentTypes = fullSet.filter(x => this.types.includes(x));

    if (this.annuities.filter(x => x.SelectedBridge === 'Yes').length > 0) {
      this.assessmentTypes.splice(0, 0, 'selectedProduct');
    }
    this.dynamicMessage =
      this.model && Object.keys(this.model).length > 1
        ? 'More than one annuity is selected. Please unselect all but one annuity.'
        : 'Please select an annuity to activate this button';
    this.setLoading(false);
  }

  compare(e, fund, selected = 'no') {
    if (selected.toLowerCase() !== 'yes') {
      const inList = this.compareList.filter(x => {
        return x == fund;
      });

      if (inList && inList.length && selected !== 'selected') {
        this.compareList = this.compareList.filter(x => {
          return x != fund;
        });
        this.compareList = [...new Set(this.compareList)];
        if (!e) {
          this.compareChecked[fund] = false;
        } else if (!this.noSaveProfile) {
          this.rbs
            .saveProfile(
              this.id,
              {
                'AnnuityWizardCompare.CompareID': this.compareList,
              },
              true
            )
            .subscribe();
        }
      } else {
        this.compareList.push(fund);
        this.compareList = [...new Set(this.compareList)];
        if (!e) {
          this.compareChecked[fund] = true;
        } else if (!this.noSaveProfile) {
          this.rbs
            .saveProfile(
              this.id,
              {
                'AnnuityWizardCompare.CompareID': this.compareList,
              },
              false
            )
            .subscribe();
        }
      }
    }
  }

  compareAll() {
    this.annuities.forEach(x => {
      this.compareList.push(x.name);
      this.compareChecked[x.name] = true;
    });
  }

  viewComparison() {
    const compareFunds = [],
      tables = [];

    this.compareList.forEach(x => {
      const fund = cloneDeep(this.annuities.find(y => y.name == x)),
        annType = fund.BridgeType.toLowerCase();

      compareFunds.push(fund);
      if (this.noSaveProfile || this.rights.includes('HorizontalCompare')) {
        if (!tables.find(x => x.type == 'horz')) {
          if (this.horzTable1['bridgeType'].includes(annType.toLowerCase())) {
            tables.push(this.horzTable1);
          } else if (
            this.horzTable2['bridgeType'].includes(annType.toLowerCase())
          ) {
            tables.push(this.horzTable2);
          }
        }
      } else {
        switch (annType.toLowerCase()) {
          case 'ia':
            if (!tables.includes(this.iaTable)) {
              tables.push(this.iaTable);
            }
            break;
          case 'fa':
            if (!tables.includes(this.faTable)) {
              tables.push(this.faTable);
            }
            break;
          case 'va':
            if (!tables.includes(this.vaTable)) {
              tables.push(this.vaTable);
            }
            break;
          case 'via':
            if (!tables.includes(this.viaTable)) {
              tables.push(this.viaTable);
            }
            break;
          case 'io':
            if (!tables.includes(this.ioTable)) {
              tables.push(this.ioTable);
            }
            break;
          case 'io_idx':
            if (!tables.includes(this.io_idxTable)) {
              tables.push(this.io_idxTable);
            }
            break;
          case 'fa2':
            if (!tables.includes(this.fa2Table)) {
              tables.push(this.fa2Table);
            }
            break;
        }
      }
    });

    compareFunds.forEach(x => {
      x.id = {};
      x.id.ShortName = x.outline.sections.ShortName;
    });

    const compareData = {
      tables: tables,
      funds: compareFunds.reverse(),
      header: 'Compare Annuities',
    };

    const dialogRef = this.dialog.open(CompareDialogComponent, {
      panelClass: 'compare-dialog',
      width: '95vw',
      maxHeight: '95vh',
      maxWidth: '95vw',
      data: compareData,
      autoFocus: false,
    });
    dialogRef.afterClosed();
  }

  selectAnnuity(ev, name, showDialog, clearSelected?, process = true) {
    this.productSearch = '';
    this.filteredProducts = [];
    if (showDialog) {
      const dialogRef = this.dialog.open(ModelDialogComponent, {
        panelClass: 'model-dialog',
        data: {
          commentRequired: false,
          type: 'annuity',
          headline: 'Select Annuity',
          placeholder:
            'Please enter any notes you have pertaining to this annuity',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data != 'cancel') {
          this.setLoading(true);
          this.rbs
            .selectAnnuity(this.id, result.data, name, clearSelected)
            .subscribe(() => {
              this.annuities.find(x => x.name == name).SelectedBridge = 'Yes';
              this.model['aw-' + name] = true;
              this.singleSelected = Object.keys(this.model).length == 1;
              this.compare(null, name, 'selected');
              this.annuitySelected = true;
              this.aws.processAnnuities(this.id).subscribe(data => {
                if (process) {
                  this.processAnnuityData(data);
                }
                console.warn('Profile Updated on Select!');
                // this.setLoading(false);
              });
              this.dynamicMessage =
                this.model && Object.keys(this.model).length > 1
                  ? 'More than one annuity is selected. Please unselect all but one annuity.'
                  : 'Please select an annuity to activate this button';
            });
        } else {
          this.setLoading(false);
        }
      });
    } else {
      this.setLoading(true);
      this.rbs
        .selectAnnuity(this.id, null, name, clearSelected)
        .subscribe(() => {
          this.annuities.find(x => x.name == name).SelectedBridge = 'Yes';
          this.model['aw-' + name] = true;
          this.singleSelected = Object.keys(this.model).length == 1;
          // this.compare(null, name);
          // this.setLoading(false);
          this.annuitySelected = true;
          this.aws.processAnnuities(this.id).subscribe(data => {
            if (process) {
              this.processAnnuityData(data);
            }
            console.warn('Profile Updated on Select!');
            // this.setLoading(false);
          });
          this.dynamicMessage =
            this.model && Object.keys(this.model).length > 1
              ? 'More than one annuity is selected. Please unselect all but one annuity.'
              : 'Please select an annuity to activate this button';
        });
    }
  }

  deselectAnnuity(ev, name, showDialog) {
    if (showDialog) {
      const dialogRef = this.dialog.open(ModelDialogComponent, {
        panelClass: 'model-dialog',
        data: {
          commentRequired: false,
          type: 'annuity',
          headline: 'Remove Annuity',
          placeholder:
            'Please enter any notes you have pertaining to this annuity',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data != 'cancel') {
          this.setLoading(true);
          this.rbs.deselectAnnuity(this.id, result.data, name).subscribe(() => {
            this.annuities.find(x => x.name == name).SelectedBridge = 'No';
            delete this.model['aw-' + name];
            this.singleSelected = Object.keys(this.model).length == 1;
            this.compare(null, name);

            this.aws.processAnnuities(this.id).subscribe(data => {
              this.processAnnuityData(data);
              console.warn('Profile Updated on Deselect!');
              // this.setLoading(false);
            });
            this.dynamicMessage =
              this.model && Object.keys(this.model).length > 1
                ? 'More than one annuity is selected. Please unselect all but one annuity.'
                : 'Please select an annuity to activate this button';
          });
        } else {
          this.setLoading(false);
        }
        this.annuitySelected =
          this.annuities.filter(x => x.SelectedBridge == 'Yes').length > 1;
      });
    } else {
      this.setLoading(true);
      this.rbs.deselectAnnuity(this.id, null, name).subscribe(() => {
        this.annuities.find(x => x.name == name).SelectedBridge = 'No';
        delete this.model['aw-' + name];
        this.singleSelected = Object.keys(this.model).length == 1;
        this.compare(null, name);
        this.aws.processAnnuities(this.id).subscribe(data => {
          this.processAnnuityData(data);
          console.warn('Profile Updated on Deselect!');
          this.setLoading(false);
        });
        this.dynamicMessage =
          this.model && Object.keys(this.model).length > 1
            ? 'More than one annuity is selected. Please unselect all but one annuity.'
            : 'Please select an annuity to activate this button';
      });
      this.annuitySelected =
        this.annuities.filter(x => x.SelectedBridge == 'Yes').length > 1;
    }
  }

  handleReportEvent(ev) {
    switch (ev) {
      case 'standard':
        this.report();
        break;
      case 'exchange':
        this.exchangeReport();
        break;
      case 'client':
        this.validationReport();
        break;
      default:
        this.report();
        break;
    }
  }

  report() {
    if (
      this.assessmentTypes.includes('validate') ||
      (this.noSaveProfile && this.compareList.length > 0) ||
      (!this.noSaveProfile && this.annuitySelected)
    ) {
      if (this.noSaveProfile) {
        this.noSaveReport();
      } else {
        let compared = this.compareList.join('|');

        if (this.assessmentTypes.includes('validate')) {
          const reasonableList = this.annuities.map(x => {
            if (x.assessmenttype == 'select') {
              return x.name;
            }
          });
          compared = reasonableList.join('|');
        }

        const dialogRef = this.dialog.open(ReportDialogComponent, {
          panelClass: 'report-dialog',
          data: {
            hideVaDetails: false,
            hideDetails: !this.rights.includes(
              'ExcludeAlternativesReportPages'
            ),
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result && result != 'cancel') {
            this.setLoading(true);
            const iosSafari =
              this.pltfrm.IOS ||
              (navigator.platform === 'MacIntel' &&
                navigator.maxTouchPoints > 1);

            this.aws
              .getReport(
                this.id,
                compared,
                null,
                result['vaDetails'],
                false,
                !result['details']
              )
              .subscribe(data => {
                const date = new Date();
                const filename =
                  result.filename && result.filename != null
                    ? result.filename + '.pdf'
                    : 'AnnuityWizard-' +
                      date.getMonth() +
                      date.getDay() +
                      date.getFullYear() +
                      '.pdf';

                let fileData;
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                  fileData = reader.result;
                  saveAs(fileData, filename, {
                    proxyURL: `${this.baseUrl}/util/proxy`,
                    proxyTarget: '_self',
                    forceProxy: iosSafari,
                  });
                  this.setLoading(false);
                };
              });
          }
        });
      }
    }
  }

  noSaveReport() {
    let compared;
    if (this.compareList.length > 0) {
      compared = this.compareList.join('|');
    } else {
      const compList = this.annuities.map((x, i) => {
        if (i < 3) {
          return x.name;
        }
      });
      compared = compList.join('|');
    }

    if (this.assessmentTypes.includes('validate')) {
      const reasonableList = this.annuities.map(x => {
        if (x.assessmenttype == 'select') {
          return x.name;
        }
      });
      compared = reasonableList.join('|');
    }

    const dialogRef = this.dialog.open(ReportDialogComponent, {
      panelClass: 'report-dialog',
      data: {
        hideVaDetails: false,
        hideDetails: !this.rights.includes('ExcludeAlternativesReportPages'),
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.setLoading(true);
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        const profile = this.ss.get('noSave');
        this.aws
          .getNoSaveReport(
            profile.profile,
            compared,
            result['vaDetails'],
            false,
            !result['details']
          )
          .subscribe(data => {
            const date = new Date();
            const filename =
              result.filename && result.filename != null
                ? result.filename + '.pdf'
                : 'AnnuityWizard-' +
                  date.getMonth() +
                  date.getDay() +
                  date.getFullYear() +
                  '.pdf';

            let fileData;
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = () => {
              fileData = reader.result;
              saveAs(fileData, filename, {
                proxyURL: `${this.baseUrl}/util/proxy`,
                proxyTarget: '_self',
                forceProxy: iosSafari,
              });
              this.setLoading(false);
            };
          });
      }
    });
  }

  validationReport() {
    if (
      this.assessmentTypes.includes('validate')
        ? true
        : this.noSaveProfile
          ? this.compareList.length > 0
          : this.annuitySelected
    ) {
      let compared = this.compareList.join('|');

      if (this.assessmentTypes.includes('validate')) {
        const reasonableList = this.annuities.map(x => {
          if (x.assessmenttype == 'select') {
            return x.name;
          }
        });
        compared = reasonableList.join('|');
      }

      const dialogRef = this.dialog.open(ReportDialogComponent, {
        panelClass: 'report-dialog',
        data: {
          hideVaDetails: false,
          hideDetails: !this.rights.includes('ExcludeAlternativesReportPages'),
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result != 'cancel') {
          this.setLoading(true);
          const iosSafari =
            this.pltfrm.IOS ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

          this.aws
            .getReport(
              this.id,
              compared,
              null,
              result['vaDetails'],
              true,
              !result['details']
            )
            .subscribe(data => {
              const date = new Date();
              const filename =
                result.filename && result.filename != null
                  ? result.filename + '.pdf'
                  : 'AnnuityWizard-' +
                    date.getMonth() +
                    date.getDay() +
                    date.getFullYear() +
                    '.pdf';

              let fileData;
              const reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = () => {
                fileData = reader.result;
                saveAs(fileData, filename, {
                  proxyURL: `${this.baseUrl}/util/proxy`,
                  proxyTarget: '_self',
                  forceProxy: iosSafari,
                });
                this.setLoading(false);
              };
            });
        }
      });
    }
  }

  exchangeReport(silent = false) {
    if (
      this.noSaveProfile ? this.compareList.length > 0 : this.annuitySelected
    ) {
      if (this.annuitySelected) {
        const compared = this.compareList.join('|');

        const dialogRef = this.dialog.open(ReportDialogComponent, {
          panelClass: 'report-dialog',
          data: { hideDetails: true, hideVaDetails: true },
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result && result != 'cancel') {
            this.setLoading(true);
            const iosSafari =
              this.pltfrm.IOS ||
              (navigator.platform === 'MacIntel' &&
                navigator.maxTouchPoints > 1);

            this.rbs
              .getExchangeReport(this.id, compared, silent)
              .subscribe(data => {
                const date = new Date();
                const filename =
                  result.filename && result.filename != null
                    ? result.filename + '.pdf'
                    : 'AnnuityWizard-' +
                      date.getMonth() +
                      date.getDay() +
                      date.getFullYear() +
                      '.pdf';

                let fileData;
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                  fileData = reader.result;
                  saveAs(fileData, filename, {
                    proxyURL: `${this.baseUrl}/util/proxy`,
                    proxyTarget: '_self',
                    forceProxy: iosSafari,
                  });
                  this.setLoading(false);
                };
              });
          }
        });
      }
    }
  }

  dlFactsheet(contract, cusip, date, name, type, id) {
    const fmtdDate = new Date(date);

    if (type.toLowerCase() == 'fa2') {
      let productID = id;
      if (productID.includes('FA')) {
        productID = productID.replace('FA', '');
      }
      this.setLoading(true);
      this.downloadFactsheet.emit(true);
      this.aws.getFaFactSheet(productID).subscribe(data => {
        this.setLoading(true);
        this.downloadFactsheet.emit(true);
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        const filename = name + '-factsheet.pdf';

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          this.setLoading(false);
          this.downloadFactsheet.emit(false);
        };
      });
    } else {
      this.setLoading(true);
      this.downloadFactsheet.emit(true);
      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

      this.rbs
        .getAnnuityFactSheet(
          contract,
          cusip,
          fmtdDate.toISOString().substring(0, 10)
        )
        .subscribe(data => {
          const filename = name + '-factsheet.pdf';

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
            this.setLoading(false);
            this.downloadFactsheet.emit(false);
          };
        });
    }
  }

  showMore() {
    this.productSearch = '';
    this.resetFilteredProducts();
    if (!this.showAll) {
      this.showAll = true;
    } else {
      this.showAll = false;
    }
  }

  gotoPP() {
    const pb = this.crossApp.includePostBack || null;
    const silentPrint = this.crossApp.silentPrint || null;

    if (silentPrint && pb) {
      this.setLoading(true);
      this.rbs
        .getReport(this.id, this.compareList.join('|'), silentPrint)
        .subscribe(() => {
          this.rbs.postback(this.id).subscribe(() => {
            this.setLoading(false);
            this.route.navigate(['/pp/questionnaire/' + this.id]);
          });
        });
    } else if (pb && !silentPrint) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        this.setLoading(false);
        this.route.navigate(['/pp/questionnaire/' + this.id]);
      });
    } else if (silentPrint && !pb) {
      this.setLoading(true);
      this.rbs
        .getReport(this.id, this.compareList.join('|'), silentPrint)
        .subscribe(() => {
          this.setLoading(false);
          this.route.navigate(['/pp/questionnaire/' + this.id]);
        });
    } else {
      this.route.navigate(['/pp/questionnaire/' + this.id]);
    }
  }

  goCrossApp(app) {
    const pb = this.crossApp.includePostBack || null;
    const silentPrint = this.crossApp.silentPrint || null;

    if (silentPrint && pb) {
      this.setLoading(true);
      this.rbs.getReport(this.id, null, silentPrint).subscribe(z => {
        this.rbs.postback(this.id).subscribe(() => {
          this.setLoading(false);
          this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
        });
      });
    } else if (pb && !silentPrint) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        this.setLoading(false);
        this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
      });
    } else if (silentPrint && !pb) {
      this.setLoading(true);
      this.rbs.getReport(this.id, null, silentPrint).subscribe(() => {
        this.setLoading(false);
        this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
      });
    } else {
      this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
    }
  }

  identify(index, item) {
    return item.name;
  }

  externalAction() {
    const silentPrint = this.externalLink.silentPrint,
      pb = this.externalLink.includePostBack || null,
      includePID = this.externalLink.includePID,
      newTab = this.externalLink.RedirectSameTab ? '_self' : '_blank',
      silentXchng = this.externalLink.silentPrintExchange,
      silentClientRpt = this.externalLink.silentPrintClientReport,
      compared = this.compareList.join('|');

    let link = this.externalLink.link || null;
    const outsideLink = link.includes('http');

    if (includePID) {
      link += this.id;
    }
    if (this.isExchange && silentXchng && silentClientRpt) {
      this.rbs.getExchangeReport(this.id, compared, true).subscribe(() => {
        this.rbs
          .getReport(this.id, this.compareList.join('|'), true, null, true)
          .subscribe(z => {
            this.rbs.postback(this.id).subscribe(() => {
              if (outsideLink) {
                window.open(link, newTab);
              } else {
                this.route.navigateByUrl(link);
              }
              this.setLoading(false);
            });
          });
      });
    } else if (this.isExchange && silentXchng && !silentClientRpt) {
      this.rbs.getExchangeReport(this.id, compared, true).subscribe(() => {
        this.rbs.postback(this.id).subscribe(() => {
          if (outsideLink) {
            window.open(link, newTab);
          } else {
            this.route.navigateByUrl(link);
          }
          this.setLoading(false);
        });
      });
    } else if (silentPrint && pb) {
      this.setLoading(true);
      this.rbs
        .getReport(this.id, this.compareList.join('|'), silentPrint)
        .subscribe(() => {
          this.rbs.postback(this.id).subscribe(() => {
            if (outsideLink) {
              window.open(link, newTab);
            } else {
              this.route.navigateByUrl(link);
            }
            this.setLoading(false);
          });
        });
    } else if (pb) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        if (outsideLink) {
          window.open(link, newTab);
        } else {
          this.route.navigateByUrl(link);
        }
        this.setLoading(false);
      });
    } else if (silentPrint) {
      this.setLoading(true);
      this.rbs
        .getReport(this.id, this.compareList.join('|'), silentPrint)
        .subscribe(() => {
          if (outsideLink) {
            window.open(link, newTab);
          } else {
            this.route.navigateByUrl(link);
          }
          this.setLoading(false);
        });
    } else {
      if (outsideLink) {
        window.open(link, newTab);
      } else {
        this.route.navigateByUrl(link);
      }
      this.setLoading(false);
    }
  }

  externalAuditAction(ignoreWarning?) {
    const silentPrint = this.externalAudit.silentPrint,
      newTab = this.externalAudit.RedirectSameTab ? '_self' : '_blank',
      silentXchng = this.externalAudit.silentPrintExchange,
      silentClientRpt = this.externalAudit.silentPrintClientReport,
      silentPrintPp = this.externalAudit.silentPrintPP,
      compared = this.compareList.join('|'),
      customReportSolution =
        this.externalAudit.postbackSolution &&
        this.externalAudit.postbackSolution.length > 0
          ? this.externalAudit.postbackSolution
          : null;

    this.setLoading(true);

    let externalModal;
    try {
      externalModal = JSON.parse(this.externalAudit.postBackModalName);
    } catch (error) {
      console.error('Not valid json for modal: ', error);
    }

    if (externalModal && externalModal.ModalName.toLowerCase() === 'pershing') {
      let runModal = false;

      this.rbs.getPershingGroupProfile(this.id).subscribe(res => {
        runModal = res.PershingModal.AskForRRCode;

        if (runModal) {
          let dialogRef = this.dialog.open(CustomReportModalComponent, {
            panelClass: 'warn-dialog',
            minWidth: '50vw',
            data: {
              profile: this.id,
              name: externalModal.ModalName,
              headline: externalModal.Title,
              content: externalModal.Body,
              accounts: res.PershingModalAccountType,
              confirm: 'Submit',
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            dialogRef = null;
            if (result != 'cancel') {
              this.rbs
                .extPostbackData(this.id, null, customReportSolution)
                .subscribe(x => {
                  if (x && x.results != null && x.results.length > 0) {
                    const link = x.results;
                    this.setLoading(false);
                    window.open(link, newTab);
                  } else {
                    let dialogRef = this.dialog.open(WarnDialogComponent, {
                      panelClass: 'warn-dialog',
                      data: {
                        headline: 'Warning',
                        content:
                          'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                        confirm: 'OK',
                        hideCancel: true,
                      },
                    });
                    dialogRef.afterClosed().subscribe(() => {
                      dialogRef = null;
                      this.setLoading(false);
                    });
                  }
                });
            } else {
              this.setLoading(false);
              console.warn('User opted out of submission');
            }
          });
        } else {
          this.rbs
            .extPostbackData(this.id, null, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;
                this.setLoading(false);
                window.open(link, newTab);
              } else {
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.setLoading(false);
                });
              }
            });
        }
      });
    } else if (
      externalModal &&
      externalModal.ModalName.toLowerCase() != 'pershing'
    ) {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          name: externalModal.ModalName,
          headline: externalModal.Title,
          content: externalModal.Body,
          confirm: 'Submit',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        if (result != 'cancel') {
          this.rbs
            .extPostbackData(this.id, result, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;
                this.setLoading(false);
                window.open(link, newTab);
              } else {
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.setLoading(false);
                });
              }
            });
        } else {
          this.setLoading(false);
          console.warn('User opted out of submission');
        }
      });
    } else if (this.existingOrderId && !ignoreWarning) {
      const dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            'This case has been submitted to order entry. If sent again, it will create a new record in the order entry system.',
          confirm: 'OK',
          hideCancel: false,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'continue') {
          this.externalAuditAction(true);
        } else {
          this.setLoading(false);
        }
      });
    } else {
      if (
        this.externalAudit.postBackModalName &&
        this.externalAudit.postBackModalName.length > 0
      ) {
        let dialogRef = this.dialog.open(ConsolidatedReportModalComponent, {
          panelClass: 'consolidated-report',
          data: { profile: this.id, silentPrint: true },
          minWidth: '80vw',
          minHeight: '95vh',
          maxHeight: '95vh',
        });

        dialogRef.afterClosed().subscribe(result => {
          dialogRef = null;
          this.rbs
            .extPostbackData(this.id, result, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null) {
                const link = x.results;
                this.setLoading(false);
                if (!x.results && x.url) {
                  this.samlRedirect(x);
                } else {
                  window.open(link, newTab);
                }
              } else {
                const message = x.errorMessage
                  ? x.errorMessage
                  : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content: message,
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.setLoading(false);
                });
              }
            });
        });
      } else {
        if (silentPrintPp) {
          this.rbs.silentPrintPpReport(this.id);
        }

        if (this.isExchange && silentXchng && silentClientRpt) {
          this.rbs.getExchangeReport(this.id, compared, true).subscribe(() => {
            this.rbs
              .getReport(this.id, this.compareList.join('|'), true, null, true)
              .subscribe(() => {
                this.rbs
                  .extPostback(this.id, customReportSolution)
                  .subscribe(x => {
                    if (x.results != null) {
                      const link = x.results;
                      const outsideLink = link.includes('http');

                      if (outsideLink) {
                        window.open(link, newTab);
                      } else {
                        this.route.navigateByUrl(link);
                      }

                      this.setLoading(false);
                    } else if (!x.results && x.url) {
                      this.samlRedirect(x);
                    } else {
                      const message = x.errorMessage
                        ? x.errorMessage
                        : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

                      let dialogRef = this.dialog.open(WarnDialogComponent, {
                        panelClass: 'warn-dialog',
                        data: {
                          headline: 'Warning',
                          content: message,
                          confirm: 'OK',
                          hideCancel: true,
                        },
                      });
                      dialogRef.afterClosed().subscribe(() => {
                        dialogRef = null;
                        this.setLoading(false);
                      });
                    }
                  });
              });
          });
        } else if (this.isExchange && silentXchng && !silentClientRpt) {
          this.rbs.getExchangeReport(this.id, compared, true).subscribe(() => {
            this.rbs.extPostback(this.id, customReportSolution).subscribe(x => {
              if (x.results != null) {
                const link = x.results;
                const outsideLink = link.includes('http');

                if (outsideLink) {
                  window.open(link, newTab);
                } else {
                  this.route.navigateByUrl(link);
                }

                this.setLoading(false);
              } else if (!x.results && x.url) {
                this.samlRedirect(x);
              } else {
                const message = x.errorMessage
                  ? x.errorMessage
                  : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content: message,
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.setLoading(false);
                });
              }
            });
          });
        } else if (!silentPrint) {
          this.rbs.extPostback(this.id, customReportSolution).subscribe(x => {
            if (x.results != null) {
              const link = x.results;
              const outsideLink = link.includes('http');

              if (outsideLink) {
                window.open(link, newTab);
              } else {
                this.route.navigateByUrl(link);
              }

              this.setLoading(false);
            } else if (!x.results && x.url) {
              this.samlRedirect(x);
            } else {
              const message = x.errorMessage
                ? x.errorMessage
                : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

              let dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content: message,
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
                this.setLoading(false);
              });
            }
          });
        } else {
          this.rbs
            .getReport(this.id, this.compareList.join('|'), silentPrint)
            .subscribe(() => {
              this.rbs
                .extPostback(this.id, customReportSolution)
                .subscribe(x => {
                  if (x.results != null) {
                    const link = x.results;
                    const outsideLink = link.includes('http');

                    if (outsideLink) {
                      window.open(link, newTab);
                    } else {
                      this.route.navigateByUrl(link);
                    }

                    this.setLoading(false);
                  } else if (!x.results && x.url) {
                    this.samlRedirect(x);
                  } else {
                    const message = x.errorMessage
                      ? x.errorMessage
                      : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

                    let dialogRef = this.dialog.open(WarnDialogComponent, {
                      panelClass: 'warn-dialog',
                      data: {
                        headline: 'Warning',
                        content: message,
                        confirm: 'OK',
                        hideCancel: true,
                      },
                    });
                    dialogRef.afterClosed().subscribe(() => {
                      dialogRef = null;
                      this.setLoading(false);
                    });
                  }
                });
            });
        }
      }
    }
  }

  handleNotesValidation(ev, item?) {
    if (!this.moduleNotes.include) {
      item.notesValid = ev;
      this.unfFlowSvc.setNoteValidity({
        app: 'aw',
        valid: !(this.annuities.filter(x => !x.notesValid).length > 0),
      });
    } else {
      this.unfFlowSvc.setNoteValidity({
        app: 'aw',
        valid: ev,
      });
    }
  }

  updateModuleNote(ev) {
    this.moduleNotes.note = ev;
  }

  setLoading(state, internal = true) {
    this.unfFlowSvc.setLoading(state);
    if (internal) {
      this.loading = state;
    }
  }

  samlRedirect(data) {
    this.samlForm.nativeElement.action = data.url;
    this.samlForm.nativeElement['SAMLResponse'].value = data.SAMLResponse;
    this.samlForm.nativeElement.submit();
  }

  getVaFundFees(contract, cusip) {
    let fees;
    this.aws.vaFundFees(contract, cusip).subscribe(x => {
      fees = x;
    });
    return fees;
  }

  searchProducts() {
    this.showAll = false;
    const showAnnuities = [];
    this.annuities.map(annuity => {
      if (
        annuity.Carrier.toLowerCase().includes(
          this.productSearch.toLowerCase()
        ) ||
        annuity.DisplayName.toLowerCase().includes(
          this.productSearch.toLowerCase()
        )
      ) {
        showAnnuities.push(annuity.name);
      }
    });
    this.filteredProducts = showAnnuities;
    this.filteredProductsSubmitted = true;
  }

  clearProductSearch() {
    this.productSearch = '';
    this.resetFilteredProducts();
  }

  resetFilteredProducts() {
    this.filteredProductsSubmitted = false;
    this.filteredProducts = [];
    this.annuities.map(annuity => {
      this.filteredProducts.push(annuity.name);
    });
  }

  showProduct(product, grp) {
    if (
      this.filteredProductsSubmitted &&
      grp === 'exchange' &&
      product.assessmenttype === grp
    ) {
      return true;
    }

    return (
      ((!this.filteredProductsSubmitted &&
        (!product.hidden || (product.hidden && this.showAll)) &&
        product.assessmenttype === grp) ||
        (product.assessmenttype === 'validate' &&
          product.assessmenttype === grp) ||
        (grp === 'selectedProduct' &&
          product.SelectedBridge === 'Yes' &&
          !this.assessmentTypes?.includes('validate')) ||
        (grp === 'select' &&
          product.SelectedBridge === 'No' &&
          this.filteredProductsSubmitted &&
          this.filteredProducts.includes(product.name))) &&
      !(grp === 'select' && product.SelectedBridge === 'Yes')
    );
  }

  onLinkClick(ev, viewOption, cusip) {
    window.open(
      `${this.location.prepareExternalUrl(
        '/aw/product-shelf/'
      )}${viewOption}/${cusip}`,
      '_blank'
    );
  }
}
