import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { Chart } from 'angular-highcharts';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { ModelDialogComponent } from '../../model-dialog/model-dialog.component';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { SessionStorageService } from '../../services/session-storage.service';
import { CustomModelDialogComponent } from '../custom-model-dialog/custom-model-dialog.component';
import { EnvironmentService } from '../../services/environment.service';
import { UnifiedFlowService } from '../../unified-flow/unified.service';

@Component({
  selector: 'app-model-selection',
  templateUrl: './model-selection.component.html',
  styleUrls: ['./model-selection.component.scss'],
})
export class ModelSelectionComponent implements OnInit {
  @ViewChild(MatButtonToggleGroup, { static: false })
  modelButtonGroup: MatButtonToggleGroup;
  @Output() navigate = new EventEmitter();

  private environment;
  id: string;
  tableData: any;
  chartData;
  chartDetail;
  modelData = { name: '', desc: '' };
  rows = [];
  columns = [];
  bridges;
  selModel: string;
  currModel;
  recModel;
  upModel;
  downModel;
  customModel;
  upModelId;
  downModelId;
  customModelId;
  iwVars;
  clientName: string;
  clear = false;
  customValues = {};
  customAlloc = false;
  selectedFunds = false;
  path: string;
  loading = false;
  rights = [];
  updatingModel = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rbs: RightBridgeApiService,
    private tes: TagsEvaluationService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private ss: SessionStorageService,
    private iws: InvestmentWizardService,
    private envSvc: EnvironmentService,
    private unfFlowSvc: UnifiedFlowService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.path = this.environment.assets ? this.environment.assets : '';

    this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.setLoading(true);
    this.getModels(this.id);
    this.rights = this.ss.get('rights');
    this.customAlloc = this.rights?.includes('CustomAllocations');
  }

  expand(event) {
    event.target.parentElement.classList.toggle('expanded');
  }

  saveSelection() {
    const svg = this.chartData.ref
        ? this.chartData.ref.container.innerHTML
        : null,
      custom = this.customModel ? this.customModel.ModelID : null;

    this.unfFlowSvc.setSaving(true);

    if (this.selModel == custom) {
      let formatted = '';

      this.customModel.allocations.forEach((e, i) => {
        if (i > 0) {
          formatted +=
            '|' + (this.customValues[e.AssetClassID] / 100).toString();
        } else {
          formatted = (this.customValues[e.AssetClassID] / 100).toString();
        }
      });

      const data = {
        'InvestmentWizard.SelectedModel': this.selModel,
        'InvestmentWizard.SelectedModelImage': svg,
        'InvestmentWizard.UseCustomModel': true,
        'CustomModel.CustomPercent': formatted,
      };

      this.rbs.saveProfile(this.id, data).subscribe(res => {
        if (this.clear) {
          this.clearSelectedFunds();
        }
        this.unfFlowSvc.setSaving(false);
      });
    } else {
      const data = {
        'InvestmentWizard.SelectedModel': this.selModel,
        'InvestmentWizard.SelectedModelImage': svg,
        'InvestmentWizard.UseCustomModel': false,
      };
      this.rbs.saveProfile(this.id, data).subscribe(res => {
        if (this.clear) {
          this.clearSelectedFunds();
        }
        this.unfFlowSvc.setSaving(false);
      });
    }
  }

  getModels(profile): any {
    this.setLoading(true);
    this.iws.getModels(profile).subscribe(data => {
      let selectedModel = 'rec';

      this.bridges = data.bridges[0].bridges;
      this.clientName = `${data.ClientPerson.FirstName} ${data.ClientPerson.LastName}`;
      this.selectedFunds =
        data.SelectedFunds.length > 0 &&
        data.SelectedFunds[0].FundID.length > 0;

      this.currModel = { ...data.AllocationModelLookup };
      this.currModel.allocations = data.CurrentAllocationModel;

      this.recModel = { ...data.RecommendedModelLookup };
      this.recModel.allocations = data.RecommendedModel;

      this.upModel = { ...data.RecommendedModelUp1Lookup };
      this.upModel.allocations = data.RecommendedModelUp1;

      this.downModel = { ...data.RecommendedModelDown1Lookup };
      this.downModel.allocations = data.RecommendedModelDown1;

      this.customModel = { ...data.CustomModelLookup };
      this.customModel.allocations = data.CustomModel;

      if (this.currModel.ModelID == this.recModel.ModelID) {
        selectedModel = 'rec';
      } else if (this.currModel.ModelID == this.upModel.ModelID) {
        selectedModel = 'up';
      } else if (this.currModel.ModelID == this.downModel.ModelID) {
        selectedModel = 'down';
      } else if (
        this.customModel &&
        this.currModel.ModelID == this.customModel.ModelID
      ) {
        selectedModel = 'custom';
      } else {
        selectedModel = 'rec';
      }
      this.updateModel(selectedModel);
      this.setLoading(false);
    });
  }

  modelChangeReason() {
    const dialogRef = this.dialog.open(ModelDialogComponent, {
      panelClass: 'model-dialog',
      data: { type: 'model' },
    });
    const custom = this.customModel ? this.customModel.ModelID : null;

    dialogRef.afterClosed().subscribe(result => {
      if (result.data != 'cancel') {
        if (this.selModel == custom) {
          const svg = this.chartData.ref.container.children[0].outerHTML;
          let assetClassIds = this.customModel.filter(a => {
            return a.var.name == 'AssetClassID';
          });
          assetClassIds = assetClassIds[0].var.value.split('|');

          let formatted;

          assetClassIds.forEach((e, i) => {
            if (i > 0) {
              formatted += '|' + this.customValues[e] / 100;
            } else {
              formatted = this.customValues[e] / 100;
            }
          });

          const data = {
            'InvestmentWizard.SelectedModel': this.selModel,
            'InvestmentWizard.SelectedModelImage': svg,
            'InvestmentWizard.UseCustomModel': true,
            'CustomModel.CustomPercent': formatted,
          };
          this.rbs.saveProfile(this.id, data).subscribe(() => {
            if (this.clear) {
              this.clearSelectedFunds();
            }
          });
        } else {
          const svg = this.chartData.ref.container.children[0].outerHTML;
          const data = {
            'InvestmentWizard.SelectedModel': this.selModel,
            'InvestmentWizard.ModelChangeNote': result.data,
            'InvestmentWizard.SelectedModelImage': svg,
          };
          this.rbs.saveProfile(this.id, data).subscribe(() => {
            if (this.clear) {
              this.clearSelectedFunds();
            }
          });
        }
      } else {
        const svg = this.chartData.ref.container.children[0].outerHTML;
        const data = {
          'InvestmentWizard.SelectedModel': this.selModel,
          'InvestmentWizard.SelectedModelImage': svg,
          'InvestmentWizard.UseCustomModel': false,
        };

        this.rbs.saveProfile(this.id, data).subscribe(() => {
          if (this.clear) {
            this.clearSelectedFunds();
          }
        });
      }
    });
  }

  modelChangeWarn(prog, sel, curr) {
    const dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content:
          'If you choose to switch investment models all your previous fund selections will be cleared.',
        confirm: 'OK',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'continue') {
        this.clear = true;
        this.selModel = prog;
        this.modelButtonGroup.value = sel;
      } else {
        this.updateModel(curr);
      }
    });
  }

  clearSelectedFunds() {
    this.setLoading(true);
    this.rbs.selectFunds(this.id, null, true).subscribe(() => {
      this.rbs
        .saveProfile(this.id, {
          'InvestmentWizard.IsFinalized': false,
          'InvestmentWizard.FilteredFundFamilies': null,
        })
        .subscribe(x => {
          this.ss.remove('selectedFunds');
          this.setLoading(false);
        });
    });
  }

  updateModel(sel, customSelect?) {
    const save = this.unfFlowSvc.getSaving();

    if (save && this.updatingModel) {
      this.updatingModel = false;
      this.updateModel(sel, customSelect);
    } else {
      let model,
        newModel,
        finalData = [];

      const curr = this.currModel.ModelID,
        currButton = this.modelButtonGroup.value,
        assetClassColors = [];

      switch (sel) {
        case 'rec':
          model = this.recModel;
          newModel = model.ModelID;

          if (
            this.selModel != newModel &&
            curr != newModel &&
            this.selectedFunds
          ) {
            this.modelChangeWarn(newModel, sel, currButton);
          } else {
            this.modelButtonGroup.value = sel;
            this.selModel = newModel;
          }
          break;

        case 'up':
          model = this.upModel;
          newModel = model.ModelID;

          if (
            this.selModel != newModel &&
            curr != newModel &&
            this.selectedFunds
          ) {
            this.modelChangeWarn(newModel, sel, currButton);
          } else {
            this.modelButtonGroup.value = sel;
            this.selModel = newModel;
          }
          break;

        case 'down':
          model = this.downModel;
          newModel = model.ModelID;

          if (
            this.selModel != newModel &&
            curr != newModel &&
            this.selectedFunds
          ) {
            this.modelChangeWarn(newModel, sel, currButton);
          } else {
            this.modelButtonGroup.value = sel;
            this.selModel = newModel;
          }
          break;

        case 'custom':
          model = this.customModel;
          newModel = model.ModelID;

          if (
            this.selModel != newModel &&
            curr != newModel &&
            this.selectedFunds
          ) {
            this.modelButtonGroup.value = null;
            if (customSelect) {
              this.selectCustomModel(newModel, currButton);
            }
          } else if (this.selModel == newModel && curr == newModel) {
            this.modelButtonGroup.value = null;
            if (customSelect) {
              this.selectCustomModel(newModel, currButton);
            }
          } else {
            this.modelButtonGroup.value = sel;
            this.selModel = newModel;
            if (customSelect) {
              this.selectCustomModel(newModel, currButton);
            }
          }
          break;
      }

      finalData = model.allocations.map(d => {
        return {
          classId: d.AssetClassID,
          className: d.AssetClassName,
          percentage:
            sel == 'custom' ? +d.CustomPercent * 100 : +d.Percent * 100,
          percentageRaw: sel == 'custom' ? +d.CustomPercent : +d.Percent,
          color: d.AssetClassColor,
        };
      });

      this.chartDetail = finalData.map(d => {
        return { name: d.className, y: d.percentageRaw, color: d.color };
      });

      this.columns = [
        { name: 'Asset Class', prop: 'className' },
        { name: 'Proposed Allocation', prop: 'percentage' },
      ];

      this.rows = finalData.map(d => {
        delete d.classId;
        d.percentage = String(Math.round(d.percentage)) + '%';
        return d;
      });

      this.modelData['allocations'] = finalData;
      this.modelData['name'] = model.ModelName;
      this.modelData['desc'] = model.ModelDescription;

      if (!this.chartData) {
        this.chartData = new Chart({
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
            },
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: null,
          },
          plotOptions: {
            pie: {
              innerSize: 100,
              depth: 45,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
              },
            },
          },
          colors: assetClassColors,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: 'funds',
              innerSize: '40%',
              data: this.chartDetail,
              type: 'pie',
            },
          ],
        });
      } else {
        this.chartData.ref.series[0].setData(this.chartDetail);
      }

      this.saveSelection();
    }
  }

  selectCustomModel(model, curr) {
    const dialogRef = this.dialog.open(CustomModelDialogComponent, {
      panelClass: 'custom-dialog',
      data: { model: this.customModel },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.data && result.data != 'cancel') {
        this.clear = true;
        this.selModel = this.customModel ? this.customModel.ModelID : null;
        this.modelButtonGroup.value = null;
        this.customValues = result.data;
        Object.keys(result.data).forEach(x => {
          const updateClass = this.customModel.allocations.find(
            z => z.AssetClassID == x
          );
          updateClass.CustomPercent = result.data[x] / 100;
          updateClass.Percent = result.data[x] / 100;
        });
        this.updateModel('custom');
      } else {
        this.updateModel(curr);
      }
    });
  }

  evalTags(tag, lc?) {
    if (lc) {
      const lines = tag.split('[/br][/br]');
      let output = '';

      lines.forEach(x => {
        output += '<p>' + this.tes.evalTags(x) + '</p>';
      });
      return output;
    } else {
      return this.tes.evalTags(tag);
    }
  }

  evalIcon(score) {
    const evalu = score / 10;
    let val = 'neutral';

    if (evalu >= 65) {
      val = 'positive';
    } else if (evalu < 65 && evalu > 35) {
      val = 'neutral';
    } else if (evalu >= 35) {
      val = 'negative';
    }

    return './assets/' + this.path + val + '.png';
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }

  savingStatus() {
    return this.unfFlowSvc.getSaving();
  }
}
