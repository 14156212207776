import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent implements OnInit {
  form: FormGroup;
  headline: string;
  content: string;
  confirm: string;
  placeholder: string;
  noteData = { text: null, pinned: false, alert: false };

  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.headline = this.data.headline || 'Enter a New Note';
      this.content = this.data.content || '';
      this.placeholder = this.data.placeholder || '';
      this.confirm = this.data.confirm || 'Save';
    }
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    if (this.noteData.text) {
      this.dialogRef.close({ data: this.noteData });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }
}
