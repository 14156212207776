import { Component, Input } from '@angular/core';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';

@Component({
  selector: 'app-postback-thank-you',
  templateUrl: './postback-thank-you.component.html',
  styleUrls: ['./postback-thank-you.component.scss'],
})
export class PostbackThankYouComponent {
  @Input() context;

  thankYouProfile;

  constructor(private rbs: RightBridgeApiService) {}

  ngOnInit() {
    this.getGroupProfile();
  }

  getGroupProfile() {
    this.rbs.postGroupProfile('ThankYouPage', 'rb').subscribe(resp => {
      this.thankYouProfile = JSON.parse(resp.UnitInfo.ThankYouPage);
    });
  }
}
