import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { MaskService } from '../../services/mask.service';
import { states } from '../../lib/states';
import { DateTime } from 'luxon';
import { CustomFiltersService } from '../../services/custom-filters.service';

@Component({
  selector: 'app-grid-filters',
  templateUrl: './grid-filters.component.html',
  styleUrls: ['./grid-filters.component.scss'],
})
export class GridFiltersComponent implements OnInit, OnChanges {
  @Input() filters;
  @Input() reset;
  @Input() manualSearch;
  @Input() preexistingFilters;
  @Input() bridgeIdOptions;
  @Input() bridgeId;
  @Input() showBridgeIdSearch;
  @Input() searchButtonText: string;
  @Input() gridName: string;
  @Input() showFilterManager: boolean;
  @Output() filtersUpdated = new EventEmitter();
  @Output() clearSingleFilter = new EventEmitter();
  @Output() triggerSearch = new EventEmitter();
  @Output() bridgeIdSearch = new EventEmitter();

  filterForm: FormGroup = this.fb.group({});
  inputValueChanged: Subject<string> = new Subject<string>();
  formFields = { primaryForm: [], secondaryForm: [] };
  additionalFilters = [];
  currentFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  dollarMask = this.mask.dollarMaskSpecs();
  percentMask = this.mask.percentMaskSpecs();
  states = states;
  unsubscribe = new Subject();

  constructor(
    public fb: FormBuilder,
    public cstmFltrSrvc: CustomFiltersService,
    private mask: MaskService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.createGroup();

    if (this.filters) {
      this.buildFilters();
      this.setDefaultValues();
    }

    this.cstmFltrSrvc.updatePresets$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        field => {
          if (field['customRange']) {
            this.currentFilters.filters = this.currentFilters.filters.filter(
              filter => filter['field'] !== field['field']
            );
            this.currentFilters.filters.push(
              {
                field: field['field'],
                operator: 'start',
                value: field['customRange']['start'],
              },
              {
                field: field['field'],
                operator: 'end',
                value: field['customRange']['end'],
              }
            );
          }
        },
        error => alert(error)
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, propName)) {
        switch (propName) {
          case 'filters': {
            this.filterForm = this.createGroup();
            setTimeout(() => {
              this.buildFilters();
            });
            break;
          }
          case 'reset': {
            if (changes[propName].currentValue) {
              this.filterForm.reset();
              this.currentFilters = { logic: 'and', filters: [] };
            }
            break;
          }
        }
      }
    }
  }

  createGroup(groupFilter?) {
    const group = this.fb.group({});
    if (groupFilter) {
      let controls = this.filters.filter(x => x.filterGroup == groupFilter);

      // If there are additional filters add them to the primary formgroup
      if (
        groupFilter === 'primary' &&
        this.filters.find(x => x.filterGroup === 'additional')
      ) {
        controls = controls.concat(
          this.filters.filter(x => x.filterGroup === 'additional')
        );
      }

      controls.forEach(control => {
        group.addControl(control.varName, this.fb.control(''));
      });
    }

    return group;
  }

  createControl() {
    return this.fb.control('');
  }

  buildFilters() {
    this.currentFilters = { logic: 'and', filters: [] };
    this.formFields.primaryForm = this.filters.filter(
      x => x.filterGroup == 'primary'
    );
    this.formFields.secondaryForm = this.filters.filter(
      x => x.filterGroup == 'secondary'
    );
    if (this.filters.find(filter => filter.filterGroup === 'additional')) {
      this.additionalFilters = this.filters.filter(
        filter => filter.filterGroup === 'additional'
      );
    }

    let groups = this.filters.map(x => x.filterGroup);
    groups = [...new Set(groups)];
    if (!this.filterForm.controls.primaryForm && groups.includes('primary')) {
      this.filterForm.addControl('primaryForm', this.createGroup('primary'));
    } else if (!groups.includes('primary')) {
      delete this.formFields.primaryForm;
    }

    if (
      !this.filterForm.controls.secondaryForm &&
      groups.includes('secondary')
    ) {
      this.filterForm.addControl(
        'secondaryForm',
        this.createGroup('secondary')
      );
    } else if (!groups.includes('secondary')) {
      delete this.formFields.secondaryForm;
    }

    if (this.preexistingFilters && this.preexistingFilters.filters.length > 0) {
      // If there are preexisting filters set them in the form
      this.currentFilters = this.preexistingFilters;
      const filtersObject = {};
      this.filters.map(filter => {
        filtersObject[filter.varName] = '';
      });
      this.currentFilters.filters.map(filter => {
        if (Object.keys(filtersObject).includes(filter['field'])) {
          const updateTheFilter = this.filters.find(
            x => x.varName === filter['field']
          );
          filtersObject[filter['field']] = filter['value'];
          const formLevel = updateTheFilter.filterGroup;
          const leGroup = this.createGroup(formLevel);
          leGroup.setValue(filtersObject);
          this.filterForm.setControl(
            `${updateTheFilter.filterGroup}Form`,
            leGroup
          );
        }
      });
    }
  }

  setDefaultValues() {
    const defaultValues = this.filters.filter(x => x.defaultValue);

    if (defaultValues.length > 0) {
      // If there are default values set them in the form
      defaultValues.map(filter => {
        this.filterForm.controls[`${filter.filterGroup}Form`]['controls'][
          filter.varName
        ].setValue(filter.defaultValue);
        this.currentFilters.filters.push({
          field: filter.varName,
          operator: filter.filterType,
          value: filter.defaultValue,
        });
      });
      this.filtersChanged();
    }
  }

  selectionChange(ev, field) {
    const isArray = Array.isArray(ev.value);
    const eventFieldName =
      ev.source.ngControl && ev.source.ngControl.name
        ? ev.source.ngControl.name
        : field.fullField.DataField;
    const existingIdx = this.currentFilters.filters.findIndex(
      x => eventFieldName === x['field']
    );

    if (!isArray) {
      if (existingIdx > -1) {
        this.currentFilters.filters[existingIdx]['value'] = ev.value;
      } else if (field.filterType != 'between') {
        this.currentFilters.filters.push({
          field: eventFieldName,
          operator: field.filterType,
          value: ev.value || ev.option.value,
        });
      } else {
        this.currentFilters.filters.push(
          {
            field: eventFieldName,
            operator: 'lte',
            value: +field.between1,
          },
          {
            field: eventFieldName,
            operator: 'gte',
            value: +field.between2,
          }
        );
      }
    } else if (field.controlType === 'states') {
      if (existingIdx > -1) {
        this.currentFilters.filters = this.currentFilters.filters.filter(
          x => x['field'] != eventFieldName
        );

        ev.value.forEach(el => {
          this.currentFilters.filters.push({
            field: eventFieldName,
            operator: 'contains',
            value: el,
          });
        });
      } else {
        ev.value.forEach(el => {
          this.currentFilters.filters.push({
            field: eventFieldName,
            operator: 'contains',
            value: el,
          });
        });
      }
    } else if (
      field.controlType === 'multiselect' &&
      field.varName === 'AlertIDs'
    ) {
      if (existingIdx > -1) {
        this.currentFilters.filters = this.currentFilters.filters.filter(
          x => x['field'] != eventFieldName
        );
        ev.value.forEach(filterValue => {
          if (!Array.isArray(filterValue)) {
            this.currentFilters.filters.push({
              field: eventFieldName,
              operator: 'selected',
              value: ev.value || ev.option.value,
            });
          }
        });
      } else {
        ev.value.forEach(() => {
          this.currentFilters.filters.push({
            field: eventFieldName,
            operator: 'selected',
            value: ev.value || ev.option.value,
          });
        });
      }
    } else {
      if (existingIdx > -1) {
        this.currentFilters.filters = this.currentFilters.filters.filter(
          x => x['field'] != eventFieldName
        );
        ev.value.forEach(filterValue => {
          if (!Array.isArray(filterValue)) {
            this.currentFilters.filters.push({
              field: eventFieldName,
              operator: 'contains',
              value: ev.value || ev.option.value,
            });
          }
        });
      } else {
        ev.value.forEach(() => {
          this.currentFilters.filters.push({
            field: eventFieldName,
            operator: 'contains',
            value: ev.value || ev.option.value,
          });
        });
      }
    }
    this.filtersChanged();
  }

  clearFilter(ev, field, group) {
    ev.preventDefault();
    ev.stopPropagation();

    this.filterForm.controls[group]['controls'][field.varName].setValue(null);
    this.currentFilters.filters = this.currentFilters.filters.filter(x => {
      return x['field'] != field.varName;
    });

    this.filtersChanged();
    this.clearSingleFilter.emit(field.varName);
  }

  textChange(ev, field) {
    // debounce filter text changes
    if (this.inputValueChanged.observers.length === 0) {
      this.inputValueChanged
        .pipe(debounceTime(750), distinctUntilChanged())
        .subscribe(() => {
          const existingIdx = this.currentFilters.filters.findIndex(
            x => field.fullField.DataField === x['field']
          );

          if (existingIdx > -1 && field.filterType != 'between') {
            this.currentFilters.filters[existingIdx]['value'] = ev.target.value;
          } else if (existingIdx < 0 && field.filterType == 'between') {
            const fltrValue = ev.target.value.includes('$')
              ? this.mask.removeMoneyMask(ev.target.value)
              : ev.target.value;
            this.currentFilters.filters.push(
              { field: field.between1, operator: 'lte', value: +fltrValue },
              { field: field.between2, operator: 'gte', value: +fltrValue }
            );
          } else if (existingIdx > -1 && field.filterType == 'between') {
            const fltrValue = ev.target.value.includes('$')
              ? this.mask.removeMoneyMask(ev.target.value)
              : ev.target.value;
            const fltr1 = this.currentFilters.filters.filter(
              fld => fld['field'] == field.fullField.externalFilter.between1
            );
            fltr1[0]['value'] = +fltrValue;

            const fltr2 = this.currentFilters.filters.filter(
              fld => fld['field'] == field.fullField.externalFilter.between2
            );
            fltr2[0]['value'] = +fltrValue;
          } else if (existingIdx < 0 && field.filterType == 'contains') {
            this.currentFilters.filters.push({
              field: field.varName,
              operator: 'contains',
              value: ev.target.value,
            });
          } else {
            this.currentFilters.filters.push({
              field: field.fullField.DataField,
              operator: field.filterType,
              value: ev.target.value,
            });
          }

          this.filtersChanged();
        });
    }
    this.inputValueChanged.next(ev.target.value);
  }

  dateChange(e, field) {
    let dateValue;
    let position;

    const filterPosition = checkField =>
      this.currentFilters.filters.findIndex(
        filter =>
          filter['field'] === field.varName && filter['operator'] === checkField
      );

    if (e.value !== null) {
      if (e.targetElement.className.includes('start-date')) {
        dateValue = DateTime.fromJSDate(new Date(e.value))
          .toUTC()
          .startOf('day')
          .toISO();
        position = 'start';
      } else {
        dateValue = DateTime.fromJSDate(new Date(e.value))
          .toUTC()
          .endOf('day')
          .toISO();
        position = 'end';
      }
    } else {
      dateValue = null;
    }

    const filterExists = filterPosition(position);

    if (filterExists >= 0) {
      this.currentFilters.filters[filterExists] = {
        field: field.varName,
        operator: position,
        value: dateValue,
      };
    } else {
      this.currentFilters.filters.push({
        field: field.varName,
        operator: position,
        value: dateValue,
      });
    }

    if (field.controlType === 'relatedDate') {
      // Clear the related date
      this.currentFilters.filters = this.currentFilters.filters.filter(
        relatedFilter => relatedFilter['field'] !== field.relatedVarName
      );
    }

    if (
      e.targetElement.className.includes('end-date') &&
      filterPosition('start') >= 0 &&
      filterPosition('end') >= 0
    ) {
      this.filtersChanged();
    }
  }

  relatedDateChange(e, field) {
    let dateValue;
    let position;

    const filterPosition = checkField =>
      this.currentFilters.filters.findIndex(
        filter =>
          filter['field'] === field.relatedVarName &&
          filter['operator'] === checkField
      );

    if (e.targetElement.className.includes('start-date')) {
      dateValue = DateTime.fromJSDate(new Date(e.value))
        .toUTC()
        .startOf('day')
        .toISO();
      position = 'start';
    } else {
      dateValue = DateTime.fromJSDate(new Date(e.value))
        .toUTC()
        .endOf('day')
        .toISO();
      position = 'end';
    }

    const filterExists = filterPosition(position);

    if (filterExists >= 0) {
      this.currentFilters.filters[filterExists] = {
        field: field.relatedVarName,
        operator: position,
        value: dateValue,
      };
    } else {
      this.currentFilters.filters.push({
        field: field.relatedVarName,
        operator: position,
        value: dateValue,
      });
    }

    // Clear the related date
    this.currentFilters.filters = this.currentFilters.filters.filter(
      relatedFilter => relatedFilter['field'] !== field.varName
    );

    if (
      e.targetElement.className.includes('end-date') &&
      filterPosition('start') >= 0 &&
      filterPosition('end') >= 0
    ) {
      this.filtersChanged();
    }
  }

  filtersChanged() {
    this.filtersUpdated.emit(this.currentFilters);
  }

  triggerManualSearch() {
    setTimeout(() => {
      this.triggerSearch.emit();
    }, 750);
  }

  setBridgeIdSearch(e, clearBridge = false) {
    if (clearBridge) {
      this.bridgeIdSearch.emit('clear');
    } else {
      this.bridgeIdSearch.emit(e);
    }
  }
  applySavedFilters(ev) {
    this.preexistingFilters = ev;
    if (
      this.preexistingFilters &&
      this.preexistingFilters.filters.length === 0
    ) {
      this.filterForm.reset();
      this.currentFilters = { logic: 'and', filters: [] };
    }
    this.buildFilters();
    this.filtersChanged();
  }
}
