import { Component, Input } from '@angular/core';
import { AdminService } from '../../../admin/admin.service';
import { Unit } from '../../../models/admin-models';
import { Platform } from '@angular/cdk/platform';
import { ReportingService } from '../../reporting.service';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'app-unit-profile-history',
  templateUrl: './unit-profile-history.component.html',
  styleUrls: ['./unit-profile-history.component.scss'],
})
export class UnitProfileHistoryComponent {
  @Input() baseUrl;

  unitProfileHistoryData;
  units: Unit[] = [] as Unit[];
  filteredUnits;
  selectedUnit;
  selectedApp;
  reportTypes: string[];
  selectedType;
  loading = false;

  constructor(
    private admnSvc: AdminService,
    private pltfrm: Platform,
    private rptSvc: ReportingService
  ) {}

  getUnitProfileHistory(ev) {
    this.selectedApp = ev.app;
    this.rptSvc.getUnitProfileHistory(ev).subscribe({
      next: data => {
        const ogData = data.results.reverse();
        this.unitProfileHistoryData = [...ogData];
      },
      error: error => {
        console.error(error);
      },
    });
  }

  getUnitProfileReport(ev) {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.admnSvc
      .getUnitProfileReport(
        this.selectedUnit,
        this.selectedApp,
        this.selectedType
      )
      .subscribe(data => {
        const filename = `${this.selectedUnit}_${this.selectedApp}_${this.selectedType}_report.pdf`;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          // this.loading = false;
        };
        this.loading = false;
      });
  }

  download(ev, id, type) {
    this.admnSvc
      .downloadHistoricUnitProfile(id, this.selectedApp, type)
      .subscribe(
        data => {
          let fileData;
          const filename = `${this.selectedApp}-unitProfile-${id}.${type}`;
          const reader = new FileReader();
          reader.readAsDataURL(data);

          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename);
          };
        },
        error => {
          console.error(error);
        }
      );
  }
}
