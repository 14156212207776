import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ReportingService } from '../reporting/reporting.service';
import { EnvironmentService } from '../services/environment.service';
import { Platform } from '@angular/cdk/platform';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'app-compare-dialog',
  templateUrl: './compare-dialog.component.html',
  styleUrls: ['./compare-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompareDialogComponent implements OnInit {
  @ViewChild('scroll', { static: true }) private scrollContainer: ElementRef;

  funds;
  gridData = [];
  columns = [];
  formattedFunds = [];
  tables = [];
  loading = false;
  private baseUrl: string;
  private environment;
  tableDef = {
    id: 'compare1',
    name: 'compare1',
    label: 'table label',
    type: 'vert',
    header: {
      title: '',
      bullets: [],
    },
    footer: {
      footnotes: [],
    },
    columns: [
      {
        var: 'header1',
        label: 'Label',
      },
      {
        var: 'header1',
        label: 'Fund',
      },
    ],
    rows: [
      {
        var: 'name',
        label: 'Name',
      },
      {
        var: 'RBCategoryy',
        label: 'Category',
      },
      {
        var: 'AssetAllocationCashnet',
        label: 'Asset Allocation in Cash',
      },
      {
        var: 'AssetAllocationNonUSBondNet',
        label: 'Asset Allocation in non-US Bonds',
      },
      {
        var: 'AssetAllocationNonUSStockNet',
        label: 'Asset Allocation in non-US Stocks',
      },
      {
        var: 'AssetAllocationOtherNet',
        label: 'Asset Allocation in Other Investments',
      },
      {
        var: 'AssetAllocationPreferredNet',
        label: 'Asset Allocation in Preferred Stocks',
      },
      {
        var: 'AssetAllocationUSBondNet',
        label: 'Asset Allocation in US Bonds',
      },
      {
        var: 'AssetAllocationUSStockNet',
        label: 'Asset Allocation in US Stocks',
      },
      {
        var: 'TrailingReturnYTD',
        label: 'YTD',
      },
      {
        var: 'TrailingReturnY1',
        label: '1 Yr Trailing Return',
      },
      {
        var: 'TrailingReturnY3',
        label: '3 Yr Trailing Return',
      },
      {
        var: 'TrailingReturnY5',
        label: '5 Yr Trailing Return',
      },
      {
        var: 'TrailingReturnY10',
        label: '10 Yr Trailing Return',
      },
      {
        var: '3yStandardDeviation',
        label: 'Standard Deviation',
      },
      {
        var: 'R_Squared',
        label: 'R^2',
      },
      {
        var: 'InceptionDate',
        label: 'Inception Date',
      },
      {
        var: 'MgrTenure',
        label: 'Average Manager Tenure',
      },
      {
        var: 'NetAssets',
        label: 'Net Assets',
      },
      {
        var: 'TurnoverRatio',
        label: 'Turnover Rate',
      },
      {
        var: 'ExpenseRatio',
        label: 'Expense Ratio',
      },
      {
        var: 'AverageCreditQuality',
        label: 'Average Credit Quality',
      },
      {
        var: 'AverageEffectiveDuration',
        label: 'Average Duration',
      },
      {
        var: 'YieldAsOfDate',
        label: '30-Day Yield',
      },
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<CompareDialogComponent>,
    private rptSvc: ReportingService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.funds = this.data.funds;
    if (
      this.data.tables &&
      this.data.tables.length == 1 &&
      this.data.tables[0].type == 'horz'
    ) {
      this.horizontalTable(this.funds, this.data.tables[0]);
    } else if (
      this.data.tables &&
      this.data.tables.length >= 1 &&
      this.data.type != 'iwGrid'
    ) {
      this.data.tables.forEach(e => {
        const currSet = this.funds.filter(x => {
          return x.BridgeType.toLowerCase() == e.bridgeType.toLowerCase();
        });
        this.formatTable(currSet, e);
      });
    } else if (this.data.tables && this.data.type == 'iwGrid') {
      this.formatTable(this.funds, this.data.tables[0]);
    } else {
      this.formatTable(this.funds, this.tableDef);
    }
  }

  formatTable(funds, table) {
    const rows = table['rows'],
      header = table['header'],
      footer = table['footer'],
      data = [],
      columnData = [];

    this.formattedFunds = [];

    const liSwitch = table.bridgeType == 'term' || table.bridgeType == 'perm';
    funds?.forEach((x, i) => {
      const currFund = liSwitch ? x.vars : x;

      this.formattedFunds[i] = {};
      rows.forEach(y => {
        if (!y.header) {
          if (y.var == 'name') {
            if (liSwitch) {
              this.formattedFunds[i][y.var] = currFund[y.var].display
                ? currFund[y.var].display[0]
                : '';
            } else {
              this.formattedFunds[i][y.var] = currFund[y.var];
            }
          } else if (currFund.hasOwnProperty(y.var)) {
            if (liSwitch) {
              this.formattedFunds[i][y.var] = currFund[y.var].display
                ? currFund[y.var].display[0]
                : '';
            } else {
              this.formattedFunds[i][y.var] = currFund[y.var];
            }
          } else if (
            currFund.currFund &&
            currFund.currFund.hasOwnProperty(y.var)
          ) {
            if (liSwitch) {
              this.formattedFunds[i][y.var] = currFund[y.var].display
                ? currFund[y.var].display[0]
                : '';
            } else {
              this.formattedFunds[i][y.var] = currFund[y.var];
            }
          } else if (currFund.subBridges) {
            let found = false;
            for (const e of currFund.subBridges) {
              // if (typeof e[y.var] != 'undefined') {
              if (e.hasOwnProperty(y.var)) {
                this.formattedFunds[i][y.var] = e[y.var];
                found = true;
                break;
              }
            }

            if (!found) {
              this.formattedFunds[i][y.var] = '';
            }
          } else {
            this.formattedFunds[i][y.var] = '';
          }
        }
      });

      this.formattedFunds[i].id = x;
    });
    this.formattedFunds.forEach((x, i) => {
      let shortName: string;
      if (x.id.ShortName) {
        shortName = x.id.ShortName;
      } else if (x.id.id && x.id.id.ShortName) {
        shortName = x.id.id.ShortName;
      } else {
        shortName = x.id.Name;
      }

      if (x.id.fundId) {
        columnData[i] = { id: x.id.fundId, name: x.name };
      } else if (x.name) {
        columnData[i] = { id: 'a' + x.name, name: shortName };
      } else if (x.Name) {
        columnData[i] = { id: 'a' + x.Name, name: x.Name };
      } else if (x.id.name) {
        columnData[i] = { id: 'a' + x.id.name, name: shortName };
      }
    });

    const dataLength = this.formattedFunds.length - 1,
      propertiesLength = rows.length - 1;

    for (let i = 0; i <= propertiesLength; i++) {
      data[i] = {};
      for (let j = 0; j <= dataLength; j++) {
        const currentItem = this.formattedFunds[j];
        const property = Object.keys(currentItem)[i];
        const label =
          property &&
          property != 'id' &&
          rows.find(q => {
            return q.var == property;
          })
            ? rows.find(q => {
                return q.var == property;
              }).label
            : null;
        if (j === 0) {
          data[i]['metric'] = label;
        }

        if (currentItem.id.fundId && property && property != 'id') {
          data[i][currentItem.id.fundId] = currentItem[property];
        } else if (currentItem.name && property && property != 'id') {
          data[i]['a' + currentItem.name] = currentItem[property];
        } else if (currentItem.Name && property && property != 'id') {
          data[i]['a' + currentItem.Name] = currentItem[property];
        } else if (currentItem.id.name && property && property != 'id') {
          data[i]['a' + currentItem.id.name] = currentItem[property];
        }
      }
    }

    this.gridData = data.filter(x => {
      let content = false;
      Object.keys(x).forEach((z, i) => {
        if (i > 0 && !content) {
          content = x[z] && x[z].length > 0;
        }
      });
      return content;
    });
    this.columns = columnData;

    this.scrollContainer.nativeElement.scrollTop = 0;
    if (this.columns.length > 0) {
      this.tables.push({
        gridData: this.gridData,
        columns: this.columns,
        header: header,
        footer: footer,
      });
    }
  }

  horizontalTable(funds, table) {
    const header = null,
      footer = table['footer'];

    funds.forEach(el => {
      el.name = el.id.ShortName;
      if (el.subBridges) {
        table.rows.forEach(col => {
          if (!Object.keys(el).includes(col.var)) {
            el.subBridges.forEach(sub => {
              Object.keys(sub).forEach(() => {
                el[col.var] = sub[col.var] ? sub[col.var] : el[col.var];
              });
            });
          }
        });
      }
    });

    this.gridData = funds;
    this.columns = table.rows.map(z => {
      return { id: z.var, name: z.label };
    });

    this.tables.push({
      gridData: this.gridData,
      columns: this.columns,
      header: header,
      footer: footer,
      type: 'horz',
    });
  }

  downloadComparisonPdf() {
    this.loading = true;
    const reportData = {
      gridData: this.gridData,
      columns: this.columns,
    };

    let solution = 'rb';
    if (this.data.iwProductShelf) {
      solution = 'iw';
    }

    this.rptSvc.getComparisonReport(reportData, solution).subscribe(resp => {
      const filename = `${
        this.data.iwProductShelf ? 'investment' : 'product'
      }-comparison.pdf`;
      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      let fileData;
      const reader = new FileReader();

      reader.readAsDataURL(resp);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
      };
      this.loading = false;
    });
  }
}
