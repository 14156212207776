import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.scss'],
})
export class DetailDialogComponent implements OnInit {
  form: FormGroup;
  type = 'model';
  headline: string;
  content: string;
  confirm: string;
  placeholder: string;
  required = Validators.required;

  constructor(
    public dialogRef: MatDialogRef<DetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.headline =
        this.data.headline || 'Reason for switching Investment Model';
      this.content = this.data.content || '';
      this.placeholder = this.data.placeholder || '';
      this.confirm = this.data.confirm || 'Save';
      this.type = this.data.type || 'model';
      this.required = this.data.commentRequired == false ? null : this.required;
    }

    const formGroup = {};
    formGroup['reason'] = new FormControl('', this.required);
    this.form = new UntypedFormGroup(formGroup);
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close({ data: this.form.get('reason').value });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }
}
