import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RightBridgeApiService } from '../services/right-bridge-api.service';

@Component({
  selector: 'funds-dialog',
  templateUrl: './funds-dialog.component.html',
  styleUrls: ['./funds-dialog.component.scss'],
})
export class FundsDialogComponent {
  funds = [];
  distMethods = [
    { value: 1, label: 'Distribute Evenly' },
    { value: 2, label: 'Custom Amounts' },
  ];
  selected = [];

  constructor(
    private rbs: RightBridgeApiService,
    public dialogRef: MatDialogRef<FundsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.funds = data.funds ? data.funds[0] : [];
    //fix this cludge
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'fund');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    // Traverse the DOM up with a while loop
    while (!el.classList.contains(clazz)) {
      // Increment the loop to the parent node
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    // At this point, the while loop has stopped and `el` represents the element that has
    // the class you specified in the second parameter of the function `clazz`

    // Then return the matched element
    return el;
  }

  selectFund(e, fund) {
    this.selected.push({
      'SelectedFunds.Cusip': 'realcusip',
      'SelectedFunds.Symbol': 'realsymbol',
      'SelectedFunds.AssetClass': 'realclass',
      'SelectedFunds.Name': 'realname',
      'SelectedFunds.Amount': 125000,
    });
  }
}
