<div mat-dialog-header>
  <h1>{{ data.name }}</h1>
  <mat-toolbar color="primary">
    <span>{{ data.header }}</span>
  </mat-toolbar>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <div class="row compare-container col-12">
    <ng-container *ngFor="let t of tables">
      <h1 class="table-header" *ngIf="t.header && t.header.title">{{
        t.header.title
      }}</h1>
      <ul class="header-details" *ngIf="t.header && t.header.bullets">
        <li *ngFor="let z of t.header.bullets">{{ z }}</li>
      </ul>
      <kendo-grid
        [kendoGridBinding]="t.gridData"
        [sortable]="t.type === 'horz'"
        class="compare-table w-100"
      >
        <kendo-grid-column
          field="metric"
          title=""
          width="150"
          [locked]="true"
          *ngIf="t.type !== 'horz'"
        ></kendo-grid-column>
        <ng-container *ngIf="t.type !== 'horz'">
          <kendo-grid-column
            *ngFor="let column of t.columns"
            field="{{ column.id }}"
            title="{{ column.name }}"
            class="percent-column"
            [width]="450"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.id]"></span>
            </ng-template>
          </kendo-grid-column>
        </ng-container>
        <ng-container *ngIf="t.type === 'horz'">
          <kendo-grid-column
            *ngFor="let column of t.columns"
            field="{{ column.id }}"
            title="{{ column.name }}"
            class="percent-column"
            [width]="column.id === 'name' ? 250 : 150"
            [locked]="column.id === 'name'"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.id]"></span>
            </ng-template>
          </kendo-grid-column>
        </ng-container>
      </kendo-grid>
      @if (t.footer && t.footer.footnotes) {
        @for (z of t.footer.footnotes; track $index) {
          <div [innerHTML]="z"></div>
        }
      }
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button
    mat-stroked-button
    color="primary"
    (click)="downloadComparisonPdf()"
    *ngIf="data.iwProductShelf"
  >
    <mat-icon>download</mat-icon>Download Comparison
  </button>
  <button mat-raised-button (click)="dialogRef.close('Cancel')">Close</button>
</mat-dialog-actions>
<ngx-loading
  [show]="loading"
  [config]="{
    fullScreenBackdrop: false,
    backdropBackgroundColour: 'rgba(0,0,0,.5)'
  }"
></ngx-loading>
