import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../../reporting.service';
import { AdminService } from '../../../admin/admin.service';
import { Application, Unit } from '../../../models/admin-models';
import { FormControl } from '@angular/forms';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-rep-exceptions-report',
  templateUrl: './rep-exceptions.component.html',
  styleUrls: ['./rep-exceptions.component.scss'],
})
export class RepExceptionsComponent implements OnInit {
  exceptionsData = { data: null, headers: [] };
  startDate;
  endDate;
  alertId;
  filteredUnits;
  unitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  selectedUnit;
  applications: Application[] = [];
  selectedApp;

  constructor(
    private rptSvc: ReportingService,
    private admnSrvc: AdminService,
    private ss: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.getUnits();
    this.ss.get('globals')?.Modules.forEach(el => {
      switch (el.toLowerCase()) {
        case 'aw':
          this.applications.push({
            name: 'Annuity Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'iw':
          this.applications.push({
            name: 'Investment Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'li':
          this.applications.push({
            name: 'Life Insurance Wizard',
            value: el.toLowerCase(),
          });
          break;
        case 'pp':
          this.applications.push({
            name: 'Product Profiler',
            value: el.toLowerCase(),
          });
          break;
        case 'se':
          this.applications.push({
            name: 'Suggestion Engine',
            value: el.toLowerCase(),
          });
          break;
        default:
          break;
      }
    });

    this.selectedApp = this.applications[0]?.value;
  }

  getUnits() {
    this.filteredUnits = this.unitsControl.valueChanges.pipe(
      startWith('%'),
      distinctUntilChanged(),
      switchMap(value => {
        if (value.length > 2) {
          return this._filterUnits(value);
        }
        return [];
      })
    );
  }

  getExceptions() {
    this.exceptionsData.data = [];
    this.exceptionsData.headers = [];
    const start = new Date(this.startDate).toISOString();
    const end = new Date(this.endDate).toISOString();
    this.rptSvc
      .getRepExceptions(
        start,
        end,
        this.alertId,
        this.selectedApp,
        this.selectedUnit
      )
      .subscribe({
        next: data => {
          if (data.results.data.length > 0) {
            this.exceptionsData.data = [...data.results.data];
            const keys = Object.keys(data.results.data[0]);
            keys.forEach(x => {
              this.exceptionsData.headers.push({ DataField: x, Label: x });
            });
          }
        },
        error: error => {
          console.error(error);
        },
      });
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSrvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
}
