import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';

@Component({
  selector: 'app-case-management-modal',
  templateUrl: './case-management-modal.component.html',
  styleUrls: ['./case-management-modal.component.scss'],
})
export class CaseManagementModalComponent {
  constructor(
    private dialogRef: MatDialogRef<CaseManagementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog
  ) {}

  changesMade = false;

  changeValue(value) {
    this.changesMade = value;
  }

  close() {
    if (!this.changesMade) {
      this.dialogRef.close({ data: 'cancel' });
    } else {
      let warnDialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content: `Leave without saving your changes?`,
          confirm: 'Yes',
          hideCancel: false,
        },
      });

      warnDialogRef.afterClosed().subscribe(result => {
        if (result == 'continue') {
          this.dialogRef.close({ data: 'cancel' });
        }
        warnDialogRef = null;
      });
    }
  }
}
