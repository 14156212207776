import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-post-trade',
  templateUrl: './post-trade.component.html',
  styleUrls: ['./post-trade.component.scss'],
})
export class PostTradeComponent implements OnInit, OnDestroy {
  rights = [];
  unsubscribe: Subject<any> = new Subject();
  apps = [
    {
      route: 'trade-review',
      appName: 'Trade Review',
      app: 'TR',
      appRight: 'TradeReview',
      icon: 'beenhere',
    },
    {
      route: 'account-review',
      appName: 'Account Review',
      app: 'AR',
      appRight: 'AccountReview',
      icon: 'fact_check',
    },
    {
      route: 'rep-review',
      appName: 'Rep Review',
      app: 'RR',
      appRight: 'RepReview',
      icon: 'how_to_reg',
      disabled: true,
    },
  ];
  activeApp;

  constructor(
    private ss: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (params.view) {
          this.activeApp = params.view;
        } else {
          const defaultApp = this.apps.find(app =>
            this.rights.includes(app.appRight)
          );

          this.activeApp = defaultApp.app;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  activateApp(app) {
    this.activeApp = app;
    this.route.navigate([`/post-trade/${app}`]);
  }

  findActiveApp(appName) {
    return this.apps.find(app => app.app === appName);
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
