import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tradeApprovalKey } from '../trade-review-detail-keys';

@Component({
  selector: 'app-trade-history-dialog',
  templateUrl: './trade-history-dialog.component.html',
  styleUrls: ['./trade-history-dialog.component.scss'],
})
export class TradeHistoryDialogComponent {
  tradeApprovalKey = tradeApprovalKey;

  constructor(
    public dialogRef: MatDialogRef<TradeHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  save() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
