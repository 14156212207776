<div>
  @if (!loading && !editCaseTitle) {
    <h1 class="mb-0">
      {{ caseProfile['CaseManagement']['Title'] }}
      <button
        mat-icon-button
        [matTooltip]="'Edit case title'"
        (click)="toggleEditCaseTitle(true)"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </h1>
  }
  @if (!loading && editCaseTitle) {
    <div class="edit-title-container mb-3">
      <mat-form-field class="mb-0 w-50">
        <mat-label>Case Title</mat-label>
        <input
          type="text"
          [placeholder]="'Case Title'"
          matInput
          name="caseTitle"
          [(ngModel)]="caseTitle"
        />
      </mat-form-field>
      <button
        color="warn"
        mat-icon-button
        [matTooltip]="'Cancel'"
        (click)="cancelEditCaseTitle()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <button
        color="primary"
        mat-icon-button
        [matTooltip]="'Save case title'"
        (click)="saveCaseTitle()"
      >
        <mat-icon>save</mat-icon>
      </button>
    </div>
  }
  @if (!loading && showCaseHistory) {
    <div class="case-data">
      <p class="mb-0">
        Case Management Number: {{ data.CRID }} | Submitted By:
        {{ caseHistoryData[0]['SavedByName'] }}
      </p>
    </div>
  }
</div>

<div class="row dialog-content mt-3">
  <div class="col-5 case-info">
    @if (!loading) {
      <div
        class="assigned-to-container"
        [ngClass]="{
          'rep-only-view': canRespondToAssignee,
          'mb-3': !canRespondToAssignee
        }"
      >
        @if (canRespondToAssignee) {
          <div>
            <h4>Assigned To:</h4>
            <p>{{ caseProfile['CaseManagement']['AssignedTo'] }}</p>
          </div>
        } @else if (!canRespondToAssignee) {
          <mat-form-field class="w-100">
            <mat-label>Assigned To</mat-label>
            <mat-select
              [(ngModel)]="caseProfile['CaseManagement']['AssignedTo']"
              (selectionChange)="changeValue(true)"
              [disabled]="canRespondToAssignee"
            >
              @if (caseUsers.length > 0) {
                @for (user of caseUsers; track user.UserID) {
                  <mat-option [value]="user.UserID">
                    {{ user.UserName || user.UserID }}
                  </mat-option>
                }
              }
            </mat-select>
          </mat-form-field>
        }
      </div>
    }

    @if (!loading) {
      <div
        class="case-status-container"
        [ngClass]="{
          'rep-only-view': canRespondToAssignee,
          'mb-3': !canRespondToAssignee
        }"
      >
        @if (canRespondToAssignee) {
          <div>
            <h4>Case Status:</h4>
            <p>{{ caseProfile['CaseManagement']['StatusName'] }}</p>
          </div>
        } @else if (!canRespondToAssignee) {
          <mat-form-field class="w-100">
            <mat-label>Case Status</mat-label>
            <mat-select
              [(ngModel)]="caseProfile['CaseManagement']['StatusID']"
              (selectionChange)="changeValue(true)"
            >
              @for (
                status of caseProfile['ActiveStatuses'];
                track status.StatusID
              ) {
                <mat-option
                  [value]="status.StatusID"
                  [disabled]="!status['Active']"
                >
                  {{ status.StatusName || status.StatusID }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </div>
    }

    <div class="save-case-container mb-4">
      <button
        class="w-100"
        mat-raised-button
        aria-label="Submit Note"
        color="primary"
        (click)="saveCaseChanges()"
        [disabled]="saveBtnDisabled()"
      >
        @if (
          canRespondToAssignee &&
          this.caseProfile?.CaseManagement?.AssignedTo === this.profileID
        ) {
          <span>
            <mat-icon>save</mat-icon>
            Save Changes and Respond to Assignee
          </span>
        } @else if (
          canRespondToAssignee &&
          this.caseProfile?.CaseManagement?.AssignedTo !== this.profileID
        ) {
          <span
            >Assigned to Reviewer. Please wait for Reviewer to Reassign.</span
          >
        } @else {
          <span>
            <mat-icon>save</mat-icon>
            Save Changes
          </span>
        }
      </button>
    </div>

    @if (
      !loading &&
      caseProfile['UnitCMPostBack'] &&
      caseProfile['UnitCMPostBack']['ShowPostBackButton'] &&
      caseProfile['UnitCMPostbackStatus'] &&
      caseProfile['UnitCMPostbackStatus']['ShowStatuses'].includes(
        caseDetails['StatusID'].toString()
      )
    ) {
      <div class="save-case-container mb-4">
        <button
          class="w-100"
          mat-raised-button
          aria-label="Submit Note"
          color="primary"
          (click)="submitPostback()"
        >
          <mat-icon>assignment_turned_in</mat-icon>
          {{ caseProfile['UnitCMPostBack']['PostBackButtonLabel'] }}
        </button>
      </div>
    }

    @if (canUploadFiles) {
      <div class="upload-case-files mb-2">
        <h4 class="mb-2"> Upload Case Files </h4>
        <input
          type="file"
          class="file-input"
          (change)="onFileSelected($event)"
          #fileUpload
        />
        <span class="mb-2">
          {{ fileName || 'No file selected yet' }}
        </span>
        @if (!fileName) {
          <button
            class="w-100"
            mat-stroked-button
            aria-label="Attach File"
            color="primary"
            (click)="fileUpload.click()"
          >
            <mat-icon>attach_file</mat-icon> Attach File
          </button>
        } @else {
          <button
            class="w-100 mb-3"
            mat-stroked-button
            aria-label="Choose a Different File"
            color="warn"
            (click)="fileUpload.click()"
          >
            <mat-icon>upload</mat-icon> Choose a Different File
          </button>
          <button
            class="w-100 mb-3"
            mat-raised-button
            aria-label="Complete Upload"
            color="primary"
            (click)="completeFileUpload()"
          >
            <mat-icon>check</mat-icon> Complete Upload
          </button>
        }
      </div>
    }

    @if (data.firstComplete) {
      <div
        ><h5
          >Generating file... <br />
          This can take several minutes to complete. <br />
          Please check this tool again after a few moments.</h5
        ></div
      >
    }
    @if (caseFiles && caseFiles.length > 0 && isArray(caseFiles)) {
      <div class="case-files list">
        <h4 class="mb-2">
          Case Files <small>({{ caseFiles.length }} attached)</small>
        </h4>

        <div class="attached-case-files">
          @for (caseFile of caseFiles; track caseFile) {
            <div class="download-row">
              <span> {{ caseFile.Name }} </span>
              <button
                class="ml-2"
                mat-icon-button
                color="primary"
                (click)="getCaseRecordFile(caseFile)"
                matTooltip="Download File"
              >
                <mat-icon>download</mat-icon>
              </button>
              @if (caseFile.Category !== 'System' && canDeleteFiles) {
                <button
                  class="ml-2"
                  mat-icon-button
                  color="warn"
                  aria-label="Delete Case File"
                  (click)="deleteCaseRecordFile(caseFile)"
                  matTooltip="Delete File"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              }
            </div>
          }
        </div>
      </div>
    }
    @if (showPrintReportBtn && isCaseManager) {
      <div>
        <button
          mat-raised-button
          color="primary"
          (click)="printCMReport($event)"
          >{{ reportSettings['ButtonText'] }}</button
        >
      </div>
    }
  </div>

  <div class="col-7 cm-tab-container custom-tabs blue">
    <mat-tab-group>
      <mat-tab label="Notes">
        <div class="chat-center">
          <div class="chat-messages-container">
            @if (messages.length !== 0) {
              <div class="message-container w-100">
                @for (
                  message of messages;
                  track message.NoteId;
                  let i = $index
                ) {
                  <div
                    class="chat-message"
                    [ngClass]="{
                      incoming: message.CreatedByUserId !== data.defaultUserId,
                      'type-two':
                        message.NoteTypeId === '2' || message.NoteTypeId === 2,
                      hidden:
                        (message.NoteTypeId === '2' ||
                          message.NoteTypeId === 2) &&
                        !isCaseManager,
                      'top-message': i === 0
                    }"
                  >
                    <div class="sender-info">
                      {{ message.CreatedByUserName || message.CreatedByUserId }}
                      at
                      {{ message.CreateDate }}
                    </div>
                    @if (
                      message.NoteTypeId === '2' || message.NoteTypeId === 2
                    ) {
                      <div class="sender-info">Private</div>
                    }

                    @if (editMessageId !== message.NoteId) {
                      <div class="chat-bubble mb-3">
                        {{ message.NoteText }}
                        <span class="edit-icon-container">
                          <mat-icon
                            class="edit-icon"
                            (click)="editNote(message)"
                          >
                            edit_note
                          </mat-icon>
                        </span>
                      </div>
                    } @else {
                      <div class="edit-bubble">
                        @if (isCaseManager) {
                          <mat-form-field class="w-20 mr-1">
                            <mat-label>Type</mat-label>
                            <mat-select [(ngModel)]="editNoteType">
                              @for (type of noteTypes; track type.value) {
                                <mat-option [value]="type.value">
                                  {{ type.label }}
                                </mat-option>
                              }
                            </mat-select>
                          </mat-form-field>
                        }
                        <div class="input-container">
                          <input
                            matInput
                            type="text"
                            placeholder="Message"
                            name="Message Input"
                            [(ngModel)]="editMessage"
                          />
                        </div>
                        <mat-icon (click)="submitEdit()">save</mat-icon>
                      </div>
                    }
                  </div>
                }
              </div>
            } @else {
              <div class="no-chat-container"> No messages found </div>
            }
          </div>

          <div class="chat-input">
            <form (submit)="submitNote()">
              <mat-form-field class="w-20 mr-1" *hasPermission="'CaseManager'">
                <mat-label>Type</mat-label>
                <mat-select [(value)]="noteType">
                  @for (type of noteTypes; track $index) {
                    <mat-option [value]="type.value">
                      {{ type.label }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <div class="input-container">
                <textarea
                  matInput
                  placeholder="Message"
                  name="Message Input"
                  [(ngModel)]="message"
                ></textarea>
              </div>
              <button
                class="mx-2"
                mat-mini-fab
                [disabled]="!message"
                aria-label="Submit Note"
                color="primary"
                type="submit"
              >
                <mat-icon>send</mat-icon>
              </button>
            </form>
            <div
              class="note-hint"
              [ngClass]="{ 'note-hint-manager': isCaseManager }"
            >
              *Note can be edited at any time after it is sent
            </div>
          </div>
        </div>
      </mat-tab>

      @if (showCaseHistory) {
        <mat-tab label="Case History">
          <div class="history-center">
            <table class="w-100">
              <thead>
                @for (header of caseHistoryHeaders; track header.label) {
                  <th>{{ header.label }}</th>
                }
              </thead>
              <tbody>
                @for (entry of caseHistoryData; track entry) {
                  <tr>
                    @for (header of caseHistoryHeaders; track header.label) {
                      <td>{{ entry[header.fieldName] }}</td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </mat-tab>
      }
    </mat-tab-group>
  </div>
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
