import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'localTime',
  pure: false,
})
export class LocalTime implements PipeTransform {
  transform(value: string, format: string) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let formatString = 'hh:mma';
    if (format === 'date') {
      formatString = 'MMM d, yyyy';
    } else if (format === 'shortDate') {
      formatString = 'M/d/yyyy';
    } else if (format === 'dateTime') {
      formatString = 'MMM d, yyyy, hh:mma';
    } else if (format === 'shortDateTime') {
      formatString = 'M/d/yy, hh:mma';
    } else if (format === 'longDateTime') {
      formatString = 'ccc MMM dd, yyyy, hh:mma';
    }
    return DateTime.fromISO(value, { zone: 'utc' })
      .setZone(timeZone)
      .toFormat(formatString);
  }
}
