import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { ReportDialogComponent } from '../../report-dialog/report-dialog.component';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { saveAs } from '@progress/kendo-file-saver';
import { cloneDeep } from 'lodash';
import { Platform } from '@angular/cdk/platform';

import * as Highcharts from 'highcharts';
import { EnvironmentService } from '../../services/environment.service';
import { CustomReportModalComponent } from '../../shared-components/custom-report-modal/custom-report-modal.component';

@Component({
  selector: 'product-result',
  templateUrl: './product-result.component.html',
  styleUrls: ['./product-result.component.scss'],
})
export class ProductResultComponent implements OnInit {
  private environment;
  private baseUrl: string;

  id: string;
  chartData;
  products = [];
  productsCharts = [];
  loading = false;
  name: string;
  hidePrint = false;
  externalLink;
  externalAudit;
  crossApp;
  path: string;
  awButtonText = 'Choose Annuities';
  iwButtonText = 'Select Investments';
  liButtonText = 'Select Insurance Products';
  awButtonTooltip;
  assessmentTypes = [];
  Highcharts: typeof Highcharts = Highcharts;
  currentApp;
  reportSbtxt: string = null;
  hideDetails = true;
  validationError = false;
  rights;
  showReportNotes;
  notesPlaceholder;
  existingReportNotes;
  isRolloverCase = false;

  constructor(
    public dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private route: Router,
    private ss: SessionStorageService,
    private tes: TagsEvaluationService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  expand(event) {
    const el = this.closestByClass(event.target, 'product');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.path = this.environment.assets ? this.environment.assets : '';
    this.params.params.subscribe(params => {
      this.id = params.id;
    });
    this.getResults(this.id);
    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
    });

    const globals = this.ss.get('globals') || {};

    this.externalLink = globals.standardPostBack || null;
    this.externalAudit = globals.dynamicPostBack || null;
    this.crossApp = globals.crossApp || null;
    this.awButtonText = globals.AWButtonText
      ? globals.AWButtonText
      : this.awButtonText;
    this.iwButtonText = globals.IWButtonText
      ? globals.IWButtonText
      : this.iwButtonText;
    this.liButtonText = globals.LIButtonText
      ? globals.LIButtonText
      : this.liButtonText;
    this.currentApp = this.ss.get('currentApp');
    this.rights = this.ss.get('rights');
  }

  getResults(profile): any {
    this.loading = true;
    this.rbs.processProducts(profile).subscribe(data => {
      const parsed = data;
      const prodList = parsed.products;
      this.hidePrint = prodList.find(
        x => x.name.toLowerCase() == 'dataentryvalidation'
      );
      this.reportSbtxt =
        (data.vars && data.vars['UnitSettings^ReportSubtext'].display[0]) ||
        null;
      this.hideDetails =
        data.vars &&
        data.vars['UnitSettings^PrintCompare'].raw[0] == 'Y' &&
        data.vars['ClientProfiling^AssessmentType'].raw[0] == 2;
      this.hideDetails = !this.hideDetails;
      this.awButtonTooltip =
        data.vars && data.vars['UnitSettings^AWButtonTooltip']
          ? data.vars['UnitSettings^AWButtonTooltip'].display[0]
          : null;
      this.showReportNotes =
        data.vars['UnitQuestions^NoteField'] &&
        data.vars['UnitQuestions^NoteField'].raw[0] &&
        data.vars['UnitQuestions^NoteField'].raw[0].toLowerCase() == 'y';
      this.existingReportNotes =
        data.vars['ClientProfiling^Notes'] &&
        data.vars['ClientProfiling^Notes'].display[0];
      this.notesPlaceholder =
        data.vars['UnitQuestions^NoteFieldContents'] &&
        data.vars['UnitQuestions^NoteFieldContents'].display[0];

      if (this.existingReportNotes && this.existingReportNotes.length) {
        this.saveNotes(this.existingReportNotes);
      } else {
        this.saveNotes(null);
      }

      this.name = parsed.FirstName + ' ' + parsed.LastName;

      this.products = prodList.filter(x => {
        return !x.chart;
      });

      const types = [];
      this.products.forEach(x => {
        if (!x.ShortName && !x.LongName) {
          x.ShortName = x.name;
        }
        x.reasons.forEach(e => {
          e.explanation = this.tes.evalTags(e.explanation);
          e.headline = this.tes.evalTags(e.headline);
        });

        if (x.PlanGrid && x.PlanGrid.length > 0) {
          x.PlanGrid = JSON.parse(x.PlanGrid);
        }

        if (x.ComplianceChart && x.ComplianceChart.length > 0) {
          x.ComplianceChart = JSON.parse(x.ComplianceChart);
        }

        if (x.BridgeDetailChart && x.BridgeDetailChart.length > 0) {
          x.BridgeDetailChart = JSON.parse(x.BridgeDetailChart);
        }

        if (
          x.assessmenttype &&
          !this.assessmentTypes.includes(x.assessmenttype)
        ) {
          types.push(x.assessmenttype);
        }

        if (x.RelatedBridge) {
          const relatedBrdg = cloneDeep(JSON.parse(x.RelatedBridge));

          relatedBrdg.Bridges.forEach((el, i) => {
            const bridge = prodList.find(z => z.name == el);
            if (bridge) {
              relatedBrdg.Bridges[i] = {};
              relatedBrdg.Bridges[i]['charts'] = [];
              bridge.charts.forEach(z => {
                z.Data.plotOptions.series = {};
                // z.Data.plotOptions.series.events = {};
                z.Data.plotOptions.series.events = {
                  legendItemClick: function (e) {
                    e.preventDefault();
                  },
                };
                relatedBrdg.Bridges[i]['charts'].push(z);
                relatedBrdg.Bridges[i].ShortDescription =
                  bridge.ShortDescription;
                bridge.reasons.forEach(rs => {
                  rs.explanation = this.tes.evalTags(rs.explanation);
                  rs.headline = this.tes.evalTags(rs.headline);
                });
                relatedBrdg.Bridges[i].reasons = bridge.reasons;
                relatedBrdg.Bridges[i].dynamicContent =
                  bridge[bridge.dynamicContent];
                relatedBrdg.Bridges[i].header = bridge.ShortName;
              });
            } else if (relatedBrdg.Bridges.length == 1) {
              relatedBrdg.Bridges = null;
            } else {
              relatedBrdg.Bridges[i] = null;
            }

            x['bridgeCharts'] = relatedBrdg;
          });
        }

        this.isRolloverCase =
          x.BridgeType == 'rollover' ? true : this.isRolloverCase;

        x.expandedClass = x.assessmenttype === 'validate' ? 'expanded' : '';
      });

      this.validationError =
        this.products.length == 1 &&
        this.products[0].name == 'DataEntryValidation';

      const fullSet = ['validate', 'rollover', 'select', 'chart'];
      this.assessmentTypes = fullSet.filter(x => types.includes(x));

      this.loading = false;
    });
  }

  report(ev) {
    const notes = this.ss.get('ppReportNotes');
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      panelClass: 'report-dialog',
      data: {
        hideDetails: true,
        showNotes: this.showReportNotes,
        printNote: notes,
        placeholder: this.notesPlaceholder,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.loading = true;

        if (
          (result.notes && result.notes.length > 0) ||
          (notes && result.notes === '')
        ) {
          const finalNotes = notes && result.notes === '' ? null : result.notes;
          this.saveNotes(finalNotes);
          this.rbs
            .saveProfile(this.id, { 'ClientProfiling.Notes': finalNotes })
            .subscribe(() => {
              this.rbs
                .getReport(this.id, result['details'], false, false, false)
                .subscribe(data => {
                  const date = new Date();
                  const filename =
                    result.filename && result.filename != null
                      ? result.filename + '.pdf'
                      : 'ProductProfiler-' +
                        date.getMonth() +
                        date.getDay() +
                        date.getFullYear() +
                        '.pdf';

                  const iosSafari =
                    this.pltfrm.IOS ||
                    (navigator.platform === 'MacIntel' &&
                      navigator.maxTouchPoints > 1);

                  let fileData;
                  const reader = new FileReader();
                  reader.readAsDataURL(data);
                  reader.onloadend = () => {
                    fileData = reader.result;
                    saveAs(fileData, filename, {
                      proxyURL: `${this.baseUrl}/util/proxy`,
                      proxyTarget: '_self',
                      forceProxy: iosSafari,
                    });
                    this.loading = false;
                  };
                });
            });
        } else {
          this.rbs
            .getReport(this.id, result['details'], false, false, false)
            .subscribe(data => {
              const date = new Date();
              const filename =
                result.filename && result.filename != null
                  ? result.filename + '.pdf'
                  : 'ProductProfiler-' +
                    date.getMonth() +
                    date.getDay() +
                    date.getFullYear() +
                    '.pdf';

              const iosSafari =
                this.pltfrm.IOS ||
                (navigator.platform === 'MacIntel' &&
                  navigator.maxTouchPoints > 1);

              let fileData;
              const reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = () => {
                fileData = reader.result;
                saveAs(fileData, filename, {
                  proxyURL: `${this.baseUrl}/util/proxy`,
                  proxyTarget: '_self',
                  forceProxy: iosSafari,
                });
                this.loading = false;
              };
            });
        }
      }
    });
  }

  rolloverReport(ev) {
    const notes = this.ss.get('ppReportNotes');
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      panelClass: 'report-dialog',
      data: {
        hideDetails: true,
        showNotes: this.showReportNotes,
        printNote: notes,
        placeholder: this.notesPlaceholder,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        this.loading = true;

        if (result.notes && result.notes.length > 0) {
          this.saveNotes(result.notes);
          this.rbs
            .saveProfile(this.id, { 'ClientProfiling.Notes': result.notes })
            .subscribe(() => {
              this.rbs
                .getRolloverReport(this.id, result['details'])
                .subscribe(data => {
                  const date = new Date();
                  const filename =
                    result.filename && result.filename != null
                      ? result.filename + '.pdf'
                      : 'ProductProfiler-RolloverReport' +
                        date.getMonth() +
                        date.getDay() +
                        date.getFullYear() +
                        '.pdf';

                  const iosSafari =
                    this.pltfrm.IOS ||
                    (navigator.platform === 'MacIntel' &&
                      navigator.maxTouchPoints > 1);

                  let fileData;
                  const reader = new FileReader();
                  reader.readAsDataURL(data);
                  reader.onloadend = () => {
                    fileData = reader.result;
                    saveAs(fileData, filename, {
                      proxyURL: `${this.baseUrl}/util/proxy`,
                      proxyTarget: '_self',
                      forceProxy: iosSafari,
                    });
                    this.loading = false;
                  };
                });
            });
          // else {
          //   this.loading = false;
          // }
        } else {
          this.rbs
            .getRolloverReport(this.id, result['details'])
            .subscribe(data => {
              const date = new Date();
              const filename =
                result.filename && result.filename != null
                  ? result.filename + '.pdf'
                  : 'ProductProfiler-RolloverReport' +
                    date.getMonth() +
                    date.getDay() +
                    date.getFullYear() +
                    '.pdf';

              const iosSafari =
                this.pltfrm.IOS ||
                (navigator.platform === 'MacIntel' &&
                  navigator.maxTouchPoints > 1);

              let fileData;
              const reader = new FileReader();
              reader.readAsDataURL(data);
              reader.onloadend = () => {
                fileData = reader.result;
                saveAs(fileData, filename, {
                  proxyURL: `${this.baseUrl}/util/proxy`,
                  proxyTarget: '_self',
                  forceProxy: iosSafari,
                });
                this.loading = false;
              };
            });
        }
      }
    });
  }

  saveNotes(note) {
    this.ss.set('ppReportNotes', note);
  }

  externalAction() {
    let link = this.externalLink.link || null;
    const silentPrint = this.externalLink.silentPrint,
      pb = this.externalLink.includePostBack || null,
      includePID = this.externalLink.includePID,
      newTab = this.externalLink.RedirectSameTab ? '_self' : '_blank';

    if (includePID) {
      link += this.id;
    }

    if (silentPrint && pb) {
      this.loading = true;
      this.rbs.getReport(this.id, false, silentPrint).subscribe(() => {
        this.rbs.postback(this.id).subscribe(() => {
          this.loading = false;
          window.open(link, newTab);
        });
      });
    } else if (pb) {
      this.loading = true;
      this.rbs.postback(this.id).subscribe(() => {
        this.loading = false;
        window.open(link, newTab);
      });
    } else if (silentPrint) {
      this.loading = true;
      this.rbs.getReport(this.id, false, silentPrint).subscribe(() => {
        this.loading = false;
        window.open(link, newTab);
      });
    } else {
      window.open(link, newTab);
    }
  }

  externalAuditAction() {
    const silentPrint = this.externalAudit.silentPrint,
      newTab = this.externalAudit.RedirectSameTab ? '_self' : '_blank',
      customReportSolution =
        this.externalAudit.postbackSolution &&
        this.externalAudit.postbackSolution.length > 0
          ? this.externalAudit.postbackSolution
          : null;

    this.loading = true;

    let externalModal;
    try {
      externalModal = JSON.parse(this.externalAudit.postBackModalName);
    } catch (error) {
      console.error('Not valid json for modal: ', error);
    }

    if (externalModal && externalModal.ModalName.toLowerCase() === 'pershing') {
      let runModal = false;

      this.rbs.getPershingGroupProfile(this.id).subscribe(res => {
        runModal = res.PershingModal.AskForRRCode;

        if (runModal) {
          let dialogRef = this.dialog.open(CustomReportModalComponent, {
            panelClass: 'warn-dialog',
            minWidth: '50vw',
            data: {
              profile: this.id,
              name: externalModal.ModalName,
              headline: externalModal.Title,
              content: externalModal.Body,
              accounts: res.PershingModalAccountType,
              confirm: 'Submit',
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            dialogRef = null;
            if (result != 'cancel') {
              this.rbs
                .extPostbackData(this.id, null, customReportSolution)
                .subscribe(x => {
                  if (x && x.results != null && x.results.length > 0) {
                    const link = x.results;
                    this.loading = false;
                    window.open(link, newTab);
                  } else {
                    const failMessage = x.errorMessage
                      ? x.errorMessage
                      : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';
                    let dialogRef = this.dialog.open(WarnDialogComponent, {
                      panelClass: 'warn-dialog',
                      data: {
                        headline: 'Warning',
                        content: failMessage,
                        confirm: 'OK',
                        hideCancel: true,
                      },
                    });
                    dialogRef.afterClosed().subscribe(() => {
                      dialogRef = null;
                      this.loading = false;
                    });
                  }
                });
            } else {
              this.loading = false;
              console.warn('User opted out of submission');
            }
          });
        } else {
          this.rbs
            .extPostbackData(this.id, null, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;
                this.loading = false;
                window.open(link, newTab);
              } else {
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.loading = false;
                });
              }
            });
        }
      });
    } else if (
      externalModal &&
      externalModal.ModalName.toLowerCase() != 'pershing'
    ) {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          name: externalModal.ModalName,
          headline: externalModal.Title,
          content: externalModal.Body,
          confirm: 'Submit',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        if (result != 'cancel') {
          this.rbs
            .extPostbackData(this.id, result, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;
                this.loading = false;
                window.open(link, newTab);
              } else {
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.loading = false;
                });
              }
            });
        } else {
          this.loading = false;
          console.warn('User opted out of submission');
        }
      });
    } else if (
      this.externalAudit.postBackModalName &&
      this.externalAudit.postBackModalName.length > 0 &&
      !externalModal
    ) {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'consolidated-report',
        data: { profile: this.id, silentPrint: true },
        minWidth: '80vw',
        minHeight: '95vh',
        maxHeight: '95vh',
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        this.rbs
          .extPostbackData(this.id, result, customReportSolution)
          .subscribe(x => {
            if (x && x.results != null && x.results.length > 0) {
              const link = x.results;
              this.loading = false;
              window.open(link, newTab);
            } else {
              let dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content:
                    'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
                this.loading = false;
              });
            }
          });
      });
    } else {
      if (silentPrint) {
        this.rbs.getReport(this.id, false, silentPrint).subscribe(() => {
          this.rbs.extPostback(this.id, customReportSolution).subscribe(x => {
            if (
              x &&
              x.results != null &&
              x.results.length > 0 &&
              x.results.includes('http')
            ) {
              const link = x.results;
              this.loading = false;
              window.open(link, newTab);
            } else {
              const message = x.errorMessage
                ? x.errorMessage
                : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

              let dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content: message,
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
                this.loading = false;
              });
            }
          });
        });
      } else {
        this.rbs.extPostback(this.id, customReportSolution).subscribe(x => {
          if (
            x &&
            x.results != null &&
            x.results.length > 0 &&
            x.results.includes('http')
          ) {
            const link = x.results;
            this.loading = false;
            window.open(link, newTab);
          } else {
            const message = x.errorMessage
              ? x.errorMessage
              : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

            let dialogRef = this.dialog.open(WarnDialogComponent, {
              panelClass: 'warn-dialog',
              data: {
                headline: 'Warning',
                content: message,
                confirm: 'OK',
                hideCancel: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              dialogRef = null;
              this.loading = false;
            });
          }
        });
      }
    }
  }

  goCrossApp(app) {
    const pb = this.crossApp.includePostBack || null;
    const silentPrint = this.crossApp.silentPrint || null;

    if (silentPrint && pb) {
      this.loading = true;
      this.rbs.getReport(this.id, null, silentPrint).subscribe(() => {
        this.rbs.postback(this.id).subscribe(() => {
          this.loading = false;
          this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
        });
      });
    } else if (pb && !silentPrint) {
      this.loading = true;
      this.rbs.postback(this.id).subscribe(x => {
        this.loading = false;
        this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
      });
    } else if (silentPrint && !pb) {
      this.loading = true;
      this.rbs.getReport(this.id, null, silentPrint).subscribe(() => {
        this.loading = false;
        this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
      });
    } else {
      this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
    }
  }

  handleReportEvent(ev) {
    switch (ev) {
      case 'rollover':
        this.rolloverReport(ev);
        break;
      default:
        this.report(ev);
        break;
    }
  }

  returnZero() {
    return 0;
  }
}
