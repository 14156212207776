import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
})
export class ActionDialogComponent implements OnInit {
  action = { type: '2335', notes: null, futureLikes: false, date: null };
  header = 'I Like It';
  follow_up_type = '2120';

  constructor(
    public dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const future = new Date();
    future.setDate(future.getDate() + 30);
    this.action.date = future;
    if (this.data.action === 'followUp') {
      this.header = 'Follow Up';
      this.action.type = '2155';
    }
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    this.dialogRef.close({ data: this.action });
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
