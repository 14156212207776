import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  OnInit,
  Attribute,
} from '@angular/core';
import { PermissionsService } from '../services/permissions.service';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
  private permissions: any[] = [];
  // private logicalOp = 'AND';
  private isHidden = true;
  private permList = [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private perms: PermissionsService
  ) {}

  ngOnInit() {}

  @Input()
  set hasPermission(val) {
    if (typeof val === 'boolean') {
      this.permissions = [val];
    } else {
      this.permissions = val.split(',');
    }
    this.perms.currentPerms().subscribe(x => {
      this.permList = x;
      this.updateView();
    });
  }
  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    const hasPermission = this.permissions.some(x => {
      const hasPermission = false;
      const negCheck = new RegExp(/^\!/);
      const negative = negCheck.test(x);

      let formatted = x;
      formatted = negative ? formatted.replace('!', '') : formatted;
      if (this.permList && !hasPermission) {
        const permissionFound = this.permList.includes(formatted);
        if (!permissionFound && negative) {
          return true;
        } else if (permissionFound && !negative) {
          return true;
        } else {
          return false;
        }
      }
    });
    return hasPermission;
  }
}
