import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { AdminService } from '../../../admin/admin.service';

import { cloneDeep } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaskService } from '../../../services/mask.service';

@Component({
  selector: 'app-form-data-grid',
  styleUrls: ['form-dataGrid.component.scss'],
  templateUrl: `./form-dataGrid.component.html`,
})
export class FormlyDataGridComponent extends FieldType implements OnInit {
  @ViewChild(GridComponent, { static: true })
  grid: GridComponent;
  gridData;
  columns;
  loading = true;
  gridForm: FormGroup = new UntypedFormGroup({});
  private fields;
  private editedRowIndex: number;
  rowSelected = args => this.findSelectColumn(args.dataItem);
  selectColumn;
  hidden = [];

  dollarMask = this.mask.dollarMaskSpecs();
  gridHeight = 500;
  scrollMode = 'scrollable';

  constructor(
    private adminSrvc: AdminService,
    private snacky: MatSnackBar,
    private mask: MaskService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    if (
      this.props.gridId == 'spiadiaproductgrid' ||
      this.props.gridId == 'faproductgrid' ||
      this.props.gridId == 'productgrid'
    ) {
      this.gridHeight = 2000;
      this.scrollMode = 'virtual';
    }

    this.adminSrvc
      .getGridData(
        this.props.gridId,
        this.props.context.unit,
        this.props.context.app
      )
      .subscribe(x => {
        this.gridData = x.results.data;
        this.columns = x.results.headers;

        const selector = this.columns.find(z => z.IsLink);
        if (selector) {
          this.selectColumn = selector.DataField;
          this.columns.find(z => z.IsLink).Hidden = true;
        }

        const subgrid = this.columns.find(z => z.subgrid);

        if (subgrid) {
          this.gridData.forEach(el => {
            el.subgrid = {};
            el.subgridDef = subgrid;
          });
        }

        // this.fields = this.gridData ? Object.keys(this.gridData[0]) : [];

        this.setColumnVisiblity();

        this.loading = false;

        this.ref.detectChanges();
      });
  }

  findSelectColumn(row) {
    return row[this.selectColumn];
  }

  setColumnVisiblity() {
    this.columns.forEach(x => {
      switch (x.RespVis) {
        case 'xs':
          x.RespVis = '';
          break;
        case 'sm':
          x.RespVis = '(min-width: 700px)';
          break;
        case 'md':
          x.RespVis = '(min-width: 1100px)';
          // this.hideColumn(x.DataField);
          break;
        case 'lg':
          x.RespVis = '(min-width: 1200px)';
          this.hideColumn(x.DataField);
          break;
        case 'xl':
          x.RespVis = '(min-width: 1500px)';
          this.hideColumn(x.DataField);
          break;
      }

      if (x.Hidden) {
        this.hidden.push(x.DataField);
      }
    });

    this.hidden.forEach(x => {
      this.hideColumn(x);
    });

    // this.resultsGrid.autoFitColumns();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
      // this.setStorage();
    }
  }

  onSelect(e) {
    e.selectedRows.map(item => {
      item.dataItem[this.selectColumn] = true;
      return item;
    });

    e.deselectedRows.map(item => {
      item.dataItem[this.selectColumn] = false;
      return item;
    });

    this.saveCurrent();
  }

  selectionEvent(ev, rowIdx, source) {
    this.gridData[rowIdx][source] = ev.value;
    this.saveCurrent();
  }

  textChange(ev, rowIdx, source, data) {
    const payload = cloneDeep(data);

    const inputValueChanged: Subject<string> = new Subject<string>();
    // debounce filter text changes
    if (inputValueChanged.observers.length === 0 || ev.key == 'Tab') {
      inputValueChanged
        .pipe(
          debounceTime(500)
          // distinctUntilChanged()
        )
        .subscribe(() => {
          payload[source] = isNaN(+ev.target.value)
            ? ev.target.value
            : +ev.target.value;
          data[source] = isNaN(+ev.target.value)
            ? ev.target.value
            : +ev.target.value;
          this.adminSrvc
            .saveGridData(
              this.props.gridId,
              this.props.context.unit,
              this.props.context.app,
              { data: [payload] }
            )
            .subscribe(() => {
              this.snacky.open('Unit Profile has been saved', 'Close', {
                duration: 2000,
              });
            });
        });
    }
    inputValueChanged.next(ev.target.value);
  }

  saveCurrent() {
    this.adminSrvc
      .saveGridData(
        this.props.gridId,
        this.props.context.unit,
        this.props.context.app,
        { data: this.gridData }
      )
      .subscribe(() => {
        this.snacky.open('Unit Profile has been saved', 'Close', {
          duration: 2000,
        });
      });
  }

  onExpandHandler(ev) {
    if (ev.dataItem) {
      this.adminSrvc
        .getGridData(
          ev.dataItem.subgridDef.subgrid,
          this.props.context.unit,
          this.props.context.app,
          ev.dataItem[ev.dataItem['subgridDef'].DataField]
        )
        .subscribe(x => {
          this.gridData.find(
            y =>
              y.AnnuityProduct$AnnuityID ===
              ev.dataItem.AnnuityProduct$AnnuityID
          ).subgrid = x.results;
          this.grid.detailExpand.emit(ev.index);
        });
    }
  }

  subRowSelected(ev, data, subgrid) {
    this.adminSrvc
      .saveGridData(
        subgrid.subgrid,
        this.props.context.unit,
        this.props.context.app,
        { data: [data] }
      )
      .subscribe(() => {
        this.snacky.open('Unit Profile has been saved', 'Close', {
          duration: 2000,
        });
      });
  }

  showSubgrid(dataItem: any, index: number): boolean {
    return dataItem.subgrid;
  }

  isSelectable() {
    return this.selectColumn && this.selectColumn.length > 0;
  }

  removeMoneyMask(obj) {
    if (obj) {
      return Number(obj.replace(/\,/gi, '').replace('$', ''));
    }
  }

  exportToExcel(grid: GridComponent): void {
    this.loading = true;
    const newGrid = cloneDeep(grid);
    newGrid.saveAsExcel();
    this.loading = false;
  }

  // exportToExcel(grid: GridComponent): void {
  //   grid.saveAsExcel();
  // }

  public excelData = () => {
    let fullData = cloneDeep(this.gridData);

    if (
      this.props.filterExcel &&
      this.props.filterExcel.toLowerCase() == 'selected'
    ) {
      const selector = this.columns.find(z => z.IsLink);
      fullData = fullData.filter(x => x[selector.DataField]);
    } else {
      fullData = this.gridData;
    }

    return process(fullData, {});
  };
}
