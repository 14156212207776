import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  DoCheck,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormlyFieldConfig, FieldArrayType } from '@ngx-formly/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { InvestmentWizardService } from '../../../investment-wizard/services/investment-wizard-service.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import { FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { WarnDialogComponent } from '../../../warn-dialog/warn-dialog.component';

@Component({
  selector: 'app-form-grid-expand',
  templateUrl: './form-gridExpand.component.html',
  styleUrls: ['form-gridExpand.component.scss'],
})
export class FormlyGridExpandComponent
  extends FieldArrayType
  implements OnInit, DoCheck
{
  constructor(
    private iws: InvestmentWizardService,
    private ss: SessionStorageService,
    private changeDetector: ChangeDetectorRef,
    private snacky: MatSnackBar,
    private dialog: MatDialog
  ) {
    super();
  }

  @ViewChild('gridExp', { static: true }) public grid: GridComponent;

  columns;
  subFields;
  gridData;
  loading = false;
  noRecords = 'Please Add an Entry';
  searchOptions = null;
  showTemplates = false;
  placeholder = 'Select a template to apply';
  label = 'Select a template to apply';
  templates;
  firmTemplates;
  program;
  buttonOption;
  hideDelete = false;
  rights = this.ss.get('rights');
  totalInvestment = 0;
  assetAmount = '$0';
  mode = 'amount';
  totalPercent = 0;
  showTotals = false;
  totalsCondit;
  idVar;
  parentIdVar;
  needsRowId = false;
  rowIdPrefix;
  rowId;
  investmentProgramLabel = '';
  fiveTwoNineSearch = false;
  plans529 = [];
  selected529;
  uploadingFile = false;

  public expandedDetailKeys = [];

  public expandDetailsBy = dataItem => {
    return dataItem ? dataItem.CurrentPosition_FundID : 0;
  };
  public fileName: string;
  public file: File;

  ngDoCheck() {
    this.field.options.checkExpressions?.(this.field);
  }

  ngOnInit() {
    this.onPopulate(this.field);

    const parentVars = this.field.parent.fieldGroup?.map(x => x.key);
    this.parentIdVar = parentVars?.find(
      (x: string) => x.includes('SourceBridgeID') || x.includes('TSPSourceID')
    ) as string;

    const vars = this.field.fieldArray['fieldGroup']?.map(x => x.key);
    this.idVar = vars?.find(
      (x: string) => x.includes('SourceBridgeID') || x.includes('TSPSourceID')
    ) as string;

    if (this.idVar) {
      this.needsRowId = true;
      this.rowIdPrefix = this.idVar.split('_')[0];
      this.rowId = this.field.parent.model[this.parentIdVar];
    }
    this.buttonOption = this.field.fieldArray['props'].btnText;
    this.showTemplates =
      this.field.fieldArray['props'].showTemplates &&
      this.rights.includes('IWTemplates');
    this.searchOptions = this.field.fieldArray['props'].search;
    this.columns = this.field.fieldArray['fieldGroup']?.filter(el => {
      if (!el.props.subField) {
        return true;
      }
    });
    this.subFields = this.field.fieldArray['fieldGroup']?.filter(el => {
      if (el.props.subField) {
        return true;
      }
    });

    this.columns = this.columns?.map(el => {
      const fld = {
        label: el.props.label || el.props.placeholder,
        key: el.key,
        help: el.props.help,
        visuallyHidden: el.className.includes('visual-hidden'),
        hide: el.expressions.hide(),
      };
      return fld;
    });

    if (this.model) {
      this.model.map(fund => {
        if (fund.CurrentPosition_Sell) {
          const fundList = [...this.expandedDetailKeys];
          fundList.push(fund.CurrentPosition_FundID);
          this.expandedDetailKeys = fundList;
        }
      });
    }

    this.totalsCondit = this.field.fieldArray['props']['showTotals'];
    this.showTotals = eval(this.totalsCondit);

    this.hideDelete = this.field.fieldArray['props'].hideDelete;

    this.noRecords = this.field.props.placeholder
      ? this.field.props.placeholder
      : this.noRecords;

    if (this.showTemplates) {
      this.getTemplates(
        this.field.parent.model.ValidateProduct.InvestmentValidation_Program
      );
    }

    if (this.searchOptions) {
      if (this.searchOptions.fundtype.includes('529')) {
        this.fiveTwoNineSearch = true;
        this.get529();
      }

      this.program =
        this.field.parent.model.ValidateProduct?.InvestmentValidation_Program;
      if (
        this.field.parent.model.UNITPushToUI_NonFilteredPrograms &&
        this.field.parent.model.UNITPushToUI_NonFilteredPrograms.includes(
          this.field.parent.model.ValidateProduct.InvestmentValidation_Program
        )
      ) {
        this.program = null;
      }

      this.field.form.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(z => {
          this.program = z.ValidateProduct?.InvestmentValidation_Program;
          if (this.program) {
            this.getTemplates(z.ValidateProduct.InvestmentValidation_Program);
          }

          if (
            this.field.parent.model.UNITPushToUI_NonFilteredPrograms &&
            this.field.parent.model.UNITPushToUI_NonFilteredPrograms.includes(
              this.field.parent.model.ValidateProduct
                .InvestmentValidation_Program
            )
          ) {
            this.program = null;
          }
          this.assetAmount = z.ValidateProduct?.InvestmentWizard_AssetAmount;
          this.totalInvestment = 0;
          this.totalPercent = 0;

          if (z.SelectedFunds) {
            z.SelectedFunds.forEach(fnd => {
              if (fnd.SelectedFunds_Amount) {
                this.totalInvestment += this.makeNumber(
                  fnd.SelectedFunds_Amount
                );
              } else if (fnd.SelectedFunds_Percent) {
                this.totalPercent =
                  this.totalPercent +
                  +fnd.SelectedFunds_Percent.replace('%', '') / 100;
              }
            });
          }

          if (z.InvestmentValidation_PickPctOrAmt == 'P') {
            this.mode = 'percent';
          } else {
            this.mode = 'amount';
          }

          this.showTotals = eval(this.totalsCondit);
        });
    }
    this.investmentProgramLabel =
      this.field.parent.model.ProgramDefinition_DisplayName &&
      this.field.parent.model.ProgramDefinition_ProgramID
        ? this.field.parent.model.ProgramDefinition_DisplayName[
            this.field.parent.model.ProgramDefinition_ProgramID.indexOf(
              this.field.parent.model.ValidateProduct
                .InvestmentValidation_Program
            )
          ]
        : '';

    this.field.options.build(this.field);
  }

  add(fund?, amount?, percent?) {
    super.add();
    const row = this.model.length - 1;

    if (this.needsRowId) {
      const rowId = this.getField(this.field, { key: this.idVar }, row);
      rowId.formControl.setValue(this.rowId);
    }

    if (fund) {
      const fundId = this.columns?.find(x => x.key.includes('FundID'))?.key;
      const fundIdField = this.getField(this.field, { key: fundId }, row);
      const fundName = this.columns?.find(x => x.key.includes('Name'))?.key;
      const fundNameField = this.getField(this.field, { key: fundName }, row);
      const expenseRatio = this.columns?.find(x =>
        x.key.includes('ExpenseRatio')
      )?.key;
      const expenseRatioField = this.getField(
        this.field,
        { key: expenseRatio },
        row
      );
      const shareClass = this.columns?.find(x => x.key.includes('ShareClass'))
        ?.key;
      const shareClassField = this.getField(
        this.field,
        { key: shareClass },
        row
      );
      const fundType = this.columns?.find(x => x.key.includes('FundType'))?.key;
      const fundTypeField = this.getField(this.field, { key: fundType }, row);

      if (fundIdField) {
        fundIdField.formControl.setValue(fund.FundID);
        setTimeout(() => {
          fundIdField.model[fundId] = fund.FundID;
        });
      }

      if (fundNameField) {
        fundNameField.formControl.setValue(fund.FundName);
      }

      if (expenseRatioField) {
        expenseRatioField.formControl.setValue(fund.ExpenseRatio);
      }

      if (shareClassField) {
        shareClassField.formControl.setValue(fund.ShareClass);
      }

      if (fundTypeField) {
        fundTypeField.formControl.setValue(fund.FundType);
        setTimeout(() => {
          fundTypeField.model[fundType] = fund.FundType;
        });
      }
    }

    if (amount) {
      const fundAmount = this.columns?.find(x => x.key.includes('Amount')).key;
      const fundAmountField = this.getField(
        this.field,
        { key: fundAmount },
        row
      );

      if (fundAmountField) {
        fundAmountField.formControl.setValue(amount);
      }

      this.totalInvestment += +amount;
    }

    if (percent) {
      const fundPercent = this.columns?.find(x => x.key.includes('Percent'))
        .key;
      const fundPercentField = this.getField(
        this.field,
        { key: fundPercent },
        row
      );

      if (fundPercentField) {
        fundPercentField.formControl.setValue(`${percent}%`);
      }

      this.totalInvestment +=
        this.makeNumber(
          this.field?.parent.model.ValidateProduct.InvestmentWizard_AssetAmount
        ) *
        (percent / 100);
      this.totalPercent =
        this.makeNumber(
          this.field?.parent.model.ValidateProduct.InvestmentWizard_AssetAmount
        ) / this.totalInvestment;
    }
    this.focusCell(row, 1);

    this.changeDetector.detectChanges();
  }

  remove(idx, ev?) {
    if (ev) {
      ev.preventDefault();
    }
    const localModel = [...this.model];
    const deletedFund = localModel[idx];
    const model = this.field.parent?.parent?.parent?.model;
    const key = this.key as string;
    const varSet = this.columns && this.columns[0].key.split('_')[0];
    const fundId = `${varSet}_FundID`;
    const idVar = this.idVar ? this.idVar.split('_')[1] : null;
    const sourceBridge = `${varSet}_${idVar}`;
    const fundIdx = model[key]
      ? model[key].findIndex(
          x => x[fundId] == deletedFund[fundId] && x[sourceBridge] == this.rowId
        )
      : -999;

    if (
      fundIdx > -1 &&
      this.field.fieldArray &&
      this.field.fieldArray['props'].nested
    ) {
      model[key].splice(fundIdx, 1);
    }
    super.remove(idx);
  }

  focusCell(row, cell) {
    if (this.grid) {
      this.grid.focusCell(row, cell);
    }
  }

  getField(
    field: FormlyFieldConfig,
    column,
    rowIndex: number
  ): FormlyFieldConfig {
    return field.fieldGroup[rowIndex]
      ? field.fieldGroup[rowIndex].fieldGroup.find(f => f.key === column.key)
      : { hide: false };
  }

  hideColumn(
    field: FormlyFieldConfig,
    column,
    rowIndex: number
  ): FormlyFieldConfig {
    let fieldVisible;
    if (field.formControl instanceof FormArray) {
      fieldVisible = field.fieldArray['fieldGroup']
        ? field.fieldArray['fieldGroup'].find(f => f.key === column.key)
        : false;
    } else {
      fieldVisible = field.fieldGroup[rowIndex]
        ? field.fieldGroup[rowIndex].fieldGroup.find(f => f.key === column.key)
        : false;
    }

    return fieldVisible.expressions.hide()
      ? fieldVisible.expressions.hide()
      : false;
  }

  getModel(model, rowIndex): any {
    return model[rowIndex];
  }

  handleFund(ev) {
    this.add(ev.option.value);
  }

  applyTemplate(ev) {
    if (this.field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount) {
      const investment =
        +this.field.parent.model.ValidateProduct.InvestmentWizard_AssetAmount.replace(
          '$',
          ''
        ).replaceAll(',', '');
      const funds = ev.value.funds;

      let fundList = '';
      funds.forEach((x, i) => {
        if (i != funds.length - 1) {
          fundList += `${x.ticker},`;
        } else {
          fundList += x.ticker;
        }
      });

      const formattedFunds = {};
      let fundInfo;
      const symbolList = [];

      this.iws.fundInfo(fundList).subscribe(x => {
        fundInfo = x.results;
        fundInfo.forEach(el => {
          if (!symbolList.includes(el.Symbol)) {
            const fnd = {};
            const info = funds.find(x => {
              return x.ticker == el.Symbol;
            });

            const perc = info.percent * 100;

            const amount = investment * (perc / 100);
            fnd['FundName'] = el.FundName;
            fnd['FundID'] = info.ticker;
            fnd['ExpenseRatio'] = +el.ExpenseRatio;

            symbolList.push(el.Symbol);

            this.add(fnd, amount.toFixed(2), perc);
          }
        });
      });
    }
  }

  getTemplates(program) {
    this.iws.getTemplates().subscribe(x => {
      const userTemplates = x.results.UserTemplates;
      const firmTemplates = x.results.FirmTemplates;

      this.templates =
        userTemplates && userTemplates.length > 0
          ? userTemplates.filter(z => z.program == program)
          : [];
      this.firmTemplates =
        firmTemplates && firmTemplates.length > 0
          ? firmTemplates.filter(z => z.program == program)
          : [];
    });
  }

  templateChosen(ev) {}

  get529() {
    this.iws.get529().subscribe(x => {
      this.plans529 = x.data;
    });
  }

  handle529(ev) {
    if (this.selected529) {
      const planId = this.field.parent.fieldGroup.find(
        x => x.key == 'Selected529PlansArray_PlanID'
      )?.formControl;
      const planName = this.field.parent.fieldGroup.find(
        x => x.key == 'Selected529PlansArray_PlanName'
      )?.formControl;

      if (planId.value != this.selected529.PlanId) {
        const length = this.model.length;
        for (let index = 0; index < length; index++) {
          this.remove(index);
        }
      }

      if (planId) {
        planId.setValue(this.selected529.PlanId);
      }
      if (planName) {
        planName.setValue(this.selected529.PlanName);
      }
    }
  }

  makeNumber(num) {
    const formatted = num.toString().replace('$', '').replaceAll(',', '');
    return +formatted;
  }

  changeSell(row, field, column, rowIndex) {
    const expandID = this.model[row].CurrentPosition_FundID;
    const idList = [...this.expandedDetailKeys];
    const theField = this.getField(field, column, rowIndex);

    if (this.expandedDetailKeys.includes(expandID)) {
      idList.splice(
        this.expandedDetailKeys.findIndex(key => key === expandID),
        1
      );
      this.expandedDetailKeys = idList;
      this.model[row]['CurrentPosition_Sell'] = false;
      theField.formControl.setValue(false);
    } else {
      idList.push(expandID);
      this.expandedDetailKeys = idList;
      this.model[row]['CurrentPosition_Sell'] = true;
      theField.formControl.setValue(true);
    }
  }

  sellChecked(row) {
    const expandID = this.model[row]
      ? this.model[row].CurrentPosition_FundID
      : null;
    if (this.expandedDetailKeys.includes(expandID)) {
      return true;
    } else {
      return false;
    }
  }

  onFileSelected(event) {
    if (
      event.target.files[0].type === 'text/csv' ||
      event.target.files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.file = event.target.files[0];

      if (this.file) {
        this.fileName = this.file.name;
      }
    } else {
      this.snacky.open(`Warning! File type not supported.`, 'Close', {
        duration: 4000,
      });
    }
  }

  completeCsvUpload() {
    const formData = new FormData();
    formData.append('Spreadsheet', this.file);
    this.uploadingFile = true;
    this.iws.uploadHoldingsFile(formData).subscribe(({ details, data }) => {
      if (details.FileUploadSuccess === true && data) {
        this.fileName = null;
        this.file = null;
        data.map(fundObj => {
          this.add(fundObj, fundObj.Amount);
        });
        this.uploadingFile = false;
        if (
          details.InvestmentsErrorProcess.length <= 0 &&
          details.InvestmentsNotFound.length <= 0 &&
          !details.InvestmentFailureDetails
        ) {
          this.snacky.open(`File uploaded successfully.`, 'Close', {
            duration: 4000,
          });
        } else {
          this.dialog.open(WarnDialogComponent, {
            panelClass: 'warn-dialog',
            data: {
              headline: 'Warning',
              content: `${details.InvestmentFailureDetails} </br></br> Process: ${details.InvestmentsErrorProcess[0]} </br> Investment Not Found: ${details.InvestmentsNotFound[0]}`,
              confirm: 'OK',
            },
          });
        }
      }
    });
  }

  downloadCsvTemplate(ev) {
    ev.preventDefault();
    window.open('assets/IW-Import-Holdings-template.csv', '_blank');
  }
}
