<h5>Filter Templates</h5>
<div class="edit-save-fitlers">
  <mat-form-field class="w-25 mr-2">
    <mat-label>
      @if (loading) {
        Loading Filters...
      } @else if (!loading && savedFilters.length < 1) {
        No saved filters
      } @else {
        Saved Filters
      }
    </mat-label>
    <mat-select
      (selectionChange)="templateSelected($event)"
      [(ngModel)]="currentSelectedSavedFilter"
      panelClass="saved-template-select"
      [disabled]="!savedFilters || savedFilters.length < 1"
    >
      @if (savedUserFilters && savedUserFilters.length > 0) {
        <div class="saved-filter-user-title"><span>Your Filters</span></div>
        @for (filter of savedUserFilters; track $index) {
          <mat-option [value]="filter"
            >{{ filter.Name }}
            <button
              mat-icon-button
              (click)="
                deleteFilter($event, filter, savedUserFilters, null, $index)
              "
            >
              <mat-icon>delete</mat-icon>
            </button></mat-option
          >
        }
      }
      @if (savedManagerFilters && savedManagerFilters.length > 0) {
        <div class="saved-filter-manager-title"
          ><span>Manager Filters</span></div
        >
        @for (item of savedManagerFilters; track item) {
          <div class="saved-filter-manager-subtitle"
            ><span>{{ item.unit }} Filters</span></div
          >
          @for (filter of item.filters; track $index) {
            <mat-option [value]="filter"
              >{{ filter.Name }}
              @if (canSaveEditUnitFilters) {
                <button
                  mat-icon-button
                  (click)="
                    deleteFilter(
                      $event,
                      filter,
                      savedManagerFilters,
                      item,
                      $index
                    )
                  "
                >
                  <mat-icon>delete</mat-icon>
                </button>
              }
            </mat-option>
          }
        }
      }
      @if (savedGlobalFilters && savedGlobalFilters.length > 0) {
        <div class="saved-filter-global-title"
          ><span>Filters Shared with You</span></div
        >
        @for (filter of savedGlobalFilters; track $index) {
          <mat-option [value]="filter"
            >{{ filter.Name }}
            @if (canSaveEditUnitFilters) {
              <button
                mat-icon-button
                (click)="
                  deleteFilter($event, filter, savedGlobalFilters, null, $index)
                "
              >
                <mat-icon>delete</mat-icon>
              </button>
            }
          </mat-option>
        }
      }
      <mat-select-trigger>{{ newFilterName }}</mat-select-trigger>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="launchFilterModal('save')">
    @if (filterID) {
      Save/Update Filter
    } @else {
      Save New Filter
    }
  </button>
</div>

<ng-template #manageFiltersDialog>
  @if (modalAction && modalAction === 'save') {
    <div mat-dialog-header>
      <h1>Save Filter</h1>
    </div>
    <div mat-dialog-content class="dialog-container">
      <!--  //possible toggle switch below. Didn't like how it turned out
          //and don't want to spend more time on it right now. Leaving it for future work.
          //(check scss file for necessary styling on this) -->

      @if (
        filterID &&
        ((orgUnitFilterSelected && canSaveEditUnitFilters) ||
          !orgUnitFilterSelected)
      ) {
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="saveMethod"
          [formControl]="overwriteSaveControl"
          [required]="true"
        >
          <mat-radio-button value="overwrite"
            >Overwrite Currently Selected Filter</mat-radio-button
          >
          <mat-radio-button value="save">Save New Filter</mat-radio-button>
        </mat-radio-group>
        @if (
          overwriteSaveControl.hasError('required') &&
          overwriteSaveControl.touched
        ) {
          <mat-error> This field is required </mat-error>
        }
      }

      @if (filterID && orgUnitFilterSelected && !canSaveEditUnitFilters) {
        <div
          >The currently selected filter template is managed by your unit. You
          do not have permission to edit the selection. You can save the
          selected template (along with any changes) as a personal filter.</div
        >
      }

      <div class="name-field-container">
        <mat-form-field class="w-100">
          <mat-label>Filter Name</mat-label>
          <input
            matInput
            type="text"
            [formControl]="newFilterNameControl"
            [(ngModel)]="newFilterName"
          />
          @if (newFilterNameControl.hasError('maxlength')) {
            <mat-error align="start"
              >Name must be 50 characters or less</mat-error
            >
          }
          <mat-hint align="start">{{ newFilterName.length }} / 50</mat-hint>
          @if (newFilterName) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="newFilterName = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>
      </div>
      <div class="filter-list-container">
        @if (this.currentSelectedFilters.filters.length > 0) {
          <h3>Currently Selected Field(s)</h3>
          <table class="w-100">
            <thead>
              <th>Filter</th>
              <th>Selection</th>
            </thead>
            <tbody>
              @for (
                filterItem of currentSelectedFilters.filters;
                track filterItem
              ) {
                <tr>
                  <td>{{ filterItem.label }}</td>
                  <td>{{ filterItem.display }}</td>
                </tr>
              }
            </tbody>
          </table>
        } @else {
          <h3>No Fields Selected</h3>
        }
      </div>
      @if (canSaveEditUnitFilters) {
        <div class="w-50">
          <div>Save Filter as...</div>
          <mat-radio-group
            [(ngModel)]="unitScope"
            [formControl]="unitTypeControl"
            [required]="true"
          >
            <mat-radio-button [value]="'user'">Personal</mat-radio-button>
            <mat-radio-button [value]="'manager'"
              >Managers Only</mat-radio-button
            >
            <mat-radio-button [value]="'global'">All Users</mat-radio-button>
          </mat-radio-group>
          @if (unitScope === 'manager' || unitScope === 'global') {
            <mat-form-field class="mr-2 w-100 user-select">
              <mat-label>Unit</mat-label>
              <input
                type="text"
                [placeholder]="'Unit'"
                matInput
                [matAutocomplete]="autoUnit"
                [formControl]="unitSearchControl"
              />
              <mat-autocomplete
                #autoUnit="matAutocomplete"
                (optionSelected)="changeSelection($event)"
              >
                @for (opt of unitList; track opt.value) {
                  <mat-option value="{{ opt.value }}">
                    {{ opt.value }}: {{ opt.display }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-hint
                >Note: Please enter a valid unit. Filter will be availble to
                users in the unit selected.</mat-hint
              >
            </mat-form-field>
          }
        </div>
      }
    </div>
    <div mat-dialog-actions class="row buttons">
      <button
        mat-raised-button
        style="margin-left: auto"
        (click)="close(); $event.preventDefault()"
        >Cancel</button
      >
      <button
        mat-raised-button
        style="margin-left: 10px"
        color="accent"
        (click)="submitData('save'); $event.preventDefault()"
        [disabled]="isDisabled()"
        >Save</button
      >
    </div>
  }
  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: false }"
  ></ngx-loading>
</ng-template>
