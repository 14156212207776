import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-speech-to-text',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './speech-to-text.component.html',
  styleUrl: './speech-to-text.component.scss',
})
export class SpeechToTextComponent implements OnChanges {
  @Input() typedText: string;
  @Input() cursorPosition: number;
  @Output() text = new EventEmitter();
  @Output() noteSpeechError = new EventEmitter();
  micButtonStatus = 'disabled';
  insertTextAt: number;
  isListening = false;
  newSpokenText = false;
  noteError = false;
  rawError: string;
  recognition;
  speechArray = [];
  SpeechRecognition;
  spokenText: string;

  constructor(private win: Window) {
    this.voiceSetup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.typedText &&
      typeof changes.typedText.currentValue === 'string'
    ) {
      this.spokenText = changes.typedText.currentValue;
    }
  }

  toggleListening() {
    if (this.isListening) {
      this.micButtonStatus = 'disabled';
      this.stopListening();
    } else {
      this.insertTextAt = this.cursorPosition;
      this.micButtonStatus = 'primary';
      this.startListening();
    }
  }

  startListening() {
    this.newSpokenText = false;
    this.noteError = false;
    this.recognition.start();
  }

  stopListening() {
    this.recognition.stop();
  }

  getParsedSpeech() {
    this.speechArray = [];
    return this.spokenText;
  }

  getError() {
    console.error(this.rawError);
    if (this.rawError === 'not-allowed') {
      return `Microphone permission denied for this site. Please check the permission settings for your browser.`;
    }
    return `There was an error converting speech to text. Please try again.`;
  }

  parseSpeech(event) {
    this.speechArray.push(
      event.results[event.results.length - 1][0].transcript
    );

    for (let i = 0; i < this.speechArray.length; i++) {
      this.speechArray[i] = this.speechArray[i].replaceAll(' comma ', ', ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' comma', ',');
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' exclamation point ',
        '! '
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' exclamation point',
        '!'
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(' period ', '. ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' period', '.');
      this.speechArray[i] = this.speechArray[i].replaceAll(' full stop ', '. ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' full stop', '.');
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' question mark ',
        '? '
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(
        ' question mark',
        '?'
      );
      this.speechArray[i] = this.speechArray[i].replaceAll(' percent ', '% ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' percent', '%');
      this.speechArray[i] = this.speechArray[i].replaceAll(' colon ', ': ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' colon', ':');
      this.speechArray[i] = this.speechArray[i].replaceAll(' semicolon ', '; ');
      this.speechArray[i] = this.speechArray[i].replaceAll(' semicolon', ';');
    }
    const spokenTextLetters = Array.from(this.speechArray.join(' '));
    const fullTextLetters = this.spokenText ? Array.from(this.spokenText) : [];

    if (
      this.insertTextAt === fullTextLetters.length &&
      fullTextLetters[fullTextLetters.length - 1] !== ' '
    ) {
      fullTextLetters.push(' ');
      this.insertTextAt = fullTextLetters.length;
    }

    for (let i = spokenTextLetters.length - 1; i > -1; i--) {
      const letter = spokenTextLetters[i];
      fullTextLetters.splice(this.insertTextAt, 0, letter);
    }
    this.spokenText = fullTextLetters.join('');
    this.speechArray = [];
    this.text.emit(this.spokenText);
  }

  voiceSetup() {
    this.SpeechRecognition =
      this.win['SpeechRecognition'] || this.win['webkitSpeechRecognition'];
    this.recognition = new this.SpeechRecognition();
    this.recognition.lang = 'en-US';

    this.recognition.onstart = () => {
      this.micButtonStatus = 'primary';
      this.noteSpeechError.emit(null);
      this.isListening = true;
    };
    this.recognition.onend = () => {
      this.micButtonStatus = 'disabled';
      this.isListening = false;
    };
    this.recognition.onerror = error => {
      this.rawError = error.error;
      this.spokenText = '';
      this.newSpokenText = false;
      this.noteError = true;
      this.isListening = false;
      this.noteSpeechError.emit(this.getError());
    };
    this.recognition.onresult = event => {
      this.micButtonStatus = 'disabled';
      this.parseSpeech(event);
      this.newSpokenText = true;
    };
  }
}
