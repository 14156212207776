import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import {
  distinctUntilChanged,
  map,
  switchMap,
  startWith,
} from 'rxjs/operators';
import { ReportingService } from '../../reporting.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-sales-tracking',
  templateUrl: './sales-tracking.component.html',
  styleUrls: ['./sales-tracking.component.scss'],
})
export class SalesTrackingComponent implements OnInit {
  salesUnitsControl = new FormControl();

  selectedUnit;
  sales = {
    unit: null,
    user: null,
    at: 'ALL',
    st: 'ALL',
    ri: 'ALL',
    gap: 720,
    startDate: null,
    endDate: null,
  };
  filteredUnits;
  activityTypes = ['ALL', 'BA', 'LK'];
  saleTypes = ['ALL', 'LI', 'IN', 'OP', 'AN', 'IV'];
  repIntersectTypes = ['ALL', 'True', 'False'];
  salesTrackingData = { data: null, headers: null };

  constructor(
    private admnSvc: AdminService,
    private rptSvc: ReportingService
  ) {}

  ngOnInit(): void {
    this.getSalesUnits();
  }

  getSalesTracking() {
    this.rptSvc.getSalesTracking(this.sales).subscribe({
      next: data => {
        this.salesTrackingData = { ...data.results };
      },
      error: error => {
        console.error(error);
      },
    });
  }

  getSalesUnits() {
    this.filteredUnits = this.salesUnitsControl.valueChanges.pipe(
      startWith('%'),
      distinctUntilChanged(),
      switchMap(value => {
        if ((value && value.length > 2) || value == '%') {
          return this._filterUnits(value);
        }
        return [];
      })
    );
  }

  changeDate(ev) {
    if (ev.targetElement.className.includes('start-date')) {
      this.sales.startDate = DateTime.fromJSDate(new Date(ev.value))
        .toUTC()
        .startOf('day')
        .toISO();
    } else if (ev.targetElement.className.includes('end-date')) {
      this.sales.endDate = DateTime.fromJSDate(new Date(ev.value))
        .toUTC()
        .endOf('day')
        .toISO();
    }
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }
}
