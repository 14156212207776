import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import { DateTime } from 'luxon';
import { EnvironmentService } from '../../../services/environment.service';
import { RightBridgeApiService } from '../../../services/right-bridge-api.service';

@Component({
  selector: 'app-top-5-summary',
  templateUrl: './top5Summary.component.html',
  styleUrls: ['./top5Summary.component.scss'],
})
export class Top5SummaryComponent implements OnInit {
  @Input() set solutionId(value) {
    this.solution = value;
  }
  get solutionId() {
    return this.solution;
  }

  private environment;

  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(
    DateTime.now().toUTC().minus({ days: 30 }).startOf('day').toISO()
  );
  endDate = new FormControl(DateTime.now().toUTC().endOf('day').toISO());
  gridData = { Headers: [], Data: null };
  solution;
  casesRan = [];
  classesChanged = [];
  rights = [];
  turnOffSummaryPage = false;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private rbs: RightBridgeApiService,
    private router: Router,
    private envSvc: EnvironmentService,
    private location: Location
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.rights = this.ss.get('rights');
    this.getTop5();
  }

  dateChange() {
    this.getTop5();
  }

  getTop5() {
    const start = DateTime.fromJSDate(new Date(this.startDate.value))
      .startOf('day')
      .toISO();
    const end = DateTime.fromJSDate(new Date(this.endDate.value))
      .endOf('day')
      .toISO();
    this.dbs
      .getCaseStatsTop(this.unit, this.solution, start, end)
      .subscribe(x => {
        this.casesRan = x.results.MostCases;
        this.classesChanged = x.results.ScoreClassChanges;
      });

    this.rbs.getGroupProfile('DashboardGlobals').subscribe(x => {
      if (x.UnitInfo?.TurnOffSummaryPage) {
        this.turnOffSummaryPage = true;
      }
    });
  }

  viewUser(ev, id) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.router.navigate(['/case-explorer', { user: id.id }]);
  }

  viewCase(ev, data) {
    const { ProfileID, Solution } = data;
    const solution = Solution.toLowerCase();

    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (this.turnOffSummaryPage) {
      if (solution === 'li') {
        window.open(
          `${this.location.prepareExternalUrl(
            ''
          )}case/${solution}/${ProfileID}/validate`,
          '_blank'
        );
      }
      window.open(
        `${this.location.prepareExternalUrl(
          ''
        )}case/${solution}/${ProfileID}/results`,
        '_blank'
      );
    } else {
      window.open(
        `${this.location.prepareExternalUrl('')}case/summary/${ProfileID}`,
        '_blank'
      );
    }
  }

  viewHistory(ev, data) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    window.open(
      `${this.environment.basePath}profile-history/${data.Solution}/${data.ProfileID}`,
      '_blank'
    );
  }
}
