import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MultiDialogComponent } from '../../multi-dialog/multi-dialog.component';
import { FundLookupDialogComponent } from '../fund-lookup-dialog/fund-lookup-dialog.component';
import { CompareDialogComponent } from '../../compare-dialog/compare-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { Chart } from 'angular-highcharts';
import { SessionStorageService } from '../../services/session-storage.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { FamilyDialogComponent } from '../../family-dialog/family-dialog.component';
import { ReportDialogComponent } from '../../report-dialog/report-dialog.component';
import { switchMap, distinctUntilChanged, map } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { cloneDeep } from 'lodash';
import { EnvironmentService } from '../../services/environment.service';
import { UnifiedFlowService } from '../../unified-flow/unified.service';

@Component({
  selector: 'app-fund-allocations',
  templateUrl: './fund-allocations.component.html',
  styleUrls: ['./fund-allocations.component.scss'],
})
export class FundAllocationsComponent implements OnInit {
  @ViewChild('fundFamilySingle', { static: false }) fundFamilySelectSingle;
  @ViewChild('fundFamilyMulti', { static: false }) fundFamilySelectMulti;
  @ViewChild('symbolLookup', { static: false })
  symbolComplete: MatAutocompleteTrigger;
  @ViewChild('multiLookup', { static: false })
  multiComplete: MatAutocompleteTrigger;

  private environment;
  private baseUrl: string;

  id: string;
  program: string;
  programName: string;
  cats;
  modelChartData;
  selectedChartData;
  tableData = [];
  columns = [];
  rows = [];
  investment: number;
  selectedFunds: object = {};
  investmentTotal = 0;
  filtering = 'none';
  selectionType: string;
  fundFamilies: Array<object> = [];
  filteredFundFams: string[];
  singleFundList = [];
  modelData = { name: '' };
  loading = false;
  shareClassFilter = false;
  shareClassDefault = 'A';
  shareClass = 'A';
  shareClassChangeReason = '';
  compareList: string[] = [];
  clientName;
  initialLimit: number;
  showAll = false;
  singleFundClassFilter = 'TT';
  programInstructions: string;
  selectedSingle: string;
  path: string;
  filteredSymbols;
  filteredMulti;
  searchControl = new FormControl();
  multiSearchControl = new FormControl();
  templatesControl = new FormControl();
  selectedAllocations;
  templates;
  firmTemplates;
  assetClassColors;
  multiFundList;
  breadcrumbs = 'funds';
  programMessage;
  disableFundFamilySelect = false;

  constructor(
    public dialog: MatDialog,
    private params: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private route: Router,
    private location: Location,
    private ss: SessionStorageService,
    private tes: TagsEvaluationService,
    private iws: InvestmentWizardService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private unfFlowSvc: UnifiedFlowService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.id = params.id;
    });

    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.path = this.environment.assets ? this.environment.assets : '';

    this.getCats(this.id);
    this.symbolSearch();
    this.multiSearch();
  }

  saveFunds() {
    const formatted = [];
    Object.keys(this.selectedFunds).forEach(x => {
      this.selectedFunds[x].forEach(i => {
        i['SelectedFunds.Amount'] = +i['SelectedFunds.Amount'];
        formatted.push(i);
      });
    });

    if (this.shareClass) {
      formatted.push({
        'InvestmentWizard.SelectedShareClass': this.shareClass,
      });
    }

    if (this.filteredFundFams) {
      formatted.push({
        'InvestmentWizard.FilteredFundFamilies': this.filteredFundFams,
      });
    }
    this.rbs.selectFunds(this.id, formatted).subscribe(() => {
      this.ss.set('selectedFunds', this.selectedFunds);
    });
  }

  clearFunds(id) {
    Object.keys(this.selectedFunds[id]).forEach(fnd => {
      Object.keys(this.selectedFunds).forEach(clss => {
        if (clss != id) {
          const fndId = this.selectedFunds[id][fnd]['SelectedFunds.FundID'];
          const fundClass = this.selectedFunds[clss];
          Object.keys(fundClass).forEach((fclass, i) => {
            if (fundClass[fclass]['SelectedFunds.FundID'] == fndId) {
              fundClass.splice(i, 1);
            }
          });
        }
      });
    });

    Object.keys(this.selectedFunds).forEach(el => {
      if (this.selectedFunds[el].length < 1) {
        delete this.selectedFunds[el];
      }
    });
    delete this.selectedFunds[id];
    let summaryData = [];

    this.investmentTotal = 0;
    Object.keys(this.selectedFunds).forEach((e, i) => {
      let dollarSum = 0;
      this.selectedFunds[e].forEach(x => {
        this.investmentTotal += +x['SelectedFunds.Amount'];
        dollarSum += +x['SelectedFunds.Amount'];
      });

      const fundType = this.cats.find(x => x.classId == e);
      const perc = dollarSum / this.investment;
      if (perc > 0) {
        summaryData.push({
          name: fundType.className,
          y: perc,
          color: this.assetClassColors[i],
        });
      }

      const row = this.cats.find(z => z.classId == e);
      const entry = {};
      entry['classId'] = e;
      entry['className'] = row.className;
      entry['percentage'] = row.percentage;
      entry['amount'] = row.amount;
      entry['selected'] = row.selected ? row.selected : null;
      entry['color'] = row.color;

      let realAmount = 0;
      if (this.selectedFunds[e]) {
        this.selectedFunds[e].forEach(el => {
          realAmount += +el['SelectedFunds.Amount'];
        });
      }
      entry['actualAmount'] = realAmount;
      entry['actualPercentage'] =
        realAmount > 0 ? realAmount / this.investment : 0;

      const idx = this.rows.findIndex(x => x.classId == e);
      this.rows[idx] = entry;
    });

    if (summaryData.length > 0) {
      let remainder = 1;
      summaryData.forEach(x => {
        remainder = remainder - x['y'];
      });

      if (remainder > 0) {
        summaryData.unshift({
          name: 'Unallocated',
          y: remainder,
          color: '#bf2025',
        });
      }
      this.saveFunds();
    } else {
      summaryData = [{ name: 'Unallocated', y: 1, color: '#bf2025' }];
      this.clearSelectedFunds();
    }

    this.updateSelectedChart(this.selectedFunds);
  }

  clearIndividualFund(id, assetClass) {
    this.setLoading(true);

    this.clearMultiAssetClassFund(id);

    if (
      this.selectedFunds[assetClass] &&
      this.selectedFunds[assetClass].length < 1
    ) {
      delete this.selectedFunds[assetClass];
    }

    let summaryData = [];

    this.investmentTotal = 0;
    Object.keys(this.selectedFunds).forEach((e, i) => {
      let dollarSum = 0;
      this.selectedFunds[e].forEach(x => {
        this.investmentTotal += +x['SelectedFunds.Amount'];
        dollarSum += +x['SelectedFunds.Amount'];
      });

      const fundType = this.cats.find(x => x.classId == e);
      const perc = dollarSum / this.investment;
      if (fundType && perc > 0) {
        summaryData.push({
          name: fundType.className,
          y: perc,
          color: this.assetClassColors[i],
        });
      }

      const row = this.cats.find(z => z.classId == e);
      if (row) {
        const entry = {};
        entry['classId'] = e;
        entry['className'] = row.className;
        entry['percentage'] = row.percentage;
        entry['amount'] = row.amount;
        entry['selected'] = row.selected ? row.selected : null;
        entry['color'] = row.color;

        let realAmount = 0;
        if (this.selectedFunds[e]) {
          this.selectedFunds[e].forEach(el => {
            realAmount += +el['SelectedFunds.Amount'];
          });
        }
        entry['actualAmount'] = realAmount;
        entry['actualPercentage'] =
          realAmount > 0 ? realAmount / this.investment : 0;

        const idx = this.rows.findIndex(x => x.classId == e);
        this.rows[idx] = entry;
      }
    });

    if (summaryData.length > 0) {
      let remainder = 1;
      summaryData.forEach(x => {
        remainder = remainder - x['y'];
      });

      if (remainder > 0) {
        summaryData.unshift({
          name: 'Unallocated',
          y: remainder,
          color: '#bf2025',
        });
      }
      this.saveFunds();
    } else {
      summaryData = [{ name: 'Unallocated', y: 1, color: '#bf2025' }];
      this.clearSelectedFunds();
    }

    this.updateSelectedChart(this.selectedFunds);
    this.setLoading(false);
  }

  updateSelectedFundData() {
    let summaryData = [];

    this.investmentTotal = 0;
    Object.keys(this.selectedFunds).forEach((e, i) => {
      let dollarSum = 0;
      this.selectedFunds[e].forEach(x => {
        this.investmentTotal += +x['SelectedFunds.Amount'];
        dollarSum += +x['SelectedFunds.Amount'];
      });

      const fundType = this.cats.find(x => x.classId == e);
      const perc = dollarSum / this.investment;
      if (perc > 0) {
        summaryData.push({
          name: fundType.className,
          y: perc,
          color: this.assetClassColors[i],
        });
      }

      const row = this.cats.find(z => z.classId == e);
      const entry = {};
      entry['classId'] = e;
      entry['className'] = row.className;
      entry['percentage'] = row.percentage;
      entry['amount'] = row.amount;
      entry['selected'] = row.selected ? row.selected : null;
      entry['color'] = row.color;

      let realAmount = 0;
      if (this.selectedFunds[e]) {
        this.selectedFunds[e].forEach(el => {
          realAmount += +el['SelectedFunds.Amount'];
        });
      }
      entry['actualAmount'] = realAmount;
      entry['actualPercentage'] =
        realAmount > 0 ? realAmount / this.investment : 0;

      const idx = this.rows.findIndex(x => x.classId == e);
      this.rows[idx] = entry;
    });

    if (summaryData.length > 0) {
      let remainder = 1;
      summaryData.forEach(x => {
        remainder = remainder - x['y'];
      });

      if (remainder > 0) {
        summaryData.unshift({
          name: 'Unallocated',
          y: remainder,
          color: '#bf2025',
        });
      }
      this.saveFunds();
    } else {
      summaryData = [{ name: 'Unallocated', y: 1, color: '#bf2025' }];
      this.clearSelectedFunds();
    }

    this.updateSelectedChart(this.selectedFunds);
  }

  selectSingle(event, name, assetClass, symbol, family) {
    this.selectedFunds[assetClass] = [
      {
        'SelectedFunds.Amount': this.investment,
        'SelectedFunds.AssetClass': assetClass,
        'SelectedFunds.FundID': symbol,
        'SelectedFunds.Name': name,
        'SelectedFunds.Symbol': name,
        'SelectedFunds.FundFamilyID': family,
      },
    ];
    this.investmentTotal = this.investment;
    this.saveFunds();
    event.stopPropagation();
  }

  clearMultiAssetClassFund(id) {
    Object.keys(this.selectedFunds).forEach(clss => {
      const idx = this.selectedFunds[clss].findIndex(fnd => {
        return (
          fnd['SelectedFunds.FundID'] === id ||
          fnd['SelectedFunds.Symbol'] === id
        );
      });

      if (idx > -1) {
        this.selectedFunds[clss].splice(idx, 1);
      }
    });

    this.formatRowData();
  }

  formatSelected(funds, assetClass?, type = 'MF') {
    let cleared = false;

    if (!funds.length) {
      const old = cloneDeep(this.selectedFunds[assetClass]).map(
        x => x['SelectedFunds.FundID']
      );
      this.selectedFunds[assetClass] = [];

      Object.keys(this.selectedFunds).forEach(() => {
        old.forEach(el => {
          this.clearMultiAssetClassFund(el);
        });
      });
    } else {
      funds.forEach((v, i) => {
        const oldFunds = this.selectedFunds[
          funds[i]['SelectedFunds.AssetClass']
        ]
          ? cloneDeep(
              this.selectedFunds[funds[i]['SelectedFunds.AssetClass']]
            ).map(x => {
              return {
                id: x['SelectedFunds.FundID'],
                type: x['SelectedFunds.FundType'],
              };
            })
          : [];

        if (
          this.selectedFunds[funds[i]['SelectedFunds.AssetClass']] &&
          !cleared
        ) {
          this.selectedFunds[funds[i]['SelectedFunds.AssetClass']].forEach(
            el => {
              if (el['SelectedFunds.FundType'] == type) {
                const idx = this.selectedFunds[
                  funds[i]['SelectedFunds.AssetClass']
                ].findIndex(x => x === el);
                this.selectedFunds[funds[i]['SelectedFunds.AssetClass']].splice(
                  idx,
                  1
                );
              }
            }
          );
          cleared = true;
        }
        const existing = this.selectedFunds[
          funds[i]['SelectedFunds.AssetClass']
        ]
          ? this.selectedFunds[funds[i]['SelectedFunds.AssetClass']].findIndex(
              x => {
                return x['SelectedFunds.FundID'] == v['SelectedFunds.FundID'];
              }
            )
          : -1;
        if (existing >= 0) {
          this.selectedFunds[funds[i]['SelectedFunds.AssetClass']][existing][
            'SelectedFunds.Amount'
          ] = v['SelectedFunds.Amount'];
        } else {
          const newFunds = cloneDeep(funds).map(x => {
            return {
              id: x['SelectedFunds.FundID'],
              type: x['SelectedFunds.FundType'],
            };
          });

          oldFunds.forEach(oldFund => {
            const matchingOldFunds = newFunds.find(
                newFund => newFund.id === oldFund.id
              ),
              matchingTypes = oldFund.type === newFunds[0].type;

            if (matchingTypes && !matchingOldFunds) {
              this.clearMultiAssetClassFund(oldFund.id);
            }
          });

          v['SelectedFunds.FundType'] = type ? type : 'MF';

          if (!this.selectedFunds[funds[i]['SelectedFunds.AssetClass']]) {
            this.selectedFunds[funds[i]['SelectedFunds.AssetClass']] = [
              { ...v },
            ];
            cleared = true;
          } else {
            this.selectedFunds[funds[i]['SelectedFunds.AssetClass']].push(v);
          }
        }
      });
    }

    this.formatRowData();

    this.cats = this.rows;
    this.updateSelectedChart(this.selectedFunds);
    this.ss.set('selectedFunds', this.selectedFunds);
  }

  formatRowData() {
    this.investmentTotal = 0;
    Object.keys(this.selectedFunds).forEach(e => {
      let realAmount = 0;

      this.selectedFunds[e].forEach(x => {
        this.investmentTotal += +x['SelectedFunds.Amount'];
      });

      const row = this.cats.find(z => z.classId == e);
      if (row) {
        const entry = {};
        entry['classId'] = e;
        entry['className'] = row.className;
        entry['percentage'] = row.percentage;
        entry['amount'] = row.amount;
        entry['selected'] = row.selected ? row.selected : null;
        entry['color'] = row.color;

        if (this.selectedFunds[e]) {
          this.selectedFunds[e].forEach(el => {
            realAmount += +el['SelectedFunds.Amount'];
          });
        }
        entry['actualAmount'] = realAmount;
        entry['actualPercentage'] =
          realAmount > 0 ? realAmount / this.investment : 0;

        const idx = this.rows.findIndex(x => x.classId == e);
        this.rows[idx] = entry;
      }
    });
  }

  disabledFin() {
    if (this.selectionType == 'SingleFunds') {
      return Object.keys(this.selectedFunds).length != 1;
    }

    return false;
  }

  formatPreSelected(funds) {
    const profSelectedFunds = {};

    funds.forEach(v => {
      if (!v.Symbol[0]) {
        return null;
      } else {
        const newFund = {};

        newFund['SelectedFunds.Amount'] = Number(v.Amount).toFixed(2);
        newFund['SelectedFunds.Symbol'] = v.Symbol;
        newFund['SelectedFunds.FundID'] = v.FundID;
        newFund['SelectedFunds.Name'] = v.Name;
        newFund['SelectedFunds.AssetClass'] = v.AssetClass;
        newFund['SelectedFunds.FundFamilyID'] = v.Family;
        newFund['SelectedFunds.FundType'] = v.FundType || 'MF';

        if (!profSelectedFunds[v.AssetClass]) {
          profSelectedFunds[v.AssetClass] = [];
          profSelectedFunds[v.AssetClass].push(newFund);
        } else {
          profSelectedFunds[v.AssetClass].push(newFund);
        }
      }
    });
    return profSelectedFunds;
  }

  fundsTotal(funds) {
    let total = 0;
    Object.keys(funds).forEach(f => {
      funds[f].forEach(x => {
        total += Number(x['SelectedFunds.Amount']);
      });
    });
    return total;
  }

  report() {
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      panelClass: 'report-dialog',
      data: { hideDetails: false },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        this.setLoading(true);
        this.rbs.getReport(this.id, result.details).subscribe(data => {
          const date = new Date();
          const filename =
            result.filename && result.filename != null
              ? result.filename + '.pdf'
              : this.clientName +
                '-' +
                date.getMonth() +
                date.getDay() +
                date.getFullYear() +
                '.pdf';

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
            this.setLoading(false);
          };
        });
      }
      this.setLoading(false);
    });
  }

  validatePortfolio() {
    this.route.navigate([`/iw/validate/${this.id}`]);
  }

  getCats(profile) {
    this.setLoading(true);
    this.iws.getFundCats(profile).subscribe(data => {
      const program = data.SelectedProgramData,
        profileFunds =
          data.SelectedFunds && data.SelectedFunds.length > 0
            ? this.formatPreSelected(data.SelectedFunds)
            : null,
        storageFunds = this.ss.get('selectedFunds') || {},
        iwVars = data.InvestmentWizard,
        classOptions = program.ShareClassOptions;
      this.program = iwVars.SelectedProgram;
      this.programName = program.DisplayName;

      this.selectionType = program.FundSelectionType;
      this.programInstructions = program.ProgramInstructions;
      this.programMessage = program.ProgramMessage;
      this.filtering = program.FundFamilyLimit;
      this.filteredFundFams = iwVars.FilteredFundFamilies;

      const assessmentType = iwVars.AssessmentType;

      this.breadcrumbs = assessmentType == 2 ? 'funds-validate' : 'funds';

      if (Object.keys(storageFunds).length) {
        this.selectedFunds = this.ss.get('selectedFunds');
        this.investmentTotal = this.fundsTotal(this.selectedFunds);
      } else if (profileFunds) {
        this.selectedFunds = profileFunds;
        this.investmentTotal = this.fundsTotal(this.selectedFunds);
      }

      this.shareClassFilter = classOptions == 0 ? false : true;
      this.shareClassDefault = program.ShareClassLimitDefault;
      this.shareClass = this.shareClassDefault;
      this.clientName = `${data.ClientPerson.FirstName} ${data.ClientPerson.LastName}`;

      if (this.selectionType == 'SingleFunds') {
        this.selectFund(this.program, 9999, this.id, true);
        if (Object.keys(this.selectedFunds).length) {
          this.singlePreSelect();
        }
      }

      this.getFundFamilies();
      const preInv = Number(iwVars.AssetAmount);
      this.investment = Number(Math.round(Number(preInv + 'e2')) + 'e-2');

      if (this.selectionType != 'SingleFunds') {
        const selected = null;

        this.modelData.name = data.AllocationModelLookup.Name;
        this.assetClassColors = data.CurrentAllocationModel.map(
          x => x.AssetClassColor
        );

        const finalData = data.CurrentAllocationModel.map(d => {
          const entry = {
            classId: d.AssetClassID,
            className: d.AssetClassName,
            percentage: d.Percent,
            amount: Number(
              Math.round(Number(d.Percent * this.investment + 'e2')) + 'e-2'
            ),
            selected: selected ? selected[d.AssetClassID] : null,
            color: d.AssetClassColor,
          };

          let realAmount = 0;
          if (this.selectedFunds[d.AssetClassID]) {
            this.selectedFunds[d.AssetClassID].forEach(el => {
              realAmount += +el['SelectedFunds.Amount'];
            });
          }

          entry['actualAmount'] = realAmount;
          entry['actualPercentage'] =
            realAmount > 0 ? realAmount / this.investment : 0;

          return entry;
        });

        this.columns = [
          { name: 'Asset Class', prop: 'className' },
          { name: 'Proposed Allocation', prop: 'percentage' },
        ];

        this.tableData = finalData.map(d => {
          return { name: d.className, y: Number(d.percentage), color: d.color };
        });
        this.tableData.push({ name: 'Unallocated', y: 0, color: '#bf2025' });

        Object.keys(this.selectedFunds).forEach(() => {
          this.cats = finalData;
        });

        this.rows = finalData;

        this.modelChartData = new Chart({
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
            },
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: null,
          },
          plotOptions: {
            pie: {
              innerSize: 100,
              depth: 45,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: 'funds',
              innerSize: '40%',
              data: this.tableData,
              type: 'pie',
            },
          ],
        });

        this.selectedChartData = new Chart({
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
            },
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: null,
          },
          plotOptions: {
            pie: {
              innerSize: 100,
              depth: 45,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: 'funds',
              innerSize: '40%',
              data: this.updateSelectedChart(
                this.selectedFunds,
                this.tableData
              ),
              type: 'pie',
            },
          ],
        });

        if (
          this.filtering == 'single' &&
          this.selectionType == 'Allocation' &&
          !Object.keys(this.selectedFunds).length
        ) {
          this.pickFamily();
        }

        this.cats = finalData;
        this.getTemplates();
        this.getMultiAssetClassFunds();
        this.multiSearch();
      }
      if (this.selectionType == 'Advisory') {
        this.breadcrumbs = 'advisory';
      }
      this.setLoading(false);
    });
  }

  getFundFamilies() {
    this.iws.fundFamilyList(this.program).subscribe(list => {
      this.fundFamilies = list.results;
    });
  }

  async selectFund(name, assetClass, amount, noModal?, type = 'MF') {
    this.setLoading(amount != 'TBD');
    let filterFam;
    if (
      this.filtering === 'multi' &&
      this.filteredFundFams &&
      this.filteredFundFams.length
    ) {
      filterFam = `{"Values": ${this.filteredFundFams}}`;
    } else if (this.filtering === 'single') {
      filterFam = `{"Values": [${this.filteredFundFams}]}`;
    }
    const fundClass =
      this.shareClass && type && type !== 'ETF' ? this.shareClass : null;

    if (amount == 'TBD') {
      const data = [
        {
          'SelectedFunds.FundID': 'TBD',
          'SelectedFunds.FundFamilyID': '999',
          'SelectedFunds.Symbol': 'TBD',
          'SelectedFunds.AssetClass': assetClass,
          'SelectedFunds.Name': 'Funds to be Selected at a Later Date',
          'SelectedFunds.Amount': 0,
          'SelectedFunds.IsSpecial': false,
        },
      ];
      this.formatSelected(data);
      this.saveFunds();
    } else {
      await this.rbs
        .processFunds(
          this.program,
          assetClass,
          this.id,
          fundClass,
          filterFam,
          type
        )
        .subscribe(data => {
          let funds = data.funds;
          this.initialLimit = data.limit;

          if (!noModal) {
            const modalData = {
              name: name,
              id: assetClass,
              profile: this.id,
              amount: amount,
              funds: [],
              selected: [],
              limit: this.initialLimit,
            };

            funds = funds.filter(x => {
              const selected = this.selectedFunds[assetClass]
                ? this.selectedFunds[assetClass].find(
                    z => z['SelectedFunds.FundID'] === x.fundId
                  )
                : null;
              return x.currFund.MultiAssetClass ? selected : true;
            });
            modalData.funds = funds;

            modalData.selected = this.selectedFunds[assetClass];

            if (funds.length <= 0) {
              const dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content:
                    'The system is unable to suggest an appropriate fund for this asset class.',
                  confirm: 'Ok',
                },
              });
              dialogRef.afterOpened().subscribe(() => {
                this.setLoading(false);
              });
            } else {
              const dialogRef = this.dialog.open(MultiDialogComponent, {
                panelClass: 'multi-dialog',
                data: modalData,
              });
              dialogRef.afterOpened().subscribe(() => {
                this.setLoading(false);
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result && result != 'Cancel') {
                  result.data.forEach(x => {
                    for (const k in x) {
                      x['SelectedFunds.' + k] = x[k];
                      delete x[k];
                    }
                    if (!x['SelectedFunds.FundType']) {
                      x['SelectedFunds.FundType'] = type;
                    }
                  });

                  this.formatSelected(result.data, assetClass, type);
                  this.saveFunds();
                }
              });
            }
          } else {
            let aaCounter = 0;
            let ttCounter = 0;
            funds.forEach(x => {
              if (x.type == 'AA' && aaCounter < this.initialLimit) {
                aaCounter++;
              } else if (x.type == 'AA' && aaCounter >= this.initialLimit) {
                x.hidden = true;
              } else if (x.type == 'TT' && ttCounter < this.initialLimit) {
                ttCounter++;
              } else if (x.type == 'TT' && ttCounter >= this.initialLimit) {
                x.hidden = true;
              }
            });

            this.setLoading(false);
            this.rows = funds;
          }
        });
    }
  }

  shareClassChange(ev, singleFunds = true) {
    this.shareClass = ev.value;
    if (singleFunds) {
      this.selectFund(this.program, 9999, this.id, true);
    } else {
      this.filteredSymbols = null;
      this.symbolSearch();
      this.getMultiAssetClassFunds();
      this.multiSearch();
    }
  }

  pickFamily() {
    if (!this.filteredFundFams) {
      const dialogRef = this.dialog.open(FamilyDialogComponent, {
        panelClass: 'family-dialog',
        data: {
          headline: 'Please Select a Fund Family',
          content:
            'You have chosen to select from a single fund family. Please select the preferred fund family.',
          program: this.program,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data != 'cancel') {
          this.filteredFundFams = result.data;
          this.filteredSymbols = null;
          this.symbolSearch();
          this.getMultiAssetClassFunds();
          this.rbs
            .saveProfile(this.id, {
              'InvestmentWizard.FilteredFundFamilies': this.filteredFundFams,
            })
            .subscribe(() => {
              this.ref.detectChanges();
            });
          this.multiSearch();
          this.disableFundFamilySelect = true;
        } else {
          this.location.back();
        }
      });
    }
  }

  compare(e, fund) {
    const inList = this.compareList.filter(x => {
      return x == fund;
    });

    if (inList && inList.length) {
      this.compareList = this.compareList.filter(x => {
        return x != fund;
      });
    } else {
      this.compareList.push(fund);
    }
  }
  viewComparison() {
    const compareFunds = [];

    this.compareList.forEach(x => {
      const fund = this.rows.find(y => y.Symbol == x);
      compareFunds.push(fund);
    });

    const compareData = { funds: compareFunds, header: 'Compare Funds' };

    const dialogRef = this.dialog.open(CompareDialogComponent, {
      panelClass: 'compare-dialog',
      height: '90vh',
      width: '90vw',
      maxHeight: '90vh',
      maxWidth: '90vw',
      data: compareData,
      autoFocus: false,
    });
    dialogRef.afterClosed();
  }

  filterWarn(ev) {
    if (ev == true) {
      const dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            'If you choose to filter funds based on fund families all your previous fund selections will be cleared.',
          confirm: 'Ok',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'continue') {
          this.clearSelectedFunds(false);
          this.filteredFundFams = null;
        }
      });
    }
  }

  fundFamilyChange() {
    this.filteredSymbols = null;
    this.symbolSearch();
    this.getMultiAssetClassFunds();
    this.multiSearch();
    this.rbs
      .saveProfile(this.id, {
        'InvestmentWizard.FilteredFundFamilies': this.filteredFundFams,
      })
      .subscribe();
  }

  clearSelectedFunds(spinner = true) {
    // turning on the spinner ki
    this.setLoading(spinner);
    this.rbs.selectFunds(this.id, null, true).subscribe(() => {
      this.rbs
        .saveProfile(this.id, { 'InvestmentWizard.FilteredFundFamilies': null })
        .subscribe(() => {
          this.investmentTotal = 0;
          this.ss.remove('selectedFunds');
          this.selectedFunds = {};

          this.rows.forEach(x => {
            x.actualAmount = 0;
            x.actualPercentage = 0;
          });
          this.setLoading(false);
        });
    });
  }

  singlePreSelect() {
    const sel = this.selectedFunds['9999'][0];
    this.selectedSingle = sel['SelectedFunds.FundID'];
  }

  symbolSearch() {
    this.filteredSymbols = this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(fund => {
        if (fund && fund.length > 2) {
          return this._filterSymbols(fund);
        }
        return [];
      })
    );
  }

  multiSearch() {
    this.multiSearchControl.valueChanges.subscribe(fund => {
      const search = fund && typeof fund === 'string' ? fund : null;
      if (!search) {
        this.filteredMulti = this.multiFundList;
      } else {
        this.filteredMulti = this._filterMulti(search);
      }
    });
  }

  selectFundLookup(ev) {
    const classList = this.rows.map(x => x.classId);
    const fundClasses = ev.option.value.FundAllocation.map(x => x.AssetClass);
    const common = classList.filter(x => fundClasses.includes(x));

    if (common.length > 0) {
      const dialogRef = this.dialog.open(FundLookupDialogComponent, {
        panelClass: 'fund-lookup-dialog',
        data: {
          fund: ev.option.value,
          model: this.rows.map(x => {
            return x.classId;
          }),
          amount: this.investment,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data != 'cancel') {
          this.iws.fundInfo(result.data.fund.FundID).subscribe(x => {
            const fundInfo = x.results;
            const fund = result.data.fund;
            const allowedClasses = this.rows.map(x => +x.classId);

            fundInfo.forEach(el => {
              if (allowedClasses.includes(+el.AssetClass)) {
                const fnd = {};

                fnd['SelectedFunds.Amount'] =
                  this.investment *
                  fund['allocation'] *
                  (el.PercentAllocation / 100);
                fnd['SelectedFunds.Symbol'] = el.Symbol;
                fnd['SelectedFunds.FundID'] = el.Symbol;
                fnd['SelectedFunds.Name'] = `${el.Symbol} - ${el.FundName}`;
                fnd['SelectedFunds.AssetClass'] = el.AssetClass;
                fnd['SelectedFunds.FundFamilyID'] = el.FundFamilyID;
                fnd['SelectedFunds.FundType'] = el.FundType;

                if (!this.selectedFunds[el.AssetClass]) {
                  this.selectedFunds[el.AssetClass] = [];
                }

                const existing = this.selectedFunds[el.AssetClass].findIndex(
                  el =>
                    el['SelectedFunds.Symbol'] == fnd['SelectedFunds.Symbol'] ||
                    el['SelectedFunds.FundID'] == fnd['SelectedFunds.FundID']
                );
                if (existing >= 0) {
                  this.selectedFunds[el.AssetClass][existing][
                    'SelectedFunds.Amount'
                  ] += fnd['SelectedFunds.Amount'];
                } else {
                  this.selectedFunds[el.AssetClass].push(fnd);
                }

                let realAmount = 0;
                if (this.selectedFunds[el.AssetClass]) {
                  this.selectedFunds[el.AssetClass].forEach(x => {
                    realAmount += +x['SelectedFunds.Amount'];
                  });

                  const idx = this.rows.findIndex(
                    z => z.classId == el.AssetClass
                  );
                  if (idx > -1) {
                    this.rows[idx].actualAmount = realAmount;
                    this.rows[idx].actualPercentage =
                      realAmount > 0 ? realAmount / this.investment : 0;
                  }
                }
              }
            });
            this.investmentTotal = 0;

            Object.keys(this.selectedFunds).forEach(el => {
              this.selectedFunds[el].forEach(x => {
                this.investmentTotal += +x['SelectedFunds.Amount'];
              });
            });

            this.updateSelectedChart(this.selectedFunds);
            this.saveFunds();
          });
        }
        this.filteredSymbols = null;
        this.symbolSearch();
        this.setLoading(false);
      });
    } else {
      const dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Warning',
          content:
            'This mutual fund represents an asset class that is not part of your target allocation. Return to the Investment Model page and adjust your target asset allocation to include additional asset classes.',
          confirm: 'Ok',
        },
      });
      dialogRef.afterOpened().subscribe(() => {
        this.setLoading(false);
      });
      dialogRef.afterClosed();
    }
    this.searchControl.setValue(null);
    this.multiSearchControl.setValue(null);
    this.filteredSymbols = null;
    this.symbolSearch();
    this.filteredMulti = null;
    this.symbolSearch();
    this.multiSearch();
  }

  private _filterSymbols(value: string) {
    const list = this.iws
      .fundSearch(
        value,
        this.program,
        this.filteredFundFams,
        this.shareClass,
        this.selectionType
      )
      .pipe(
        map(x => {
          return x.results;
        })
      );
    return list;
  }

  private _filterMulti(value: string) {
    if (!value) {
      return this.multiFundList;
    } else {
      return this.multiFundList.filter(x => {
        return (
          x.FundName.toLowerCase().includes(value.toLowerCase()) ||
          x.FundID.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
  }

  getTemplates() {
    this.iws.getTemplates().subscribe(x => {
      const userTemplates = x.results.UserTemplates;
      const firmTemplates = x.results.FirmTemplates;
      this.templates =
        userTemplates && userTemplates.length > 0
          ? userTemplates.filter(z => z.program == this.program)
          : [];
      this.firmTemplates =
        firmTemplates && firmTemplates.length > 0
          ? firmTemplates.filter(z => z.program == this.program)
          : [];
    });
  }

  applyTemplate(ev) {
    const funds = ev.value.funds;

    let fundList = '';
    funds.forEach((x, i) => {
      if (i != funds.length - 1) {
        fundList += `${x.ticker},`;
      } else {
        fundList += x.ticker;
      }
    });

    const formattedFunds = {};
    let fundInfo;
    this.iws.fundInfo(fundList).subscribe(x => {
      fundInfo = x.results;
      fundInfo.forEach(el => {
        const existingClass = this.rows.find(x => x.classId == el.AssetClass);

        if (existingClass) {
          const fnd = {};
          const info = funds.find(x => {
            return x.ticker == el.Symbol;
          });

          fnd['SelectedFunds.Amount'] =
            this.investment * info.percent * (el.PercentAllocation / 100);
          fnd['SelectedFunds.Symbol'] = info.ticker;
          fnd['SelectedFunds.FundID'] = info.ticker;
          fnd['SelectedFunds.Name'] = `${info.ticker} - ${el.FundName}`;
          fnd['SelectedFunds.AssetClass'] = el.AssetClass;
          fnd['SelectedFunds.FundFamilyID'] = el.FundFamilyID;
          fnd['SelectedFunds.FundType'] = el.FundType;

          if (el.AssetClass && !formattedFunds[el.AssetClass]) {
            formattedFunds[el.AssetClass] = [];
          }
          formattedFunds[el.AssetClass].push(fnd);
        }
      });

      this.selectedFunds = formattedFunds;
      Object.keys(this.selectedFunds).forEach(el => {
        let realAmount = 0;
        this.selectedFunds[el].forEach(x => {
          realAmount += +x['SelectedFunds.Amount'];
        });
        const idx = this.rows.findIndex(z => z.classId == el);
        if (idx > -1) {
          this.rows[idx].actualAmount = realAmount;
          this.rows[idx].actualPercentage =
            realAmount > 0 ? realAmount / this.investment : 0;
        }
      });

      this.investmentTotal = 0;

      Object.keys(this.selectedFunds).forEach(e => {
        this.selectedFunds[e].forEach(x => {
          this.investmentTotal += +x['SelectedFunds.Amount'];
        });
      });

      this.updateSelectedChart(this.selectedFunds);

      this.saveFunds();
      this.templatesControl.setValue(null);
    });
  }

  updateSelectedChart(fundData, mainChartData?) {
    const input = mainChartData ? mainChartData : this.tableData;
    const summaryData = cloneDeep(input);

    summaryData.forEach(x => {
      x.y = 0;
    });

    Object.keys(fundData).forEach(el => {
      let dollarSum = 0;
      fundData[el].forEach(x => {
        dollarSum += +x['SelectedFunds.Amount'];
      });

      const classData = this.cats.find(z => z.classId == el);
      const fundType = classData ? classData.className : null;

      const perc = dollarSum / this.investment;
      const idx = summaryData.findIndex(x => x.name == fundType);
      if (!summaryData[idx]) {
        summaryData[idx] = {};
      }
      summaryData[idx].y = perc;
    });

    if (summaryData.length > 0) {
      let remainder = 1;
      summaryData.forEach(x => {
        remainder = Number(
          Math.round(Number(remainder - x['y'] + 'e2')) + 'e-2'
        );
      });

      if (remainder > 0) {
        const idx = summaryData.findIndex(x => x.name == 'Unallocated');
        summaryData[idx].y = remainder;
      }
    } else {
      const idx = summaryData.findIndex(x => x.name == 'Unallocated');
      summaryData[idx].y = 1;
    }

    if (mainChartData) {
      return summaryData;
    } else {
      this.selectedChartData.ref.series[0].setData(summaryData);
    }
  }

  getFundInfo(funds: string[]) {
    let formattedFunds = '';
    funds.forEach((x, i) => {
      if (i != funds.length - 1) {
        formattedFunds += `${x},`;
      } else {
        formattedFunds += x;
      }
    });
    let fundInfo;
    this.iws.fundInfo(formattedFunds).subscribe(x => {
      fundInfo = x.results;
    });
    return fundInfo;
  }

  getMultiAssetClassFunds() {
    const shareClass = this.program == 'ETF' ? null : this.shareClass;
    this.iws
      .getMultiFunds(this.program, this.filteredFundFams, shareClass)
      .subscribe(x => {
        this.multiFundList = x.results;
        this.filteredMulti = x.results;
      });
  }

  displayFn(option?): string | undefined {
    return option ? `${option.FundID} - ${option.FundName}` : undefined;
  }

  evalTags(str) {
    return this.tes.evalTags(str);
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }
}
