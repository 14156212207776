import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { zxcvbn } from 'zxcvbn3';

@Component({
  selector: 'strength-meter',
  templateUrl: './strength-meter.component.html',
  styleUrls: ['./strength-meter.component.scss'],
})
export class StrengthMeterComponent implements OnChanges {
  @Input()
  password = '';

  @Output('strength')
  passwordStrength = new EventEmitter();

  strength = 0;

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['password'];
    if (change) {
      this.getStrength(change.currentValue);
    }
  }

  getStrength(password) {
    const estimation = zxcvbn(password || '');
    this.strength = estimation.score;
    this.passwordStrength.emit({
      strength: this.strength,
    });
  }

  getClass() {
    return `level-${this.strength}`;
  }

  getWidth() {
    if (this.strength > 0) {
      return `${this.strength * 25}%`;
    }
    return '0%';
  }
}
