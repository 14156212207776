import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReportingService } from '../../reporting.service';
import { saveAs } from '@progress/kendo-file-saver';
import {
  distinctUntilChanged,
  map,
  switchMap,
  startWith,
} from 'rxjs/operators';
import { AdminService } from '../../../admin/admin.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-mutual-funds',
  templateUrl: './mutual-funds.component.html',
  styleUrls: ['./mutual-funds.component.scss'],
})
export class MutualFundsComponent implements OnInit {
  @Input() baseUrl;
  mutualFundsUnitsControl = new FormControl();
  selectedUnit;
  filteredUnits;
  mutualFund = { unit: null, start: null, end: null };
  mutualFundData = { data: null, headers: null };

  constructor(
    private admnSvc: AdminService,
    private pltfrm: Platform,
    private rptSvc: ReportingService
  ) {}

  ngOnInit(): void {
    this.getMutualFundUnits();
  }

  getMfReport() {
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    this.rptSvc
      .getMutualFund(
        this.mutualFund.unit,
        this.mutualFund.start.toISOString(),
        this.mutualFund.end.toISOString()
      )
      .subscribe({
        next: data => {
          const filename = `${this.mutualFund.unit}_MutualFundReport.csv`;

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
            // this.loading = false;
          };
        },
        error: error => {
          console.error(error);
        },
      });
  }

  getMutualFundUnits() {
    this.filteredUnits = this.mutualFundsUnitsControl.valueChanges.pipe(
      startWith('%'),
      distinctUntilChanged(),
      switchMap(value => {
        if ((value && value.length > 2) || value == '%') {
          return this._filterUnits(value);
        }
        return [];
      })
    );
  }

  private _filterUnits(value: string) {
    if (!value) {
      value = '%25';
    }
    const list = this.admnSvc.getUnitList(value).pipe(map(data => data.names));
    return list;
  }
}
