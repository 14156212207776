import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-download-page',
  templateUrl: './download-page.component.html',
  styleUrls: ['./download-page.component.scss'],
})
export class DownloadPageComponent implements OnInit {
  private environment;
  baseUrl: string;

  constructor(private envSvc: EnvironmentService) {}

  ngOnInit(): void {
    this.environment = this.envSvc.get();
    this.baseUrl =
      this.environment.env != 'prod'
        ? `https://ra-${this.environment.env}.rightbridge.net/downloads/`
        : `https://ra.rightbridge.net/downloads/`;
  }
}
