<div class="page-container unified-questionnaire-container container unified">
  <div class="row">
    <app-left-nav [currentApp]="currentApp" [stepInput]="currentStep" (tabChanged)="handleTabChange($event, true)"
      (appChanged)="handleAppChange(true, $event)" (appPage)="handleAppPage($event)" [iwValidateTrack]="iwValidate"
      [iwProg]="progSelectedIW?.selectionType" [liValidateTrack]="liValidate" [modules]="modules"></app-left-nav>

    <div class="content">
      <h1 class="unified-header mb-2" *ngIf="!loading">
        {{ currentAppDisplay() }}
        <ng-container *ngIf="currentApp !== 'rb' && currentStepDisplay()">- {{ currentStepDisplay() }}</ng-container>
      </h1>
      <app-formly #formlyComponent *ngIf="checkStatus()" [hidden]="hideForm() || !profileData" [context]="tabContext"
        [data]="profileData" [externalSubmit]="formSubmitted" (newProfile)="handleNewProfile($event)"
        (formChange)="handleModelChange($event)" class="mx-0"></app-formly>
      <!--  -->
      <!-- PP Pages -->
      <!--  -->
      <app-pp-results *ngIf="currentStep === 'results' && currentApp === 'pp'" [hidden]="unfFlowSvc.getloading()"
        [profile]="this.profile" (prodsSelected)="handlePPProds($event)"></app-pp-results>
      <!--  -->
      <!-- AW Pages -->
      <!--  -->
      <aw-comparison *ngIf="currentStep === 'compare-results' && currentApp === 'aw'"></aw-comparison>
      <app-annuities-result *ngIf="currentStep === 'results' && currentApp === 'aw'"
        [hidden]="unfFlowSvc.getloading() && !downloadingFactsheet" [profile]="this.profile"
        (downloadFactsheet)="downloadFactsheet($event)"
        [hasSummaryModule]="modStatusSvc.showCaseSummary()"></app-annuities-result>
      <!--  -->
      <!-- IW Pages -->
      <!--  -->
      <program-selection *ngIf="currentStep === 'program-selection' && currentApp === 'iw'"
        [hidden]="unfFlowSvc.getloading()" (navigate)="handleTabChange($event)"
        (progSelected)="handleIWProg($event)"></program-selection>
      <app-model-selection *ngIf="currentStep === 'investment-model' && currentApp === 'iw'"
        [hidden]="unfFlowSvc.getloading()" (navigate)="handleTabChange($event)"></app-model-selection>
      <app-fund-allocations *ngIf="
          (currentStep === 'fund-allocations' || currentStep === 'advisory') &&
          currentApp === 'iw'
        " [hidden]="unfFlowSvc.getloading()" (navigate)="handleTabChange($event)"></app-fund-allocations>
      <app-iw-result *ngIf="currentStep === 'validate' && currentApp === 'iw'" [hidden]="unfFlowSvc.getloading()"
        [hasSummaryModule]="modStatusSvc.showCaseSummary()"></app-iw-result>

      <!--  -->
      <!-- LI Pages -->
      <!--  -->
      <results *ngIf="
          (currentStep === 'results' || currentStep === 'needs') &&
          currentApp === 'li'
        " [hidden]="unfFlowSvc.getloading()" (navigate)="handleTabChange($event)"></results>
      <app-scenarios *ngIf="currentStep === 'compare' && currentApp === 'li'" [hidden]="unfFlowSvc.getloading()"
        (navigate)="handleTabChange($event)"></app-scenarios>
      <validate *ngIf="currentStep === 'validate' && currentApp === 'li'" [hidden]="unfFlowSvc.getloading()"
        (navigate)="handleTabChange($event)" [hasSummaryModule]="modStatusSvc.showCaseSummary()"></validate>
      <app-product-result *ngIf="currentStep === 'products' && currentApp === 'li'"
        [hidden]="unfFlowSvc.getloading()"></app-product-result>
      <!--  -->
      <!-- Summary Page -->
      <!--  -->
      <app-unified-summary-page *ngIf="currentApp === 'summary'" (navigateTo)="handleSummaryNavigate($event)"
        (summaryClient)="setSummaryClient($event)"></app-unified-summary-page>

      <div class="buttons row" [hidden]="unfFlowSvc.getloading()">
        <button mat-button (click)="handleTabChange('back')" class="btn btn-primary pull-left" type="button"
          [disabled]="navDisabled || unfFlowSvc.getSaving()" *ngIf="currentStep !== 0 && currentStep !== '0'">
          <mat-icon>arrow_back</mat-icon>
          Back
        </button>
        <span class="back-button-placeholder pull-left" *ngIf="currentStep === 0 || currentStep === '0'"></span>
        <span class="saving-indicator" *ngIf="navDisabled || unfFlowSvc.getSaving()">
          <span>Save in Progress</span>
          <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </span>

        <button mat-button (click)="handleTabChange('next')" *ngIf="
            (currentApp !== 'summary' &&
              currentStep !== 'results' &&
              currentStep !== 'validate' &&
              currentStep !== 'products') ||
            ((currentStep === 'results' || currentStep === 'validate') &&
              modStatusSvc.showCaseSummary()) ||
            (currentApp === 'li' &&
              currentStep === 'results' &&
              !modStatusSvc.showCaseSummary()) ||
            (currentApp === 'li' &&
              currentStep === 'products' &&
              modStatusSvc.showCaseSummary())
          " class="btn btn-primary pull-right next-button" type="button"
          [disabled]="navDisabled || unfFlowSvc.getSaving()">
          Next <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="unfFlowSvc.getloading()" [config]="{ primaryColour: '#ffffff' }"></ngx-loading>