<div class="page-container profile-history-container container">
  <div class="explorer-content-container unified">
    <h1 class="header col-12" *ngIf="!loading">
      Results for {{ changeSummary[0].ClientName }}
      <!-- <button mat-button class="header-button" (click)="report()">
        <mat-icon>insert_drive_file</mat-icon>
        <span>Download Report</span>
      </button> -->
    </h1>
    <mat-accordion [multi]="false" *ngIf="!loading">
      <mat-expansion-panel *ngFor="let pageData of changeSummary">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>
              {{ pageData.DateTime | localTime: 'date' }} &mdash;
              {{ pageData.User }} &mdash; {{ pageData.Solution }}
            </strong>
            <span class="change-data">
              Product Changes
              <span class="data-point mx-1">{{ pageData.ProductChanges }}</span>
              Score Class Changes
              <span class="data-point ml-1">{{
                pageData.ScoreClassChanges
              }}</span>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="results-container row">
            <div class="products-container sub-container col-7">
              <h3>Products</h3>
              <table>
                <thead>
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Score Class</th>
                  <th>Product Type</th>
                  <th>Amount</th>
                </thead>
                <tbody>
                  <tr *ngFor="let row of pageData.products">
                    <td>{{ row.ProductName }}</td>
                    <td>{{ row.ProductCode }}</td>
                    <td class="text-center">
                      <mat-icon
                        *ngIf="row.ScoreClass === 'positive'"
                        class="positive"
                        >check_circle</mat-icon
                      >
                      <mat-icon
                        *ngIf="row.ScoreClass === 'neutral'"
                        class="neutral"
                        >error</mat-icon
                      >
                      <mat-icon
                        *ngIf="row.ScoreClass === 'negative'"
                        class="negative"
                        >remove_circle</mat-icon
                      >
                    </td>
                    <td>{{ row.ProductType }}</td>
                    <td>{{ row.Amount | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="key-data-container sub-container col-5">
              <h3>Key Data</h3>
              <table>
                <thead>
                  <th>Label</th>
                  <th>Value</th>
                </thead>
                <tbody>
                  <tr *ngFor="let row of pageData.keydata">
                    <td>{{ row.label }}</td>
                    <td>{{ row.display }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="change-history-container sub-container col-12 mt-5">
              <h3>Change History</h3>
              <mat-accordion
                *ngFor="let changeHistory of pageData.profilechanges"
              >
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <strong
                        >{{ changeHistory.date }} &mdash;
                        {{ changeHistory.userId }} &mdash;
                        {{ changeHistory.solution }}</strong
                      >
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <mat-tab-group>
                      <mat-tab *ngIf="changeHistory.changes" label="Changes">
                        <table class="changes">
                          <thead>
                            <th>Variable</th>
                            <th>Label</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of changeHistory.changes">
                              <td>{{ row.variable }}</td>
                              <td>{{ row.label }}</td>
                              <td>{{ row.oldvalue }}</td>
                              <td>{{ row.newvalue }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-tab>
                      <mat-tab
                        *ngIf="changeHistory.deletions"
                        label="Deletions"
                      >
                        <table class="changes">
                          <thead>
                            <th>Variable</th>
                            <th>Label</th>
                            <th>Value Removed</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of changeHistory.deletions">
                              <td>{{ row.variable }}</td>
                              <td>{{ row.label }}</td>
                              <td>{{ row.oldvalue }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-tab>
                      <mat-tab
                        *ngIf="changeHistory.additions"
                        label="Additions"
                      >
                        <table class="changes">
                          <thead>
                            <th>Variable</th>
                            <th>Label</th>
                            <th>Value Added</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of changeHistory.additions">
                              <td>{{ row.variable }}</td>
                              <td>{{ row.label }}</td>
                              <td>{{ row.newvalue }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-tab>
                    </mat-tab-group>
                  </ng-template>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
