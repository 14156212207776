<mat-form-field class="" class="mr-3">
  <mat-label>Unit ID</mat-label>
  <input
    type="text"
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="sales.unit"
    [matAutocomplete]="salesAuto"
    name="salesUnitsControl"
    [formControl]="salesUnitsControl"
  />
  <mat-autocomplete #salesAuto="matAutocomplete">
    <mat-option *ngFor="let unit of filteredUnits | async" [value]="unit.id">
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="" class="mr-3">
  <mat-label>User ID</mat-label>
  <input matInput placeholder="Enter UserID" [(ngModel)]="sales.user" />
  <mat-hint>Unit ID will override this</mat-hint>
</mat-form-field>
<mat-form-field class="" class="mr-3">
  <mat-label>Activity Type</mat-label>
  <mat-select [(ngModel)]="sales.at">
    <mat-option *ngFor="let type of activityTypes" [value]="type">
      {{ type }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="" class="mr-3">
  <mat-label>Sale Type</mat-label>
  <mat-select [(ngModel)]="sales.st">
    <mat-option *ngFor="let type of saleTypes" [value]="type">
      {{ type }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="" class="mr-3">
  <mat-label>Rep Intersect</mat-label>
  <mat-select [(ngModel)]="sales.ri">
    <mat-option *ngFor="let type of repIntersectTypes" [value]="type">
      {{ type }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="" class="mr-3">
  <mat-label>Days Gap</mat-label>
  <input matInput [(ngModel)]="sales.gap"
/></mat-form-field>

<mat-form-field class="" class="mr-3">
  <mat-label>Date Range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input
      matStartDate
      placeholder="Start date"
      (dateChange)="changeDate($event)"
    />
    <input
      matEndDate
      placeholder="End date"
      (dateChange)="changeDate($event)"
    />
  </mat-date-range-input>
  <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getSalesTracking()"
  [disabled]="!sales.unit"
>
  Get Report
</button>
<button
  mat-button
  color="primary"
  class="grid-button pull-right"
  [disabled]="!salesTrackingData.data"
  (click)="exportToExcel(salesGrid)"
  *ngIf="salesTrackingData.data"
>
  <mat-icon>cloud_download</mat-icon>Download Excel File
</button>
<kendo-grid
  #salesGrid="kendoGrid"
  [hidden]="!salesTrackingData.data"
  class="col-12 mt-3 usage-grid"
  [kendoGridBinding]="salesTrackingData.data"
  [height]="600"
  [selectable]="true"
>
  <kendo-grid-column
    *ngFor="let col of salesTrackingData.headers"
    [field]="col.DataField"
    [title]="col.Label"
  ></kendo-grid-column>
  <kendo-grid-excel
    fileName="RightBridge-SalesTrackingReport.xlsx"
  ></kendo-grid-excel>
</kendo-grid>
