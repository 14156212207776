<mat-form-field class="" class="mr-3">
  <input
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="action.unit"
    [matAutocomplete]="actionAuto"
    name="actionUnitsControl"
    [formControl]="actionUnitsControl"
  />
  <mat-autocomplete #actionAuto="matAutocomplete">
    <mat-option *ngFor="let unit of filteredUnits | async" [value]="unit.id">
      {{ unit.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getBridgeAction()"
  [disabled]="!action.unit"
>
  Get Report
</button>
<button
  mat-button
  color="primary"
  class="grid-button pull-right"
  [disabled]="!displayData || displayData.length === 0"
  (click)="exportToExcel(bridgeActionGrid)"
  *ngIf="displayData"
>
  <mat-icon>cloud_download</mat-icon>Download Excel File
</button>

<kendo-grid
  #bridgeActionGrid="kendoGrid"
  [hidden]="!displayData || displayData.length === 0"
  class="col-12 mt-3 usage-grid"
  [kendoGridBinding]="displayData"
>
  <kendo-grid-column [width]="45">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        mat-icon-button
        color="primary"
        (click)="openDetailModal($event, dataItem)"
        matTooltip="View Detail"
      >
        <mat-icon>preview</mat-icon>
      </button>
    </ng-template>
  </kendo-grid-column>
  @for (col of bridgeActionData.headers; track $index) {
    <kendo-grid-column
      [field]="col.DataField"
      [title]="col.Label"
      [hidden]="col.Hidden"
    >
      @if (
        col.DataField === 'noThanksItems' ||
        col.DataField === 'iLikeItItems' ||
        col.DataField === 'followUpsItems' ||
        col.DataField === 'otherItems'
      ) {
        <ng-template kendoGridCellTemplate let-dataItem>
          @if (dataItem[col.DataField].length === 0) {
            None
          }
          @for (item of dataItem[col.DataField]; track $index) {
            @if ($index > 0) {
              <br />
            }
            {{ display(item, $index) }}
          }
        </ng-template>
      }
    </kendo-grid-column>
  }
</kendo-grid>
@if (hiddenItemNum > 0) {
  <div class="table-notes"
    ><p>{{ hiddenItemNum }} unit(s) hidden</p
    ><p>Hidden units have 0 total actions taken</p></div
  >
}
@if (loading) {
  <app-simple-loading [message]="'Loading Report...'"></app-simple-loading>
}
