import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RightBridgeApiService } from './right-bridge-api.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorReportingService } from './error-reporting.service';

@Injectable()
export class AppGlobalsService {
  constructor(
    private rbs: RightBridgeApiService,
    private errSvc: ErrorReportingService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const globals = this.rbs.getGlobals().pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
    return globals;
  }
}
