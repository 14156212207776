import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AttestDialogComponent } from '../attest-dialog/attest-dialog.component';
import { RightBridgeApiService } from '../services/right-bridge-api.service';
import { SessionStorageService } from '../services/session-storage.service';
import { EnvironmentService } from '../services/environment.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit {
  private environment;
  params = {
    session: null,
    org: null,
    app: null,
    profile: null,
    search: null,
    bridgeId: null,
  };
  valid = true;

  constructor(
    private route: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private envSvc: EnvironmentService,
    private ss: SessionStorageService,
    private dialog: MatDialog
  ) {
    this.params = route.params['value'];
  }

  ngOnInit() {
    this.environment = this.envSvc.get();

    let check = false;
    const paramsApp = this.params.app === 'se' ? this.params.app : 'rb';
    this.rbs
      .querySession(this.params.session, this.params.org, paramsApp, true)
      ?.subscribe(
        x => {
          check = x.results > 0;
          if (check) {
            this.ss.set('session', this.params.session);
            this.rbs.getGlobals().subscribe(data => {
              this.ss.set('rights', data.results.user.rights);
              const rights = data.results.user.rights;
              const hasAttested = data.results.user.hasAttested;

              if (!hasAttested && rights.includes('LicenseAttest')) {
                const dialogRef = this.dialog.open(AttestDialogComponent, {
                  panelClass: 'attest-dialog',
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result.data != 'cancel') {
                    this.rbs.setSso(
                      this.params.org,
                      this.params.session,
                      this.params.app,
                      this.params.profile,
                      this.params.search,
                      this.params.bridgeId
                    );
                  } else {
                    this.rbs.endSession().subscribe();
                  }
                });
              } else {
                this.rbs.setSso(
                  this.params.org,
                  this.params.session,
                  this.params.app,
                  this.params.profile,
                  this.params.search,
                  this.params.bridgeId
                );
              }
            });
          } else {
            this.valid = check;
          }
        },
        error => {
          console.error(error);
        }
      );
  }
}
